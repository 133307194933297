/** @format */

import * as ActionTypes from "./ActionTypes";

export const getWalletAmount = () => ({
  type: ActionTypes.GET_WALLET_AMOUNT,
});

export const getWalletAmountError = (error) => ({
  type: ActionTypes.GET_WALLET_AMOUNT_ERROR,
  error,
});

export const getWalletAmountSuccess = (data) => ({
  type: ActionTypes.GET_WALLET_AMOUNT_SUCCESS,
  data,
});

export const getWalletError = (error) => ({
  type: ActionTypes.GET_WALLET_ERROR,
  error,
});

export const getWalletSuccess = (data) => ({
  type: ActionTypes.GET_WALLET_SUCCESS,
  data,
});

export const getWalletPending = () => ({
  type: ActionTypes.GET_WALLET_PENDING,
});

export const cardPaymentError = (error) => ({
  type: ActionTypes.CARD_PAYMENT_ERROR,
  error,
});

export const cardPaymentSuccess = (data) => ({
  type: ActionTypes.CARD_PAYMENT_SUCCESS,
  data,
});

export const cardPaymentPending = () => ({
  type: ActionTypes.CARD_PAYMENT_PENDING,
});
