/** @format */

import moment from "moment";
import {
  GET_PARTNER_PROFILE,
  media_url,
  REACT_APP_MEDIA_URL,
  regexp,
  user_image,
} from "./Constants";
import { fetchGet } from "./request";

export const getUser = async (user_id) => {
  const response = await fetchGet(
    `${GET_PARTNER_PROFILE}?partner_id=${user_id}`
  );
  const getUserData = JSON.parse(localStorage.getItem("user_data"));
  const response1 = {
    auth_token: getUserData.auth_token,
    created_at: getUserData.created,
    delivery_boy_name: response.delivery_boy_name,
    email: response.email,
    fcm_token: getUserData.fcm_token,
    id: response.partner_id,
    otp: getUserData.otp,
    password: getUserData.password,
    phone_number: response.phone_number,
    profile_picture: response.profile_picture,
    status: getUserData.status,
    updated_at: getUserData.updated_at,
  };
  await localStorage.setItem("user_data", JSON.stringify(response1));
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const checkExistingNotes = (showEditedOrder, notes) => {
  if (showEditedOrder) {
    return (
      (notes[notes.length - 1].customer_notes != null &&
        notes[notes.length - 1].customer_notes != "") ||
      (notes[notes.length - 1].internal_notes != null &&
        notes[notes.length - 1].internal_notes != "") ||
      (notes[notes.length - 1].partner_notes != null &&
        notes[notes.length - 1].partner_notes != "")
    );
  } else {
    return (
      (notes?.customer_notes != null && notes?.customer_notes != "") ||
      (notes?.internal_notes != null && notes?.internal_notes != "") ||
      (notes?.partner_notes != null && notes?.partner_notes != "")
    );
  }
};

export const getAssigneeNames = (assignee) => {
  if (assignee.length === 0) {
    return "Unassigned";
  } else if (assignee.length > 4) {
    const initialsArray = assignee
      .slice(0, 4)
      .map((item) => getInitials(item.name))
      .join(", ");

    return `${initialsArray}, + ${assignee.length - 4} More`;
  } else {
    const initialsArray = assignee
      .map((item) => getInitials(item.name))
      .join(", ");

    return `${initialsArray}`;
  }
};

export const getInitials = (fullName) => {
  const words = fullName.split(" ");

  const initials = words.map((word) => {
    return word.charAt(0).toUpperCase();
  });

  return initials.join("");
};

export const isImageOrPdf = (fileExtension) => {
  switch (fileExtension.toLowerCase()) {
    case "jpg":
    case "png":
    case "jpeg":
      return true;
  }
  return false;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const checkIfCustomerImagesExists = (statusList) => {
  return statusList?.customer_status_images !== null;
};

export const checkIfPartnerImagesExists = (statusList) => {
  return (
    statusList?.internal_status_images !== null ||
    statusList?.partner_status_images !== null
  );
};

export const checkIfPartnerNotesExists = (statusList) => {
  return (
    statusList?.internal_notes != null || statusList?.partner_notes != null
  );
};

export const checkIfCustomerNotesExists = (statusList) => {
  return statusList?.customer_notes != null;
};

export const validateDecimal = (input) => {
  return regexp.test(input);
};

export const convertToLocaleTime = (timeStr) => {
  const [hours, minutes, seconds] = timeStr.split(":");
  const dateObj = new Date();

  // Set the UTC hours, minutes, and seconds to the current date
  dateObj.setUTCHours(hours);
  dateObj.setUTCMinutes(minutes);
  dateObj.setUTCSeconds(seconds);

  // Convert to local time
  const localTimeStr = dateObj.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return localTimeStr;
};

export const getProfilePicture = (profile) => {
  if (profile) {
    if (profile?.includes("null")) {
      return user_image;
    }
    if (profile?.includes(media_url)) {
      return profile;
    } else {
      return `${REACT_APP_MEDIA_URL}${profile}`;
    }
  }
  return user_image;
};

export const emailFormat = (value) => {
  return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const getMessageTime = (time) => {
  return new Date(time).toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export async function delay(value) {
  await new Promise((resolve) => setTimeout(resolve, value));
}

export const setOrderDetails = (serviceDetails, addressDetails) => {
  const data = localStorage.getItem("order_details_service");
  if (!data) {
    localStorage.setItem(
      "order_details_service",
      JSON.stringify(serviceDetails)
    );
    localStorage.setItem(
      "order_details_address",
      JSON.stringify(addressDetails)
    );
  }
};

export const padTo2Digits = (num) => {
  return num?.toString().padStart(2, "0");
};

export const getLocalTimeZone = (dateTime, fromOrderAssign) => {
  return formatDate(dateTime, fromOrderAssign);
};

export const dateFormatter = (date) => {
  const dates = date?.substring(8, 10);
  const month = date?.substring(5, 7);
  const year = date?.substring(0, 4);
  return month + "/" + dates + "/" + year;
};

export const showInMapClicked = (orderDetail) => {
  const splitLine1 = orderDetail?.address?.line_1?.substring(
    orderDetail?.address?.line_1?.indexOf(" ") + 1
  );
  const joinLine1 = splitLine1?.split(" ").join("+");
  const getMarker = `${joinLine1},+${orderDetail?.address?.city_long_name},+${orderDetail?.address?.state_short_name},+USA`;
  window.open(
    `https://www.google.com/maps/place/${getMarker}/@${
      orderDetail?.address?.latitude
    },${orderDetail?.address?.longitude},${18}z`
  );
};

export const formatAddress = (address) => {
  return address?.replace(/,[^,]+$/, "");
};

export const formatPhoneNumber = (number) => {
  if (!number) return false;

  number = number.replace(/[^0-9]/g, "");

  const formatNumber = ("" + number).replace(/\D/g, "");
  const match = formatNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return number;
};

export const appendZero = (number) => {
  if (!number) return null;
  return number.toString().padStart(3, "0");
};

export const formatDate = (date, fromOrderAssign) => {
  if (fromOrderAssign) return moment(date).format("MM/DD/YYYY  h:mm A");
  return moment(date).format("MM/DD/YYYY");
};
export const getStripeTokeValue = async (props, details) => {
  try {
    let options = {
      type: "Card",
      name: "",
      currency: "usd",
    };
    const response = await props.stripe.createToken(details, options);

    return response;
  } catch (e) {
    return { error: e.message };
  }
};
export const getExpiryDateFormat = (value) => {
  return value
    .replace(/[^0-9]/g, "")
    .replace(/^([2-9])$/g, "0$1")
    .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2")
    .replace(/^0{1,}/g, "0")
    .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, "$1/$2");
};
