/** @format */

import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import * as colors from "../../assets/css/Colors";
import useStyles from "../../GlobalStyle";
import {
  add_address,
  boxShadow,
  card_details,
  no_address,
  PUBLISHABLE,
  select_billing_address,
  semiBold,
  total,
} from "../../config/Constants";
import { DPFContext } from "../../context";
import { formatter, getStripeTokeValue } from "../../config/Helper";
import { setTabValue } from "../../actions/HomeActions";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import strings from "../../languages/strings";
import { stripePayment } from "./services";
import { CommonError, Loader } from "../GeneralComponents";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import AddPayment from "../customersHistory/AddPayment";
import { useNavigate } from "react-router-dom";
import {
  setSelectedRow,
  setSelectedTab,
  discardSelectedPaymentCard,
  setSelectedPaymentCard,
} from "../../actions/CartActions";
import {
  CardElement,
  Elements,
  StripeProvider,
  injectStripe,
} from "react-stripe-elements";
import { addCardWithTokenPending } from "../../actions/CardListActions";
import { setEditOrder } from "../../actions/ProfileActions";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import AddAddress from "../account/AddAddress";
import { addCardWithToken } from "../customersHistory/services";
import { getOrderById, getOrderDetails } from "../ordersHistory/services";

const CardPayment = ({
  paymentAmount,
  handleClose,
  setShowModal,
  ...props
}) => {
  const styles = localStyles();
  const GlobalStyle = useStyles();
  const dispatch = useDispatch();
  const order = useSelector((state) => state.myOrders.orderItems);
  const [formAnchor, setFormAnchor] = useState(null);
  const [formattedCardNumber, setFormattedCardNumber] = useState(null);
  const [futureUse, setFutureUse] = useState(false);
  const [newCard, setNewCard] = useState(false);
  const [billingAddress, setBillingAddress] = useState(false);
  const [showModal, setShowModal1] = useState(false);
  const [tempCardDetails, setTempCardDetails] = useState(undefined);
  const [enterDetailsError, setEnterDetailsError] = useState(undefined);
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(undefined);

  const [showAddressList, setShowAddressList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultBillingAddress, setDefaultBillingAddress] = useState(null);
  const [formattedExpiry, setFormattedExpiry] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(undefined);
  const [state, setState] = useState({
    nick_name: "",
    name_on_card: "",
    errorMessage: {
      nick_name: "",
      name_on_card: "",
    },
  });

  const { orderDetail, cardError } = useSelector((state) => state.myOrders);
  const navigate = useNavigate();
  const { cardData, selectedPaymentCard } = useSelector(
    (state) => state.card_list
  );
  const { addressList } = useSelector((state) => state.address_list);
  const { setOrderDetails } = useContext(DPFContext);

  const context = useContext(DPFContext);

  useEffect(() => {
    const defaultBilling = addressList?.filter(
      (data) => data.is_default_billing
    );
    if (defaultBilling && defaultBilling.length > 0) {
      setDefaultBillingAddress(defaultBilling);
      setBillingAddress(true);
    }
  }, [addressList]);

  useEffect(() => {
    if (cardData === undefined) setNewCard(true);
  }, [cardData]);

  const handleBackClick = () => {
    setShowModal(false);
    context.setPaymentType(null);
    const getOrderId = window.location.href.split("/");
    getOrderById(dispatch, getOrderId[getOrderId.length - 1]);
    getOrderDetails(dispatch, getOrderId[getOrderId.length - 1]);
  };

  const onChangeInput = (key, value) => {
    setState({
      ...state,
      [key]: value,
      errorMessage: { ...state.errorMessage, [key]: "" },
    });

    return true;
  };

  const handleCardClick = async (cardItem, index) => {
    setSelectedCardIndex(index);
    dispatch(setSelectedPaymentCard(cardItem));
  };

  const cardPaymentMethod = async () => {
    if (newCard && futureUse) {
      const customer_id = orderDetail?.customer.id;
      if (tempCardDetails) {
        const tempAddress = {
          line_1: selectedAddress?.line_1,
          pin_code: selectedAddress?.pin_code,
          address: selectedAddress?.address,
          latitude: selectedAddress?.latitude,
          longitude: selectedAddress?.longitude,
          city_long_name: selectedAddress?.city_long_name,
          city_short_name: selectedAddress?.city_short_name,
          state_long_name: selectedAddress?.state_long_name,
          state_short_name: selectedAddress?.state_short_name,
          country_long_name: selectedAddress?.country_long_name,
          country_short_name: selectedAddress?.country_short_name,
        };
        const body = {
          cardDetails: tempCardDetails?.token,
          is_default_billing_address: billingAddress,
          address: billingAddress ? defaultBillingAddress[0] : tempAddress,
        };

        if (tempAddress?.address === undefined && !defaultBillingAddress[0])
          setEnterDetailsError(select_billing_address);
        else {
          const response = await addCardWithToken(
            dispatch,
            body,
            customer_id,
            state
          );
          if (response) {
            setFutureUse(false);
            setSelectedAddress(undefined);
            closeFormList();
          }
        }
      } else {
        setEnterDetailsError(card_details);
      }
    } else {
      setIsLoading(true);
      const response = await stripePayment(
        dispatch,
        selectedPaymentCard,
        orderDetail,
        !futureUse,
        paymentAmount,
        tempCardDetails
      );
      if (response) {
        setIsLoading(false);
        handleClose();
        dispatch(setSelectedRow(orderDetail.order_id));
        dispatch(setSelectedTab("detail"));
        dispatch(setTabValue(1));
        dispatch(setEditOrder(true));
        setOrderDetails(orderDetail.order_id);
        navigate(`/edit-order/${orderDetail?.order_id}`);
        window.location.reload();
      } else {
        setIsLoading(false);
      }
    }
  };

  const closeFormList = () => {
    dispatch(addCardWithTokenPending());
    setFormAnchor(null);
    setFutureUse(false);
    setBillingAddress(false);
    setNewCard(false);
  };

  const openFormList = () => {
    setState({
      nick_name: "",
      name_on_card: "",
    });
    setNewCard(true);
  };

  const handleChangeClick = () => {
    setShowAddressList(!showAddressList);
  };

  const onChangeText = async (key, value) => {
    let details = { [key]: value };
    if (details?.card?.complete) {
      const response = await getStripeTokeValue(props, details);
      setTempCardDetails(response);
      setEnterDetailsError(undefined);
    }
  };

  const handleFutureCheckBoxChange = () => {
    setFutureUse(!futureUse);
  };

  const handleCheckBoxChange = () => {
    setBillingAddress(!billingAddress);
  };

  const handleAddressClick = async (addressItem, index) => {
    setSelectedAddressIndex(index);
    setShowAddressList(false);
    setEnterDetailsError(undefined);
    setSelectedAddress(addressItem);
  };

  const handleAddressBackClick = () => {
    setShowAddressList(false);
  };

  const formList = Boolean(formAnchor);
  const formId = formList ? "form-popover" : undefined;

  return (
    <>
      {showModal ? (
        <AddAddress
          setAddAddress={setShowModal1}
          getService={false}
          customer={orderDetail?.customer}
          addCustomerAddress={true}
        />
      ) : showAddressList ? (
        <>
          <div className={styles.detailsHead1}>
            <ArrowBackIcon
              className={styles.icon}
              sx={{ color: colors.icon_color }}
              fontSize={"large"}
              onClick={handleAddressBackClick}
            />
            <span className={styles.title1}>Choose Billing Address</span>
          </div>
          <div className={styles.addressListContainer}>
            <p
              style={{
                fontWeight: "bold",
                textAlign: "left",
                display: "flex",
              }}>
              My Saved Addresses
            </p>
            {addressList?.length > 0 &&
              addressList?.map((addressItem, index) => (
                <div
                  className={
                    selectedAddressIndex === index
                      ? styles.selectedCard
                      : styles.addressCard1
                  }
                  key={addressItem.id}
                  onClick={() => handleAddressClick(addressItem, index)}>
                  <LocationOnIcon className={styles.cardIcon} />
                  <div className={styles.addressCardContainer}>
                    <span>{addressItem?.line_1}</span> <br />
                    {addressItem?.line_2 && (
                      <>
                        <span>{addressItem?.line_2}</span> <br />
                      </>
                    )}
                    <span>
                      {addressItem?.city_short_name},{" "}
                      {addressItem?.state_short_name} {addressItem?.pin_code}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <>
          <div className={styles.detailsHead1}>
            <ArrowBackIcon
              className={styles.icon}
              sx={{ color: colors.icon_color }}
              fontSize={"large"}
              onClick={handleBackClick}
            />
            <span className={styles.title1}>
              {strings.order_no}: {order?.customer_order_id}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}>
            {cardError && <CommonError type='textError' error={cardError} />}
            <div className={styles.cardsContainers}>
              {cardData?.length > 0 && !newCard ? (
                cardData?.map((cardItem, index) => (
                  <div
                    className={
                      selectedCardIndex === index
                        ? styles.selectedCard
                        : styles.addressCard
                    }
                    key={cardItem.id}
                    onClick={() => handleCardClick(cardItem, index)}>
                    <CreditCardIcon className={styles.cardIcon} />
                    <span className={styles.cardName}>
                      {`${cardItem?.brand} ending in ${cardItem?.last4}`}
                    </span>
                  </div>
                ))
              ) : (
                <div style={{ padding: "10px" }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      display: "flex",
                    }}>
                    Add Payment Method
                  </p>
                  <TextField
                    variant='filled'
                    placeholder='Card Nickname (Optional)'
                    onChange={(e) => onChangeInput("nick_name", e.target.value)}
                    color='grey'
                    value={state?.nick_name}
                    label='Card Nickname'
                    className={styles.input}
                  />
                  <TextField
                    variant='filled'
                    placeholder='Name on Card (Optional)'
                    onChange={(e) =>
                      onChangeInput("name_on_card", e.target.value)
                    }
                    color='grey'
                    value={state.name_on_card}
                    label='Name on Card'
                    className={styles.input}
                  />
                  <div className={styles.innerContainerCard}>
                    <CardElement
                      onChange={(data) => onChangeText("card", data)}
                      hidePostalCode={true}
                      className={styles.cardField}
                    />
                  </div>
                  <div style={{ display: "grid" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={futureUse}
                          onChange={handleFutureCheckBoxChange}
                          style={{ color: "#C90000" }}
                        />
                      }
                      label='Save this card for future use'
                    />
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={billingAddress}
                            onChange={handleCheckBoxChange}
                            style={{ color: "#C90000" }}
                          />
                        }
                        label='Use as default billing address'
                      />
                      {billingAddress && !selectedAddress && (
                        <>
                          {defaultBillingAddress &&
                          defaultBillingAddress.length > 0 ? (
                            <>
                              <span style={{ textAlign: "left" }}>
                                {defaultBillingAddress[0]?.line_1}
                              </span>
                              {defaultBillingAddress[0]?.line_2 && (
                                <>
                                  <span style={{ textAlign: "left" }}>
                                    {defaultBillingAddress[0]?.line_2}
                                  </span>{" "}
                                </>
                              )}
                              <span style={{ textAlign: "left" }}>
                                {defaultBillingAddress[0]?.city_short_name},{" "}
                                {defaultBillingAddress[0]?.state_short_name}{" "}
                                {defaultBillingAddress[0]?.pin_code}
                              </span>
                            </>
                          ) : (
                            <>{no_address}</>
                          )}
                        </>
                      )}
                      {selectedAddress && !billingAddress && (
                        <>
                          <span style={{ textAlign: "left" }}>
                            {selectedAddress?.line_1}
                          </span>
                          {selectedAddress?.line_2 && (
                            <>
                              <span style={{ textAlign: "left" }}>
                                {selectedAddress?.line_2}
                              </span>
                            </>
                          )}
                          <span style={{ textAlign: "left" }}>
                            {selectedAddress?.city_short_name},{" "}
                            {selectedAddress?.state_short_name}{" "}
                            {selectedAddress?.pin_code}
                          </span>
                        </>
                      )}
                    </>
                    {!billingAddress && !selectedAddress && (
                      <div className={styles.addNewCard}>
                        <div
                          className={styles.addAddressContainer}
                          onClick={() => setShowModal1(true)}>
                          <AddCircleIcon className={styles.cardIcon} />{" "}
                          <span className={styles.newCardText}>
                            {add_address}
                          </span>
                        </div>
                        <div>
                          <span
                            onClick={handleChangeClick}
                            className={styles.newCardText}
                            style={{ color: "#C90000", fontWeight: "bold" }}>
                            Change
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {!newCard && (
              <div className={styles.addCard} onClick={openFormList}>
                <AddCircleIcon className={styles.cardIcon} />{" "}
                <span className={styles.newCardText}> Add New Card</span>
              </div>
            )}
            <StripeProvider apiKey={PUBLISHABLE}>
              <Elements>
                <AddPayment
                  formId={formId}
                  formList={formList}
                  formAnchor={formAnchor}
                  closeFormList={closeFormList}
                  formattedCardNumber={formattedCardNumber}
                  setFormattedCardNumber={setFormattedCardNumber}
                  formattedExpiry={formattedExpiry}
                  setFormattedExpiry={setFormattedExpiry}
                  fromCheckout={true}
                  addressList={addressList}
                  customer={orderDetail?.customer}
                />
              </Elements>
            </StripeProvider>
            <hr className={GlobalStyle.divider1} />
            <div className={styles.infoBlock}>
              <span className={styles.notesText1}>{total}</span>
              <span
                className={styles.notesText1}
                style={{ color: colors.icon_color }}>
                {formatter.format(paymentAmount)}
              </span>
            </div>
            {isLoading && <Loader />}
            {enterDetailsError && (
              <CommonError type='textError' error={enterDetailsError} />
            )}
            <div className={styles.orderImageBlock}>
              <button
                className={styles.payButton}
                style={
                  selectedPaymentCard?.id == undefined
                    ? newCard || futureUse
                      ? {}
                      : { backgroundColor: "grey", cursor: "default" }
                    : {}
                }
                onClick={cardPaymentMethod}>
                {newCard ? (futureUse ? "Save" : "Pay") : "Submit"}
              </button>
              <button
                className={styles.payButton}
                style={{ backgroundColor: "grey" }}
                onClick={() => {
                  context.setPaymentType(null);
                  dispatch(discardSelectedPaymentCard());
                }}>
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const localStyles = makeStyles((theme) => {
  return {
    container: {
      background: colors.theme_fg,
      gridColumnStart: 3,
      gridRowStart: 1,
      gridRowEnd: 3,
      borderRadius: "5px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflow: "auto",
      width: "90%",
      "&::-webkit-scrollbar": {
        width: "0px",
      },
    },
    addressCardContainer: {
      textAlign: "left",
      paddingLeft: "15px",
    },
    addressCard1: {
      borderRadius: "5px",
      boxShadow: boxShadow,
      padding: "15px 30px",
      marginBottom: "30px",
      [theme.breakpoints.down("md")]: {
        padding: "15px",
      },
      width: "90%",
      marginLeft: "15px",
      textAlign: "Center",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      "&:hover": {
        backgroundColor: colors.icon_color,
        color: "white",
        cursor: "pointer",
        "& $cardIcon": {
          color: "white",
        },
      },
    },
    selectedCard: {
      borderRadius: "5px",
      boxShadow: boxShadow,
      padding: "15px 45px",
      marginBottom: "30px",
      [theme.breakpoints.down("md")]: {
        padding: "15px",
      },
      width: "100%",
      textAlign: "Center",
      display: "flex",
      alignItems: "center",
      backgroundColor: colors.icon_color,
      color: "white",
      cursor: "pointer",
      "& $cardIcon": {
        color: "white",
      },
    },
    addAddressContainer: {
      display: "flex",
      alignItems: "center",
    },
    addressListContainer: {
      height: "550px",
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: colors.theme_bg,
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: colors.icon_color,
        borderRadius: "5px",
      },
    },

    cardField: {
      backgroundColor: "#e5e5e5",
      borderColor: "#000000",
      // borderRadius: "10px",

      fontSize: 20,
      color: "black",
      height: "56px",
      padding: "20px",
      // marginBottom: "10px",
    },
    input: {
      backgroundColor: "none",
      border: "none",
      paddingLeft: "0px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      marginBottom: "20px",
      width: "100%",
      "& .MuiInputBase-root": {
        boxShadow: boxShadow,
        fontFamily: "Montserrat, sans-serif",
      },
    },
    addNewCard: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      marginTop: "10px",
      justifyContent: "space-between",
    },
    cardsContainers: {
      display: "flex",
      maxHeight: "27rem",
      minHeight: "min-content",
      overflowX: "auto",
      flexDirection: "column",
      width: "90%",
      marginBottom: "10px",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: colors.theme_bg,
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: colors.icon_color,
        borderRadius: "5px",
      },
    },
    innerContainerCard: {
      width: "100%",
      borderBottom: "2px solid #818181",
    },
    newCardText: {
      marginLeft: "5px",
    },
    addCard: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    text: {
      margin: "10px 0 30px",
      padding: 0,
      fontSize: "22px",
      lineHeight: "26px",
      width: "250px",
      textAlign: "center",
    },
    cardName: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "33px",
      marginLeft: "10px",
    },
    addressCard: {
      borderRadius: "5px",
      boxShadow: boxShadow,
      padding: "10px 45px",
      marginBottom: "20px",
      [theme.breakpoints.down("md")]: {
        padding: "15px",
      },
      width: "100%",
      textAlign: "Center",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        backgroundColor: colors.icon_color,
        color: "white",
        cursor: "pointer",
        "& $cardIcon": {
          color: "white",
        },
      },
    },

    cardIcon: {
      color: colors.icon_color,
    },
    icon: {
      cursor: "pointer",
    },
    notesText1: {
      fontSize: "24px",
      lineHeight: "27px",
      margin: 0,
      fontWeight: "bold",
    },
    infoBlock: {
      display: "flex",
      justifyContent: "space-between",
      width: "55%",
    },
    title1: {
      fontWeight: semiBold,
      fontSize: "24px",
      lineHeight: "35px",
      display: "table",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
        lineHeight: "30px",
        marginLeft: "30px",
        display: "table",
        margin: "0 auto",
      },
      [theme.breakpoints.between("md", "lg")]: {
        fontSize: "18px",
        marginLeft: "25px",
        display: "table",
        margin: "0 auto",
      },
    },
    checkoutTab: {
      background: "#c90000",
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "5px 5px 0 0",
      color: "#FFFFFF",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "35px",
      textTransform: "none",
      height: "59px",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    detailsHead1: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 20px",
      position: "sticky",
      background: colors.theme_fg,
      alignItems: "center",
      width: "95%",
      [theme.breakpoints.down("lg")]: {
        padding: "10px",
      },
    },
    checkboxBlock: {
      display: "flex",
      alignItems: "center",
      fontSize: "18px",
    },
    head: {
      fontWeight: semiBold,
      fontSize: "24px",
      lineHeight: "35px",
      marginTop: "60px",
    },
    image: {
      marginRight: "105px",
    },

    notesText: {
      fontSize: "18px",
      lineHeight: "27px",
      margin: 0,
    },

    orderImageBlock: {
      display: "flex",
      // flexDirection: "column",
      width: "80%",
      justifyContent: "space-between",
      alignItems: "center",
    },
    payButton: {
      border: "none",
      background: colors.icon_color,
      color: colors.text_white,
      fontSize: "20px",
      padding: "15px",
      width: "170px",
      borderRadius: "5px",
      cursor: "pointer",
      margin: "20px 0",
    },
  };
});

export default injectStripe(CardPayment);
