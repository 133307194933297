/** @format */

import * as Actions from "../actions/ActionTypes";
const RolesReducer = (
  state = {
    isLoading: false,
    error: undefined,
    partnerRolesList: [],
    partnerRolesCount: 0,
    partnerUsersLoading: false,
    partnerUsersList: undefined,
    deletePartnerUsersLoading: false,
    deletePartnerUserError: undefined,
  },
  action
) => {
  switch (action.type) {
    case Actions.GET_PARTNER_ROLES_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        error: undefined,
        partnerRolesList: [],
        partnerRolesCount: 0,
      });
    case Actions.GET_PARTNER_ROLES_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        error: undefined,
        partnerRolesList: action.data.role,
        partnerRolesCount: action.data.count,
      });
    }
    case Actions.GET_PARTNER_ROLES_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        partnerRolesList: [],
        partnerRolesCount: 0,
      });
    case Actions.GET_PARTNER_USERS_PENDING:
      return Object.assign({}, state, {
        partnerUsersLoading: true,
        partnerUsersList: undefined,
      });
    case Actions.GET_PARTNER_USERS_SUCCESS: {
      return Object.assign({}, state, {
        partnerUsersLoading: false,
        partnerUsersList: action.data,
      });
    }
    case Actions.GET_PARTNER_USERS_ERROR:
      return Object.assign({}, state, {
        partnerUsersLoading: false,
        partnerUsersList: undefined,
      });
    case Actions.DELETE_PARTNER_USER_PENDING:
      return Object.assign({}, state, {
        deletePartnerUsersLoading: true,
      });
    case Actions.DELETE_PARTNER_USER_SUCCESS: {
      return Object.assign({}, state, {
        deletePartnerUsersLoading: false,
      });
    }
    case Actions.DELETE_PARTNER_USER_ERROR:
      return Object.assign({}, state, {
        deletePartnerUsersLoading: false,
        deletePartnerUserError: action.error,
      });
    default:
      return state;
  }
};

export default RolesReducer;
