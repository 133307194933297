/** @format */

import { fetchPost, fetchGet } from "../../config/request";
import {
  CLEAR_NOTIFICATIONS,
  CREATE_CUSTOMER,
  CREATE_ORDER_URL,
  DASHBOARD_COUNT_URL,
  EDIT_ORDER,
  GET_MESSAGE_BY_ORDER_ID,
  GET_NOTIFICATIONS,
  GET_ORDER_BY_ID,
  GET_ORDER_ITEMS_URL,
  GET_ORDER_STATUS,
  GET_ORDER_URL,
  GET_PARTNER_USER_ORDER_URL,
  LINK_CUSTOMER,
  ORDER_COUNT_URL,
  SEARCH_CUSTOMER,
  SHARE_ORDER,
  UNSEEN_NOTIFICATION_COUNT,
  UPDATE_NOTIFICATION_COUNT,
} from "../../config/Constants";
import {
  serviceActiveOrdersError,
  serviceActionPending,
  serviceActionError,
  serviceActionSuccess,
  partnerCompletedOrders,
  partnerPendingOrders,
  partnerNewOrders,
  orderCountError,
  orderCountSuccess,
} from "../../actions/HomeActions";
import {
  editOrderError,
  editOrderPending,
  editOrderSuccess,
  getOrderByIdError,
  getOrderByIdPending,
  getOrderByIdSuccess,
  getOrderId,
  getOrderItems,
  getOrderItemsError,
  getOrderItemsSuccess,
  getOrderStatus,
  getOrderStatusError,
  getOrderStatusSuccess,
  orderTabPage,
  shareOrderError,
  shareOrderSuccess,
} from "../../actions/MyOrdersActions";
import { reset } from "../../actions/CartActions";
import {
  submitOrder,
  submitOrderError,
  submitOrderSuccess,
} from "../../actions/CheckoutAction";
import { addToCart } from "../../actions/ProductActions";
import {
  clearNotificationsError,
  clearNotificationsSuccess,
  getMessagesByOrderIdError,
  getMessagesByOrderIdSuccess,
  getNotificationsError,
  getNotificationsOnClickError,
  getNotificationsOnClickPending,
  getNotificationsOnClickSuccess,
  getNotificationsSuccess,
  getUnseenNotificationCountError,
  getUnseenNotificationCountSuccess,
  setLoadMore,
  updateNotificationCountError,
  updateNotificationCountSuccess,
} from "../../actions/CommonAction";
import {
  addUserError,
  addUserPending,
  addUserSuccess,
  linkCustomerError,
} from "../../actions/NewOrderActions";
import {
  searchCustomerError,
  searchCustomerSuccess,
} from "../../actions/SearchActions";

export async function getCompletedOrders(dispatch, isAssignOrderPermission) {
  getActiveOrder(dispatch, isAssignOrderPermission);
  try {
    const payload = {
      partner_id: JSON.parse(localStorage.getItem("user_data")).partner.id,
      partner_user_id: JSON.parse(localStorage.getItem("user_data")).id,
      isCompleteOrderList: true,
      offset: 0,
      limit: 1000,
    };
    if (isAssignOrderPermission) {
      delete payload.partner_user_id;
    } else {
      delete payload.partner_id;
    }
    const getOrderCompleted = await fetchPost(
      isAssignOrderPermission ? GET_ORDER_URL : GET_PARTNER_USER_ORDER_URL,
      payload
    );
    if (getOrderCompleted.error)
      return dispatch(serviceActiveOrdersError(getOrderCompleted.error));
    dispatch(partnerCompletedOrders(getOrderCompleted));
  } catch (e) {
    return dispatch(serviceActiveOrdersError(e));
  }
}

export async function getOrderCounts(dispatch, isAdmin) {
  try {
    const orderCount = await fetchGet(
      isAdmin
        ? `${DASHBOARD_COUNT_URL}?partner_id=${
            JSON.parse(localStorage.getItem("user_data")).partner.id
          }`
        : `${ORDER_COUNT_URL}?partner_user_id=${
            JSON.parse(localStorage.getItem("user_data")).id
          }`
    );
    if (orderCount.error) dispatch(orderCountError(orderCount.error));
    dispatch(orderCountSuccess(orderCount));
  } catch (e) {
    return dispatch(serviceActiveOrdersError(e));
  }
}

const getActiveOrder = async (dispatch, isAssignOrderPermission) => {
  const payload2 = {
    partner_id: JSON.parse(localStorage.getItem("user_data")).partner.id,
    partner_user_id: JSON.parse(localStorage.getItem("user_data")).id,
    isCompleteOrderList: false,
    offset: 0,
    limit: 1000,
  };
  if (isAssignOrderPermission) {
    delete payload2.partner_user_id;
  } else {
    delete payload2.partner_id;
  }
  const getOrderOthers = await fetchPost(
    isAssignOrderPermission ? GET_ORDER_URL : GET_PARTNER_USER_ORDER_URL,
    payload2
  );

  if (getOrderOthers.error) {
    dispatch(serviceActiveOrdersError(getOrderOthers.error));
  } else {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    let newOrder = getOrderOthers.orders.filter((data) => {
      return data.created_at.substring(0, 10) == today;
    });
    const pendingOrder = getOrderOthers.orders;
    dispatch(partnerPendingOrders(pendingOrder));
    dispatch(partnerNewOrders(newOrder));
  }
};

export const createNewUser = async (dispatch, state, welcomeEmail) => {
  dispatch(addUserPending());
  state.address = {
    ...state.address,
    address_name: "",
    is_default_billing: true,
  };
  const newUser = await fetchPost(`${CREATE_CUSTOMER}`, {
    customer_name: state.customer_name,
    company_name: state.company_name,
    address: state.address,
    phone_number: state.phone_number.toString().replace(/[- )(]/g, ""),
    email: state.email,
    partner_id: JSON.parse(localStorage.getItem("user_data")).partner?.id,
    send_welcome_email: !welcomeEmail,
  });
  if (newUser?.error) {
    dispatch(addUserError(newUser?.error));
    return false;
  } else {
    dispatch(addUserSuccess(newUser));
    return true;
  }
};

export const linkCustomer = async (dispatch, state) => {
  const newUser = await fetchPost(`${LINK_CUSTOMER}`, {
    phone_number: state.phone_number.toString().replace(/[- )(]/g, ""),
    email: state.email,
    partner_id: JSON.parse(localStorage.getItem("user_data")).partner.id,
  });
  if (newUser?.error) {
    dispatch(linkCustomerError(newUser?.error));
    return false;
  } else {
    return true;
  }
};

export const searchCustomer = async (dispatch, value) => {
  const search = await fetchPost(`${SEARCH_CUSTOMER}`, {
    query: value,
    partner_id: JSON.parse(localStorage.getItem("user_data")).partner.id,
  });
  if (search?.error) {
    dispatch(searchCustomerError(search?.error));
    return false;
  } else {
    dispatch(searchCustomerSuccess(search));
    return true;
  }
};

export const onGetOrderStatus = async (dispatch, orderId) => {
  dispatch(getOrderStatus());
  const status = await fetchGet(`${GET_ORDER_STATUS}?order_id=${orderId}`);
  if (status.error) {
    return dispatch(getOrderStatusError(status.error));
  }
  return dispatch(getOrderStatusSuccess(status));
};

export const getOrderDetails = async (dispatch, orderId) => {
  dispatch(getOrderItems());
  const orderData = await fetchGet(
    `${GET_ORDER_ITEMS_URL}?order_id=${orderId}`
  );
  if (orderData?.error) return dispatch(getOrderItemsError(orderData?.error));
  dispatch(getOrderItemsSuccess(orderData));
};

export const getOrderById = async (dispatch, orderId) => {
  dispatch(getOrderByIdPending());
  const orderDetail = await fetchGet(`${GET_ORDER_BY_ID}/${orderId}`);
  if (orderDetail.error) dispatch(getOrderByIdError(orderDetail.error));
  else dispatch(getOrderByIdSuccess(orderDetail));
};

export const editTheOrder = async (dispatch, body) => {
  dispatch(editOrderPending());
  const editOrder = await fetchPost(`${EDIT_ORDER}`, body);
  if (editOrder.error) dispatch(editOrderError(editOrder.error));
  else {
    const permify = JSON.parse(localStorage.getItem("__permifyUser"));

    dispatch(editOrderSuccess(editOrder));
    getCompletedOrders(
      dispatch,
      permify.roles.includes("admin_owner") || permify.roles.includes("admin")
    );
    getOrderById(dispatch, body.order_id);
    return true;
  }
};

export const shareOrder = async (dispatch, data, orderId) => {
  const orderData = await fetchPost(`${SHARE_ORDER}`, {
    orderId: orderId,
    receiver_email: data.email,
    subject: data.subject,
    from: data.from,
    to: data.to,
  });
  if (orderData?.error) return dispatch(shareOrderError(orderData?.error));
  else {
    dispatch(shareOrderSuccess(orderData));
  }
};

export const getNotificationsOnClick = async (dispatch, user_id, offset) => {
  dispatch(getNotificationsOnClickPending());
  const notification = await fetchPost(`${GET_NOTIFICATIONS}`, {
    receiver_id: user_id,
    receiver_type: "partner",
    offset,
    limit: 6,
  });
  if (notification?.error)
    return dispatch(getNotificationsOnClickError(notification?.error));
  else {
    dispatch(getNotificationsOnClickSuccess(notification));
    if (notification.messages.length === 0) dispatch(setLoadMore(false));
  }
};

export const getNotifications = async (dispatch, user_id, offset) => {
  const notification = await fetchPost(`${GET_NOTIFICATIONS}`, {
    receiver_id: user_id,
    receiver_type: "partner",
    offset,
    limit: 10,
  });
  if (notification?.error)
    return dispatch(getNotificationsError(notification?.error));
  else {
    dispatch(getNotificationsSuccess(notification));
    if (notification.messages.length === 0) dispatch(setLoadMore(false));
  }
};

export const clearNotifications = async (dispatch, user_id) => {
  const notification = await fetchPost(`${CLEAR_NOTIFICATIONS}`, {
    receiver_id: user_id,
    receiver_type: "partner",
  });
  if (notification?.error)
    return dispatch(clearNotificationsError(notification?.error));
  else {
    dispatch(clearNotificationsSuccess(notification));
  }
};

export const updateNotificationCount = async (
  dispatch,
  user_id,
  notification_id
) => {
  let updateNotification;
  if (notification_id) {
    updateNotification = await fetchGet(
      `${UPDATE_NOTIFICATION_COUNT}?receiver_id=${user_id}&receiver_type=partner&notification_id=${notification_id}`
    );
  } else {
    updateNotification = await fetchGet(
      `${UPDATE_NOTIFICATION_COUNT}?receiver_id=${user_id}&receiver_type=partner`
    );
  }
  if (updateNotification?.error)
    return dispatch(updateNotificationCountError(updateNotification?.error));
  else {
    dispatch(updateNotificationCountSuccess(updateNotification));
  }
};

export const getUnseenNotificationCount = async (dispatch, user_id) => {
  const unseenNotification = await fetchGet(
    `${UNSEEN_NOTIFICATION_COUNT}?receiver_id=${user_id}&receiver_type=partner`
  );
  if (unseenNotification?.error)
    return dispatch(getUnseenNotificationCountError(unseenNotification?.error));
  else {
    dispatch(getUnseenNotificationCountSuccess(unseenNotification));
  }
};

export const getMessagesByOrderId = async (dispatch, orderId, offset) => {
  const messagesByOrderId = await fetchGet(
    `${GET_MESSAGE_BY_ORDER_ID}?offset=${offset}&limit=500&order_id=${orderId}`
  );
  if (messagesByOrderId?.error)
    return dispatch(getMessagesByOrderIdError(messagesByOrderId?.error));
  else {
    dispatch(getMessagesByOrderIdSuccess(messagesByOrderId));
  }
};

export async function getCustomerOrders(dispatch, orderLoader, offset, limit) {
  try {
    dispatch(orderTabPage(0));
    if (orderLoader) dispatch(serviceActionPending(offset));
    const getOrder = await fetchPost(GET_ORDER_URL, {
      partner_id: JSON.parse(localStorage.getItem("user_data")).partner.id,
      isStatusWithComplete: true,
      offset,
      limit,
    });
    if (getOrder.error) return dispatch(serviceActionError(getOrder.error));
    dispatch(serviceActionSuccess(getOrder));
    return true;
  } catch (e) {
    return dispatch(serviceActionError(e));
  }
}

export async function addOrder(
  dispatch,
  cardList,
  setOrderDetails,
  setCartAddress,
  totalAmount,
  dateValue,
  discountAmount,
  amount,
  promoCodeDetails,
  cardFormValue,
  user,
  sendNotification,
  addressInfo
) {
  dispatch(submitOrder());

  const products = [];
  cardList.map((cardListValue) => {
    products.push({
      product_id: cardListValue.product_id,
      service_id: cardListValue.service_id,
      qty: 1,
      price: cardListValue.amount,
      product_addon_name: cardListValue.product_fee_name
        ? cardListValue.product_fee_name
        : "",
      product_addon_amount: cardListValue.product_fee_amount,
    });
  });

  const body = {
    customer_id: user?.id.toString(),
    service_address_id: addressInfo?.id,
    pickup_date: dateValue,
    pickup_time: null,
    delivery_date: null,
    delivery_time: null,
    total: totalAmount,
    discount: discountAmount,
    sub_total: amount,
    delivery_cost: null,
    deals_id: promoCodeDetails?.id || null,
    delivered_by: JSON.parse(localStorage.getItem("user_data")).partner.id,
    payment_mode: "after",
    service_id: cardList[0].service_id,
    service_addon_name: cardList[0].service_fee_name
      ? cardList[0].service_fee_name
      : "",
    service_addon_amount: cardList[0].service_fee,
    products,
    order_name: cardFormValue?.order_name || "",
    customer_comments: cardFormValue?.order_notes || "",
    billing_address_id: Number(1),
    send_email_to_customer: sendNotification,
  };

  const insertOrder = await fetchPost(CREATE_ORDER_URL, body);
  if (insertOrder.error) {
    dispatch(submitOrderError());
    return false;
  }
  dispatch(getOrderId(insertOrder.oder_id));
  setOrderDetails(null);
  setCartAddress(null);
  dispatch(addToCart(0, []));
  dispatch(submitOrderSuccess());
  dispatch(reset());
  try {
    localStorage.removeItem("cardOptionalValue");
    return true;
  } catch (exception) {
    return false;
  }
}

export const pendingPaymentFilter = (orders) => {
  return orders.filter(
    (order) =>
      order?.status?.length > 0 &&
      order?.status?.[order?.status?.length - 1]?.partner_status
        ?.label_for_partner === "Pending Payment"
  );
};
