/** @format */

import { combineReducers } from "@reduxjs/toolkit";
import RegisterReducer from "./RegisterReducer";
import AuthReducer from "./AuthReducer";
import LoginReducer from "./LoginReducer";
import ForgotReducer from "./ForgotReducer";
import CommonReducer from "./CommonReducer";
import ProfileReducer from "./ProfileReducer";
import HomeReducer from "./HomeReducer";
import MyOrdersReducer from "./MyOrdersReducer";
import ProductReducer from "./ProductReducer";
import AddressListReducer from "./AddressListReducer";
import ServicesReducer from "./ServicesReducer";
import CartReducer from "./CartReducer";
import CheckoutReducer from "./CheckoutReducer";
import ResetPasswordReducer from "./ResetPasswordReducer";
import AddressReducer from "./AddressReducer";
import NewOrderReducer from "./NewOrderReducer";
import SearchReducer from "./SearchReducer";
import CustomerListReducer from "./CustomerListReducer";
import RolesReducer from "./RolesReducer";
import PermissionReducer from "./PermissionReducer";
import OrderAssignmentReducer from "./OrderAssignmentReducer";
import CardReducer from "./CardReducer";

const allReducers = combineReducers({
  auth: AuthReducer,
  login: LoginReducer,
  register: RegisterReducer,
  forgot: ForgotReducer,
  common: CommonReducer,
  profile: ProfileReducer,
  home: HomeReducer,
  myOrders: MyOrdersReducer,
  product: ProductReducer,
  address_list: AddressListReducer,
  services: ServicesReducer,
  cart: CartReducer,
  checkout: CheckoutReducer,
  resetPassword: ResetPasswordReducer,
  address: AddressReducer,
  newOrder: NewOrderReducer,
  search: SearchReducer,
  customers: CustomerListReducer,
  roles: RolesReducer,
  permission: PermissionReducer,
  order_assign: OrderAssignmentReducer,
  card_list: CardReducer,

});

export default allReducers;
