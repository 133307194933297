/** @format */

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setShowAdditionalDetails } from "../../actions/AddressActions";
import { TextField } from "@mui/material";
import localStyles from "../account/localStyles";
import usePlacesAutocomplete from "use-places-autocomplete";

export default function AutocompleteAddress({
  onChangeAddress,
  error,
  errorText,
  addressValue,
  address,
  selectedCard,
}) {
  const styles = localStyles();
  const dispatch = useDispatch();

  const {
    ready,
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: "us",
      },
    },
    debounce: 300,
  });

  useEffect(() => {
    if (addressValue?.address) {
      setValue(addressValue?.line_1, false);
      dispatch(setShowAdditionalDetails(true));
    } else if (selectedCard) {
      setValue(selectedCard.address_line1, false);
    }
  }, []);

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const getSelectedAddress = (address) => {
    onChangeAddress(address);
    setValue(address.structured_formatting.main_text, false);
    clearSuggestions();
  };

  return (
    <div style={{ width: "100%" }}>
      <TextField
        label='Enter Address'
        fullWidth
        variant='filled'
        color='grey'
        className={styles.input}
        onChange={handleInput}
        value={address ? address : value}
        disabled={!ready}
        inputProps={{
          autoComplete: "off",
        }}
      />
      {data.length > 0 && (
        <div className={styles.addressList}>
          {data.map((address) => (
            <span
              key={address.id}
              className={styles.addressItem}
              onClick={() => getSelectedAddress(address)}>
              {address.description}
            </span>
          ))}
        </div>
      )}
      {error && (
        <span style={{ color: "#C90000", fontSize: 11 }}>{errorText}</span>
      )}
    </div>
  );
}
