/** @format */

import * as Actions from "../actions/ActionTypes";
import { customer_notes, internal_notes } from "../config/Constants";
const MyOrdersReducer = (
  state = {
    isLoading: false,
    error: undefined,
    orderList: [],
    message: undefined,
    status: undefined,
    filter_type: 0,
    orderId: undefined,
    orderItems: null,
    orderStatusLoader: false,
    orderStatusList: [],
    tabPage: 0,
    orderCount: 0,
    notificationOrderId: undefined,
    activeOrder: [],
    orderError: undefined,
    activeOrderLoading: false,
    successMessage: null,
    sharedOrder: null,
    orderDetail: undefined,
    originalOrder: undefined,
    originalOrderItems: undefined,
    products: [],
    searchIndexed: undefined,
    qtyErrorMessage: undefined,
    editOrderMsg: undefined,
    editedOrder: undefined,
    changeOrderStatusError: undefined,
    orderInvoiceError: undefined,
    orderPayment: undefined,
    editOrderLoader: false,
    changeStatusLoader: false,
  },
  action
) => {
  switch (action.type) {
    case Actions.MYORDERS_LIST_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        error: undefined,
        orderList: action.offset === 0 ? [] : [...state.orderList],
      });
    case Actions.MYORDERS_LIST_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case Actions.MYORDERS_LIST_SUCCESS: {
      let order = [...state.orderList];
      order = order.concat(action.data.getOrder);
      return Object.assign({}, state, {
        isLoading: false,
        orderList: order,
        orderCount: action.data.count,
        error: undefined,
      });
    }
    case Actions.SEND_NOTIFICATION_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        error: undefined,
      });
    case Actions.SEND_NOTIFICATION_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        isLoading: false,
      });
    case Actions.SEND_NOTIFICATION_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        error: undefined,
      });
    }
    case Actions.FILTER_TYPE:
      return Object.assign({}, state, {
        filter_type: action.data,
      });
    case Actions.GET_ORDER_ID:
      return Object.assign({}, state, {
        orderId: action.orderId,
      });
    case Actions.GET_ORDER_ITEMS:
      return Object.assign({}, state, {
        isLoading: true,
        orderItems: null,
        error: undefined,
      });
    case Actions.GET_ORDER_ITEMS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        orderItems: action.data,
        error: undefined,
      });
    case Actions.GET_ORDER_ITEMS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        orderItems: null,
        error: action.error,
      });
    case Actions.GET_ORDER_STATUS:
      return Object.assign({}, state, {
        orderStatusLoader: true,
        orderStatusList: [],
        error: undefined,
      });

    case Actions.GET_ORDER_STATUS_SUCCESS:
      return Object.assign({}, state, {
        orderStatusLoader: false,
        orderStatusList: action.orderStatusList,
        error: undefined,
      });
    case Actions.GET_ORDER_STATUS_ERROR:
      return Object.assign({}, state, {
        orderStatusLoader: false,
        orderStatusList: [],
        error: action.error,
      });
    case Actions.ORDER_TAB_PAGE:
      return Object.assign({}, state, {
        tabPage: action.page,
      });
    case Actions.NOTIFICATION_ORDER_ID:
      return Object.assign({}, state, {
        notificationOrderId: action.notificationOrderId,
      });
    case Actions.ACTIVE_ORDERS_PENDING:
      return Object.assign({}, state, {
        activeOrderLoading: true,
        activeOrder: action.offset === 0 ? [] : [...state.activeOrder],
        orderError: undefined,
        activeOrderCount: action.offset === 0 ? 0 : state.activeOrderCount,
      });
    case Actions.ACTIVE_ORDERS_SUCCESS: {
      let order = [...state.activeOrder];
      order = order.concat(action.data.orders);
      return Object.assign({}, state, {
        activeOrderLoading: false,
        activeOrder: order,
        orderError: undefined,
        activeOrderCount: action.data.count,
      });
    }
    case Actions.ACTIVE_ORDERS_ERROR:
      return Object.assign({}, state, {
        activeOrderLoading: false,
        orderError: action.error,
        activeOrder: [],
      });
    case Actions.SHARE_ORDER_PENDING:
    case Actions.SHARE_ORDER_ERROR:
      return Object.assign({}, state, {
        successMessage: null,
      });
    case Actions.SHARE_ORDER_SUCCESS:
      return Object.assign({}, state, {
        sharedOrder: action.data,
        successMessage: "Your order has been successfully shared",
      });
    case Actions.EDIT_ORDER_PENDING:
      return Object.assign({}, state, {
        editOrderMsg: undefined,
        editedOrder: undefined,
        editOrderLoader: true,
      });
    case Actions.EDIT_ORDER_SUCCESS:
      return Object.assign({}, state, {
        orderDetail: action.data.order,
        editOrderLoader: false,
      });

    case Actions.EDIT_ORDER_ERROR:
      return Object.assign({}, state, {
        editOrderMsg: action.error,
        editOrderLoader: false,
      });
    case Actions.GET_ORDER_BY_ID_PENDING:
    case Actions.GET_ORDER_BY_ID_ERROR:
      return Object.assign({}, state, {
        orderDetail: undefined,
      });
    case Actions.GET_ORDER_BY_ID_SUCCESS: {
      return Object.assign({}, state, {
        orderDetail: action.data.order,
        originalOrder: action?.data?.originalOrder,
        originalOrderItems: action.data.originalOrderItems,
        orderPayment: action.data.orderPayment,
      });
    }

    case Actions.SET_QTY_ERROR:
      return Object.assign({}, state, {
        qtyErrorMessage: undefined,
      });
    case Actions.HANDLE_EDIT_ORDER: {
      const orderDetail = { ...state.orderDetail };
      let qtyErrorMessage;
      if (action.data.updateKey === "qty") {
        let delivery_rate = 0;
        orderDetail.tax_rate_subtotal = 0;
        orderDetail.discount = 0;
        orderDetail.non_taxable_total = 0;
        orderDetail.taxable_total = 0;
        orderDetail[action.data.key][action.data.index].qty = Number(
          action.data.value
        );
        orderDetail["orderItems"].map((item) => {
          orderDetail.tax_rate_subtotal +=
            item.is_taxable &&
            (item.qty * (item?.price?.amount || item.price) || 0) *
              (Number(orderDetail.tax_rate) / 100);
          orderDetail.taxable_total +=
            item.is_taxable &&
            (item.qty * (item?.price?.amount || item.price) -
              Number(item?.discount) ||
              0);
          orderDetail.non_taxable_total +=
            !item.is_taxable &&
            (item.qty * (item?.price?.amount || item.price) -
              Number(item?.discount) ||
              0);
          orderDetail.discount += Number(item?.discount) || 0;
        });
        state?.orderItems?.addONs.map(
          (item) => (delivery_rate += item?.add_on_amount)
        );
        orderDetail.sub_total =
          orderDetail.taxable_total +
          orderDetail.tax_rate_subtotal +
          orderDetail.non_taxable_total;
        orderDetail.total =
          orderDetail.sub_total +
          delivery_rate +
          Number(orderDetail.adjustment) -
          (orderDetail?.deal ? orderDetail?.deal?.discount : 0);
      } else if (action.data.updateKey === "is_taxable") {
        orderDetail[action.data.key][action.data.index].is_taxable =
          action.data.value;
        let delivery_rate = 0;
        orderDetail.tax_rate_subtotal = 0;
        orderDetail.sub_total = 0;
        orderDetail.discount = 0;
        orderDetail.non_taxable_total = 0;
        orderDetail.taxable_total = 0;
        orderDetail["orderItems"].map((item) => {
          orderDetail.tax_rate_subtotal +=
            item.is_taxable &&
            ((item.qty * (item?.price?.amount || item.price) || 0) -
              (Number(item?.discount) || 0)) *
              (Number(orderDetail.tax_rate) / 100);
          orderDetail.taxable_total +=
            item.is_taxable &&
            item.qty * (item?.price?.amount || item.price) -
              (Number(item?.discount) || 0);
          orderDetail.non_taxable_total +=
            !item.is_taxable &&
            item.qty * (item?.price?.amount || item.price) -
              (Number(item?.discount) || 0);

          orderDetail.discount += Number(item?.discount) || 0;
        });
        state?.orderItems?.addONs.map(
          (item) => (delivery_rate += item?.add_on_amount)
        );
        orderDetail.sub_total =
          orderDetail.taxable_total +
          orderDetail.tax_rate_subtotal +
          orderDetail.non_taxable_total;
        orderDetail.total =
          orderDetail.sub_total +
          delivery_rate +
          Number(orderDetail.adjustment) -
          (orderDetail?.deal ? orderDetail?.deal?.discount : 0);
      } else if (action.data.updateKey === "description") {
        if (orderDetail[action.data.key][action.data.index].product) {
          orderDetail[action.data.key][action.data.index].product.description =
            action.data.value;
        } else {
          orderDetail[action.data.key][action.data.index].description =
            action.data.value;
        }
      } else if (action.data.updateKey === "discount") {
        orderDetail[action.data.key][action.data.index].discount =
          action.data.value;

        let delivery_rate = 0;
        orderDetail.tax_rate_subtotal = 0;
        orderDetail.sub_total = 0;
        orderDetail.discount = 0;
        orderDetail.non_taxable_total = 0;
        orderDetail.taxable_total = 0;
        orderDetail["orderItems"].map((item) => {
          orderDetail.tax_rate_subtotal +=
            item.is_taxable &&
            ((item.qty * (item?.price?.amount || item.price) || 0) -
              (Number(item?.discount) || 0)) *
              (Number(orderDetail.tax_rate) / 100);
          orderDetail.taxable_total +=
            item.is_taxable &&
            item.qty * (item?.price?.amount || item.price) -
              (Number(item?.discount) || 0);
          orderDetail.non_taxable_total +=
            !item.is_taxable &&
            item.qty * (item?.price?.amount || item.price) -
              (Number(item?.discount) || 0);

          orderDetail.discount += Number(item?.discount) || 0;
        });
        state?.orderItems?.addONs.map(
          (item) => (delivery_rate += item?.add_on_amount)
        );
        orderDetail.sub_total =
          orderDetail.taxable_total +
          orderDetail.tax_rate_subtotal +
          orderDetail.non_taxable_total;
        orderDetail.total =
          orderDetail.sub_total +
          delivery_rate +
          Number(orderDetail.adjustment) -
          (orderDetail?.deal ? orderDetail?.deal?.discount : 0);
      } else if (action.data.updateKey === "price") {
        orderDetail[action.data.key][action.data.index].price =
          action.data.value;

        let delivery_rate = 0;

        orderDetail.tax_rate_subtotal = 0;
        orderDetail.sub_total = 0;
        orderDetail.discount = 0;
        orderDetail.non_taxable_total = 0;
        orderDetail.taxable_total = 0;
        orderDetail["orderItems"].map((item) => {
          orderDetail.tax_rate_subtotal +=
            item.is_taxable &&
            ((item.qty * (item?.price?.amount || item.price) || 0) -
              (Number(item?.discount) || 0)) *
              (Number(orderDetail.tax_rate) / 100);
          orderDetail.taxable_total +=
            item.is_taxable &&
            item.qty * (item?.price?.amount || item.price) -
              (Number(item?.discount) || 0);
          orderDetail.non_taxable_total +=
            !item.is_taxable &&
            item.qty * (item?.price?.amount || item.price) -
              (Number(item?.discount) || 0);

          orderDetail.discount += Number(item?.discount) || 0;
        });
        state?.orderItems?.addONs.map(
          (item) => (delivery_rate += item?.add_on_amount)
        );
        orderDetail.sub_total =
          orderDetail.taxable_total +
          orderDetail.tax_rate_subtotal +
          orderDetail.non_taxable_total;
        orderDetail.total =
          orderDetail.sub_total +
          delivery_rate +
          Number(orderDetail.adjustment) -
          (orderDetail?.deal ? orderDetail?.deal?.discount : 0);
      } else if (action.data.updateKey === "product_name") {
        if (orderDetail[action.data.key][action.data.index].product) {
          orderDetail[action.data.key][action.data.index].product.product_name =
            action.data.value;
        } else {
          orderDetail[action.data.key][action.data.index].product_name =
            action.data.value;
        }
      }
      if (action.data.key === "tax_rate") {
        let delivery_rate = 0;

        orderDetail.tax_rate = action.data.value;
        orderDetail.tax_rate_subtotal = 0;
        orderDetail.sub_total = 0;
        orderDetail.non_taxable_total = 0;
        orderDetail.taxable_total = 0;
        orderDetail["orderItems"].map((item) => {
          orderDetail.tax_rate_subtotal +=
            item.is_taxable &&
            ((item.qty * (item?.price?.amount || item.price) || 0) -
              (Number(item?.discount) || 0)) *
              (Number(orderDetail.tax_rate) / 100);
          orderDetail.taxable_total +=
            item.is_taxable &&
            item.qty * (item?.price?.amount || item.price) -
              (Number(item?.discount) || 0);
          orderDetail.non_taxable_total +=
            !item.is_taxable &&
            item.qty * (item?.price?.amount || item.price) -
              (Number(item?.discount) || 0);
        });
        state?.orderItems?.addONs.map(
          (item) => (delivery_rate += item?.add_on_amount)
        );
        orderDetail.sub_total =
          orderDetail.taxable_total +
          orderDetail.tax_rate_subtotal +
          orderDetail.non_taxable_total;
        orderDetail.total =
          orderDetail.sub_total +
          delivery_rate +
          Number(orderDetail.adjustment) -
          (orderDetail?.deal ? orderDetail?.deal?.discount : 0);
      }
      if (action.data.key === "adjustment") {
        let delivery_rate = 0;
        state?.orderItems?.addONs.map(
          (item) => (delivery_rate += item?.add_on_amount)
        );
        orderDetail.adjustment = action.data.value;
        orderDetail.total =
          orderDetail.sub_total +
          delivery_rate +
          Number(action.data.value) -
          (orderDetail?.deal ? orderDetail?.deal?.discount : 0);
      }
      if (action.data.key === "adjustment_reason") {
        // let delivery_rate = 0;
        // state?.orderItems?.addONs.map(
        //   (item) => (delivery_rate += item?.add_on_amount)
        // );
        orderDetail.adjustment_reason = action.data.value;
      }

      if (action.data.key === customer_notes) {
        orderDetail[action.data.key] = action.data.value;
      }
      if (action.data.key === internal_notes) {
        orderDetail[action.data.key] = action.data.value;
      }
      if (action.data.key === "send_notification") {
        orderDetail[action.data.key] = action.data.value;
      }
      return Object.assign({}, state, {
        orderDetail,
        qtyErrorMessage,
      });
    }
    case Actions.SET_SELECTED_PRODUCT: {
      const orderDetail = { ...state.orderDetail };
      orderDetail["orderItems"][action.data.index] = action.data.data;
      orderDetail["orderItems"][action.data.index].qty = 1;
      let delivery_rate = 0;

      orderDetail.tax_rate_subtotal = 0;
      orderDetail.sub_total = 0;
      orderDetail.non_taxable_total = 0;
      orderDetail.taxable_total = 0;
      orderDetail["orderItems"].map((item) => {
        orderDetail.tax_rate_subtotal +=
          item.is_taxable &&
          ((item.qty * (item?.price?.amount || item.price) || 0) -
            (Number(item?.discount) || 0)) *
            (Number(orderDetail.tax_rate) / 100);
        orderDetail.taxable_total +=
          item.is_taxable &&
          item.qty * (item?.price?.amount || item.price) -
            (Number(item?.discount) || 0);
        orderDetail.non_taxable_total +=
          !item.is_taxable &&
          item.qty * (item?.price?.amount || item.price) -
            (Number(item?.discount) || 0);
      });
      state?.orderItems?.addONs.map(
        (item) => (delivery_rate += item?.add_on_amount)
      );
      orderDetail.sub_total =
        orderDetail.taxable_total +
        orderDetail.tax_rate_subtotal +
        orderDetail.non_taxable_total;
      orderDetail.total =
        orderDetail.sub_total +
        delivery_rate +
        Number(orderDetail.adjustment) -
        (orderDetail?.deal ? orderDetail?.deal?.discount : 0);
      return Object.assign({}, state, {
        orderDetail,
        products: [],
      });
    }
    case Actions.DELETE_SELECTED_PRODUCT: {
      const orderDetail = { ...state.orderDetail };
      orderDetail["orderItems"].splice(action.data.index, 1);
      orderDetail.tax_rate_subtotal = 0;
      orderDetail.sub_total = 0;
      orderDetail.non_taxable_total = 0;
      orderDetail.taxable_total = 0;
      orderDetail.discount = 0;
      let delivery_rate = 0;

      orderDetail["orderItems"].map((item) => {
        orderDetail.tax_rate_subtotal +=
          item.is_taxable &&
          ((item.qty * (item?.price?.amount || item.price) || 0) -
            (Number(item?.discount) || 0)) *
            (Number(orderDetail.tax_rate) / 100);
        orderDetail.taxable_total +=
          item.is_taxable &&
          item.qty * (item?.price?.amount || item.price) -
            (Number(item?.discount) || 0);
        orderDetail.non_taxable_total +=
          !item.is_taxable &&
          item.qty * (item?.price?.amount || item.price) -
            (Number(item?.discount) || 0);
        orderDetail.discount += Number(item?.discount) || 0;
      });
      state?.orderItems?.addONs.map(
        (item) => (delivery_rate += item?.add_on_amount)
      );
      orderDetail.sub_total =
        orderDetail.taxable_total +
        orderDetail.tax_rate_subtotal +
        orderDetail.non_taxable_total;
      orderDetail.total =
        orderDetail.sub_total +
        delivery_rate +
        Number(orderDetail.adjustment) -
        (orderDetail?.deal ? orderDetail?.deal?.discount : 0);
      return Object.assign({}, state, {
        orderDetail,
        products: [],
      });
    }
    case Actions.ADD_NEW_ROW: {
      const orderDetail = { ...state.orderDetail };
      orderDetail[action.key].push({});
      return Object.assign({}, state, {
        orderDetail,
      });
    }
    case Actions.CHANGE_ORDER_STATUS_PENDING:
      return Object.assign({}, state, {
        changeStatusLoader: true,
      });
    case Actions.CHANGE_ORDER_STATUS_ERROR: {
      return Object.assign({}, state, {
        changeOrderStatusError: action.error,
        changeStatusLoader: false,
      });
    }
    case Actions.CHANGE_ORDER_STATUS_SUCCESS:
      return Object.assign({}, state, {
        changeOrderStatusError: undefined,
        changeStatusLoader: false,
      });
    case Actions.SEARCH_PRODUCT_PENDING:
      return Object.assign({}, state, {
        products: [],
        searchIndexed: undefined,
      });
    case Actions.SEARCH_PRODUCT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        products: action.data.data,
        searchIndexed: action.data.index,
      });

    case Actions.SEARCH_PRODUCT_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        products: [],
      });
    case Actions.GET_ORDER_INVOICE_SUCCESS:
      return Object.assign({}, state, {
        orderInvoiceError: undefined,
      });
    case Actions.GET_ORDER_INVOICE_ERROR:
      return Object.assign({}, state, {
        orderInvoiceError: action.error,
      });
    default:
      return state;
  }
};

export default MyOrdersReducer;
