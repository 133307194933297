/** @format */

import React, { useContext, useEffect, useState } from "react";

import InfiniteScroll from "react-infinite-scroller";

import { makeStyles } from "@mui/styles";
import {
  normal,
  Orders,
  Customers,
  no_notifications,
  smallLogo99Partner,
} from "../../config/Constants";
import Moment from "react-moment";

import Tooltip from "@mui/material/Tooltip";

import { useNavigate } from "react-router-dom";
import * as colors from "../../assets/css/Colors";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ChatIcon from "@mui/icons-material/Chat";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Avatar, Badge, Modal, Popover, Snackbar } from "@mui/material";
import { checkIsAuthError } from "../../actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import { resetEditOrder, resetProfileData } from "../../actions/ProfileActions";
import { DPFContext } from "../../context";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import MobileMenu from "./MobileMenu";
import { setSelectedRow } from "../../actions/CartActions";

import {
  clearNotifications,
  getMessagesByOrderId,
  getNotifications,
  getNotificationsOnClick,
  getUnseenNotificationCount,
  updateNotificationCount,
} from "../ordersHistory/services";

import {
  getUnseenNotificationCountSuccess,
  updateMsgCountActive,
  updateMsgCountCompleted,
} from "../../actions/CommonAction";
import { getProfilePicture } from "../../config/Helper";
import {
  clearAllOrders,
  newOrderCreated,
  orderStatusChanged,
  setTabValue,
} from "../../actions/HomeActions";
import { socket } from "../../App";
import { Loader } from "../GeneralComponents";
import { toast } from "react-toastify";
import useStyles from "../../GlobalStyle";
import { usePermify } from "@permify/react-role";
import { reset } from "../../actions/CartActions";

const Header = () => {
  const dispatch = useDispatch();
  const styles = localStyles();
  const GlobalStyles = useStyles();
  const navigate = useNavigate();
  const { setUser } = usePermify();

  const user = JSON.parse(localStorage.getItem("user_data"));
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [active, setActive] = useState(false);
  const [activeCustomer, setActiveCustomer] = useState(false);

  const [stateToast, setStateToast] = useState(false);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const { notifications, unseenNotificationCount, notificationLoader } =
    useSelector((state) => state.common);
  const { loadMore } = useSelector((state) => state.common);

  useEffect(() => {
    if (window.location.href.includes("/Orders")) {
      setActive(true);
    } else if (window.location.href.includes("/Customers")) {
      setActiveCustomer(true);
    } else {
      setActive(false);
      setActiveCustomer(false);
    }
  }, [window.location.href]);

  useEffect(() => {
    socket?.on("connect", (socketConnection) => {
      console.log("socket connected in header", socketConnection);
    });

    socket?.on("new_order_created", (callback) => {
      console.log(callback, "order created");
      if (
        callback.success &&
        callback?.orderDetail?.deliveryBoy?.id ===
          JSON.parse(localStorage.getItem("user_data")).partner.id
      ) {
        dispatch(newOrderCreated(callback.orderDetail));
      }
    });

    socket?.on(
      `partner_notifications_${
        JSON.parse(localStorage.getItem("user_data")).id
      }`,
      (callback) => {
        console.log(callback, "partner notifications");
        if (callback.success) {
          dispatch(getUnseenNotificationCountSuccess(callback));
        }
      }
    );

    socket?.on(
      `message_count_${
        JSON.parse(localStorage.getItem("user_data")).partner.id
      }_partner`,
      (callback) => {
        console.log(callback, "message_count");
        dispatch(updateMsgCountActive(callback));
        dispatch(updateMsgCountCompleted(callback));
      }
    );

    socket?.on("status_update", (callback) => {
      console.log(callback, "order updated");
      if (
        callback.success &&
        callback?.orderDetail?.delivered_by ===
          JSON.parse(localStorage.getItem("user_data")).partner.id
      ) {
        dispatch(orderStatusChanged(callback.orderDetail));
      }
    });

    socket?.on("order_edited", (callback) => {
      console.log(callback, "order edited");
    });

    socket?.on("seen messages", (callback) => {
      console.log(callback, "seen messages");

      getMessagesByOrderId(
        dispatch,
        Number(localStorage.getItem("orderOpened")),
        0
      );
    });

    socket?.on(
      `${JSON.parse(localStorage.getItem("user_data")).id}_partner`,
      (callback) => {
        console.log(callback, "seen messages");
        const userData = callback.user_data;
        userData.auth_token = JSON.parse(
          localStorage.getItem("user_data")
        ).auth_token;

        localStorage.setItem("user_data", JSON.stringify(userData));

        let userRoles = [];

        callback?.user_data?.partnerRoles?.map((role) => {
          userRoles.push(role?.slug);
        });
        let userPermissions = [];
        callback?.user_data?.partnerPermissions?.map((permission) => {
          userPermissions.push(permission?.slug);
        });
        setUser({
          id: callback?.id?.toString(),
          roles: userRoles,
          permissions: userPermissions,
        });
      }
    );

    socket?.on("user received message", (callback) => {
      console.log("message received from server...", callback);

      getMessagesByOrderId(
        dispatch,
        Number(localStorage.getItem("orderOpened")),
        0
      );
    });
  }, [socket]);

  const {
    setAccount,
    setOrderDetails,
    account,
    setOrdersSort,
    setContact,
    thanksModal,
    setThanksModal,
    setCustomerDetails,
    setCartDetails,
  } = useContext(DPFContext);

  const matches = useMediaQuery("(max-width:1023px)");
  const [openMenu, setOpenMenu] = useState(false);

  const showUserMenu = (event) => {
    setUserAnchorEl(event.currentTarget);
  };

  const closeUserMenu = () => {
    setUserAnchorEl(null);
  };

  const showNotificationList = (event) => {
    getNotificationsOnClick(dispatch, localStorage.getItem("user_id"), 0);
    setNotificationAnchorEl(event.currentTarget);
  };

  const closeNotificationList = () => {
    setNotificationAnchorEl(null);
  };

  useEffect(() => {
    getNotifications(dispatch, localStorage.getItem("user_id"), 0);
    getUnseenNotificationCount(dispatch, localStorage.getItem("user_id"));
  }, []);

  const userMenu = Boolean(userAnchorEl);
  const userId = userMenu ? "user-popover" : undefined;
  const notificationList = Boolean(notificationAnchorEl);
  const notificationId = notificationList ? "notification-popover" : undefined;

  const openChatBubble = () => {
    if (window.location.href.includes("/Customers")) {
      navigate("/dashboard/chat");
    }
    setContact(true);
    setOrderDetails(null);
    setAccount(null);
    setOrdersSort(null);
    closeMenu();
    closeUserMenu();
  };

  const logOut = async () => {
    socket?.disconnect();
    await signOut(auth);
    dispatch(clearAllOrders());
    dispatch(resetProfileData());
    dispatch(checkIsAuthError());
    localStorage.removeItem("orderOpened");
    localStorage.removeItem("previouslyOpened");
    localStorage.removeItem("user_data");
    localStorage.removeItem("order_details_service");
    localStorage.removeItem("order_details_address");
    localStorage.removeItem("user_id");
    localStorage.removeItem("customer_name");
    localStorage.removeItem("id");
    toast.success("Logout Successfully");
  };

  const openAccount = () => {
    // navigate("/dashboard");
    setOrderDetails(null);
    setCustomerDetails(false);
    setAccount(true);
    closeMenu();
    closeUserMenu();
  };

  const closeMenu = () => {
    setOpenMenu(false);
  };

  const goToMainPage = () => {
    navigate("/dashboard");
    dispatch(resetEditOrder());
    setOrderDetails(null);
    setCartDetails(null);
    dispatch(reset());
    setAccount(null);
    setActive(false);
    setActiveCustomer(false);
  };

  const loadMoreData = (page) => {
    getNotifications(dispatch, localStorage.getItem("user_id"), 6 * page);
  };

  const openChatModule = (type, id, n_id) => {
    updateNotificationCount(dispatch, localStorage.getItem("user_id"), n_id);
    dispatch(setSelectedRow(id));
    if (window.location.href.includes("/new")) navigate("/dashboard");
    setOrderDetails(id);
    closeNotificationList();
    if (type === "CHAT_NOTIFICATION") {
      dispatch(setTabValue(2));
    } else {
      dispatch(setTabValue(1));
    }
    getUnseenNotificationCount(dispatch, localStorage.getItem("user_id"));
  };

  const messagePreview = (notification) => {
    if (notification?.message?.messageType === "text") {
      return `${notification?.message?.message}`;
    }
    if (notification?.message?.messageType === "file") {
      return "Shared a pdf";
    }
    if (notification?.message?.messageType === "image") {
      return (
        <img src={notification?.message?.url} className={styles.imgDiv}></img>
      );
    }
  };

  const handleClose = () => {
    setStateToast(false);
  };

  const navigateToOrder = () => {
    navigate("/Orders");
    dispatch(resetEditOrder());
    setCartDetails(null);
    dispatch(reset());

    if (active) {
      setActive(active);
    } else {
      setActive(!active);
      setActiveCustomer(false);
    }
  };

  const navigateToCustomer = () => {
    navigate("/Customers");
    setCartDetails(null);
    dispatch(reset());
    setActive(false);
    setActiveCustomer(true);
    dispatch(reset());
    setCartDetails(null);
  };

  const handleClearNotifications = () => {
    clearNotifications(dispatch, localStorage.getItem("user_id"));
    closeNotificationList();
  };

  const handleMarkAsRead = () => {
    updateNotificationCount(dispatch, localStorage.getItem("user_id"));
    closeNotificationList();
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftBlock}>
          <div className={styles.logo} onClick={goToMainPage}>
            <Tooltip title='Home'>
              <img src={smallLogo99Partner} alt='' width={matches ? 60 : 100} />
            </Tooltip>
          </div>
          {!matches && (
            <>
              <div
                className={active ? styles.focus : styles.headerMenuList}
                onClick={navigateToOrder}>
                <span>{Orders}</span>
              </div>
              <div
                className={
                  activeCustomer ? styles.focus : styles.headerMenuList
                }
                onClick={navigateToCustomer}>
                <span>{Customers}</span>
              </div>
            </>
          )}
        </div>
        <div className={styles.rightBlock}>
          {matches ? (
            <>
              <div className={styles.notificationsIcon}>
                <Badge
                  badgeContent={unseenNotificationCount}
                  classes={{ badge: styles.badgeSize }}>
                  <NotificationsIcon
                    sx={{
                      height: "40px",
                      width: "40px",
                      color: colors.theme_fg,
                    }}
                    onClick={showNotificationList}
                  />
                </Badge>
                <Popover
                  id={notificationId}
                  open={notificationList}
                  anchorEl={notificationAnchorEl}
                  onClose={closeNotificationList}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{ top: "5px" }}
                  className={styles.notificationPaper}>
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={(page) => loadMoreData(page)}
                    hasMore={loadMore}
                    useWindow={false}>
                    <div className={styles.notifications}>
                      {notificationLoader ? (
                        <Loader />
                      ) : notifications && notifications.length > 0 ? (
                        notifications?.map((notification) => (
                          <div
                            key={notification?.id}
                            className={styles.notification}
                            style={
                              notification.seen
                                ? {
                                    backgroundColor: "white",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#EDEDED",
                                    cursor: "pointer",
                                  }
                            }
                            onClick={() =>
                              openChatModule(
                                notification?.notification_type,
                                notification?.order_id,
                                notification?.id
                              )
                            }>
                            {notification?.notification_type ===
                            "CHAT_NOTIFICATION" ? (
                              <ChatIcon
                                style={{
                                  background: "none",
                                  boxShadow: "none",
                                }}
                              />
                            ) : (
                              <NotificationsIcon
                                style={{
                                  background: "none",
                                  boxShadow: "none",
                                }}
                              />
                            )}
                            <div className={styles.notificationInfo}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}>
                                <span className={styles.notificationName}>
                                  {notification?.notification_type ===
                                  "ORDER_CREATE"
                                    ? `Order #${notification?.order?.customer_order_id} is created`
                                    : notification?.notification_type ===
                                      "CHAT_NOTIFICATION"
                                    ? `New message for Order #${notification?.order?.customer_order_id}`
                                    : `Order #${notification?.order?.customer_order_id} was updated`}
                                </span>
                                {notification.created_at && (
                                  <span>
                                    <Moment
                                      style={{
                                        fontSize: "12px",
                                        color: "#898989",
                                        fontWeight: "bold",
                                      }}
                                      fromNow>
                                      {notification.created_at}
                                    </Moment>
                                  </span>
                                )}
                              </div>
                              <span
                                className={styles.notificationDescription}
                                style={
                                  notification?.message?.messageType === "image"
                                    ? { height: "150px" }
                                    : {}
                                }>
                                {notification?.notification_type ===
                                "ORDER_CREATE"
                                  ? "Your order has been created. Tap to view."
                                  : notification?.notification_type ===
                                    "CHAT_NOTIFICATION"
                                  ? messagePreview(notification)
                                  : "Your order has been updated. Tap to view."}
                              </span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className={styles.noNotification}>
                          {no_notifications}
                        </p>
                      )}
                    </div>
                  </InfiniteScroll>
                  {notifications.length > 0 && (
                    <div className={styles.notificationFooter}>
                      <p
                        className={styles.clearNotification}
                        onClick={() => {
                          handleClearNotifications();
                        }}>
                        Clear Notifications
                      </p>
                      <p
                        className={styles.clearNotification}
                        style={{ color: "#C90000", fontWeight: "bold" }}
                        onClick={() => {
                          handleMarkAsRead();
                        }}>
                        Mark all as Read
                      </p>
                    </div>
                  )}
                </Popover>
              </div>
              <MenuIcon
                fontSize={"large"}
                sx={{ color: colors.theme_fg }}
                onClick={() => setOpenMenu(!openMenu)}
              />
              <MobileMenu
                openMenu={openMenu}
                closeMenu={closeMenu}
                openAccount={openAccount}
                logOut={logOut}
              />
            </>
          ) : (
            <>
              <div className={styles.notificationsIcon}>
                <Badge
                  badgeContent={unseenNotificationCount}
                  classes={{ badge: styles.badgeSize }}>
                  <Tooltip title='Notifications'>
                    <NotificationsIcon
                      sx={{
                        height: "40px",
                        width: "40px",
                        color: colors.theme_fg,
                      }}
                      onClick={showNotificationList}
                    />
                  </Tooltip>
                </Badge>
                <Popover
                  id={notificationId}
                  open={notificationList}
                  anchorEl={notificationAnchorEl}
                  onClose={closeNotificationList}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{ top: "5px" }}
                  className={styles.notificationPaper}>
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={(page) => loadMoreData(page)}
                    hasMore={loadMore}
                    useWindow={false}>
                    <div className={styles.notifications}>
                      {notificationLoader ? (
                        <Loader />
                      ) : notifications && notifications.length > 0 ? (
                        notifications?.map((notification) => (
                          <div
                            key={notification?.id}
                            className={styles.notification}
                            style={
                              notification.seen
                                ? {
                                    backgroundColor: "#EDEDED",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "white",
                                    cursor: "pointer",
                                  }
                            }
                            onClick={() =>
                              openChatModule(
                                notification?.notification_type,
                                notification?.order_id,
                                notification?.id
                              )
                            }>
                            {notification?.notification_type ===
                            "CHAT_NOTIFICATION" ? (
                              <ChatIcon
                                style={{
                                  background: "none",
                                  boxShadow: "none",
                                }}
                              />
                            ) : (
                              <NotificationsIcon
                                style={{
                                  background: "none",
                                  boxShadow: "none",
                                }}
                              />
                            )}
                            <div className={styles.notificationInfo}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}>
                                <span
                                  className={styles.notificationName}
                                  style={
                                    notification.seen
                                      ? {
                                          fontWeight: 600,
                                        }
                                      : {
                                          fontWeight: "bold",
                                        }
                                  }>
                                  {notification?.notification_type ===
                                  "ORDER_CREATE"
                                    ? `Order #${notification?.order?.customer_order_id} is created`
                                    : notification?.notification_type ===
                                      "CHAT_NOTIFICATION"
                                    ? `New message for Order #${notification?.order?.customer_order_id}`
                                    : `Order #${notification?.order?.customer_order_id} was updated`}
                                </span>
                                {notification.created_at && (
                                  <span>
                                    <Moment
                                      style={
                                        notification.seen
                                          ? {
                                              fontSize: "12px",
                                              color: "#898989",
                                              fontWeight: 400,
                                            }
                                          : {
                                              fontSize: "12px",
                                              color: "#898989",
                                              fontWeight: "bold",
                                            }
                                      }
                                      fromNow>
                                      {notification.created_at}
                                    </Moment>
                                  </span>
                                )}
                              </div>
                              {notification?.notification_type ===
                              "CHAT_NOTIFICATION" ? (
                                <span
                                  className={styles.notificationDescription}
                                  style={
                                    notification?.message?.messageType ===
                                    "image"
                                      ? { height: "150px" }
                                      : {}
                                  }>
                                  {notification?.notification_type ===
                                  "ORDER_CREATE"
                                    ? "Your order has been created. Tap to view."
                                    : notification?.notification_type ===
                                      "CHAT_NOTIFICATION"
                                    ? messagePreview(notification)
                                    : "Your order has been updated. Tap to view."}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className={styles.noNotification}>
                          {no_notifications}
                        </p>
                      )}
                    </div>
                  </InfiniteScroll>
                  {notifications.length > 0 && (
                    <div className={styles.notificationFooter}>
                      <p
                        className={styles.clearNotification}
                        onClick={() => {
                          handleClearNotifications();
                        }}>
                        Clear Notifications
                      </p>
                      <p
                        className={styles.clearNotification}
                        style={{ color: "#C90000", fontWeight: "bold" }}
                        onClick={() => {
                          handleMarkAsRead();
                        }}>
                        Mark all as Read
                      </p>
                    </div>
                  )}
                </Popover>
              </div>
              <div className={styles.userInfo} onClick={showUserMenu}>
                <Avatar
                  variant='rounded'
                  src={getProfilePicture(user.profile_picture)}
                  sx={{ width: "40px", height: "40px" }}>
                  {user.customer_name}
                </Avatar>
                <div className={styles.info}>
                  <span className={styles.name} style={{}}>
                    {user.delivery_boy_name || user.name}
                  </span>
                  {user.company && <span>{user.company.name}</span>}
                </div>
                <ArrowDropDownIcon className={userAnchorEl && styles.active} />
              </div>
              <Popover
                id={userId}
                open={userMenu}
                anchorEl={userAnchorEl}
                onClose={closeUserMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{ top: "5px" }}>
                <div
                  className={styles.userMenu}
                  style={
                    window.location.href.includes("/OrderAssignment") ||
                    window.location.href.includes("/new")
                      ? { height: "60px" }
                      : {}
                  }>
                  {!window.location.href.includes("/OrderAssignment") &&
                    !window.location.href.includes("/new") && (
                      <span
                        onClick={openAccount}
                        className={styles.userMenuItem}
                        style={account ? { backgroundColor: "#c9c0c0" } : {}}>
                        Account
                      </span>
                    )}
                  <a
                    style={{ color: "black" }}
                    href={"https://www.99dpf.com/"}
                    target='_blank'
                    onClick={closeUserMenu}
                    className={styles.userMenuItem}
                    rel='noreferrer'>
                    FAQ
                  </a>
                  {!window.location.href.includes("/OrderAssignment") &&
                    !window.location.href.includes("/new") && (
                      <span
                        onClick={openChatBubble}
                        className={styles.userMenuItem}>
                        $99DPF Support
                      </span>
                    )}
                  <span onClick={logOut} className={styles.userMenuItem}>
                    Log out
                  </span>
                </div>
              </Popover>
            </>
          )}
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={stateToast}
        onClose={handleClose}
        message={"We have received your email"}
        key={"top" + "center"}
        autoHideDuration={5000}
      />
      <Modal open={thanksModal} onClose={() => setThanksModal(false)}>
        <div className={GlobalStyles.modal}>
          <CheckCircleIcon style={{ color: "green", fontSize: "64px" }} />
          <span className={styles.mainTitle}>We have received your email</span>
          <span className={styles.subTitle}>
            Our agent will contact you shortly on your provided email address
          </span>
        </div>
      </Modal>
      {/* <Support
        supportModal={supportModal}
        setSupperModal={setSupperModal}
        setStateToast={setStateToast}
      /> */}
    </>
  );
};

const localStyles = makeStyles((theme) => {
  return {
    container: {
      height: 80,
      position: "sticky",
      top: 0,
      zIndex: 5,
      background: colors.top_red_section,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "40px",
      fontWeight: normal,
      boxShadow: "0px 3px 6px #00000029",
      [theme.breakpoints.down("md")]: {
        paddingRight: "20px",
      },
    },
    leftBlock: {
      display: "flex",
      alignItems: "center",
    },
    notificationFooter: {
      display: "flex",
      justifyContent: "space-between",
      top: "295px",
      position: "sticky",
    },
    rightBlock: {
      display: "flex",
      alignItems: "center",
    },
    imgDiv: {
      width: "130px",
      height: "130px",
      marginBottom: "5px",
      objectFit: "cover",
    },
    logo: {
      width: 100,
      cursor: "pointer",
      margin: "40px 40px 0",
      marginTop: "28px",
      [theme.breakpoints.down("md")]: {
        margin: "5px 15px 0",
        width: 60,
      },
    },
    headerMenuList: {
      fontSize: 20,
      lineHeight: "30px",
      cursor: "pointer",
      color: "#bebebe",
      fontWeight: "600",
      paddingRight: "40px",
    },
    focus: {
      fontSize: 20,
      lineHeight: "30px",
      color: "white",
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "underline",
      textUnderlineOffset: "10px",
      marginRight: "40px",
    },
    notifications: {
      background: colors.theme_fg,
      width: "450px",
      height: "295px",
      boxSizing: "unset",
    },
    clearNotification: {
      textAlign: "center",
      position: "sticky",
      top: "295px",
      width: "50%",

      backgroundColor: "white",
      paddingTop: "10px",
      paddingBottom: "10px",
      cursor: "pointer",
      marginBlockStart: "0rem",
      marginBlockEnd: "0rem",
    },
    noNotification: {
      marginTop: "35%",
      textAlign: "center",
    },
    circularLoader: {
      color: colors.top_red_section,
      marginLeft: "45%",
      marginTop: "30%",
    },
    badgeSize: {
      fontSize: "12px !important",
      height: "18px !important",
      width: "18px !important",
      minWidth: "18px !important",
      top: "7px !important",
      right: "2px !important",
      backgroundColor: "black",
      color: "white",
    },
    help: {
      width: 45,
      cursor: "pointer",
      marginRight: 40,
    },
    notificationsIcon: {
      marginRight: 40,
      cursor: "pointer",
    },
    userInfo: {
      cursor: "pointer",
      width: "auto",
      color: colors.theme_fg,
      display: "flex",
      alignItems: "center",
    },
    info: {
      paddingLeft: 10,
      display: "flex",
      flexDirection: "column",
    },
    name: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      fontSize: 20,
      lineHeight: "20px",
      width: "auto",
    },
    userMenu: {
      width: 195,
      height: 120,
      // padding: "0 5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      fontSize: 20,
      lineHeight: "28px",
      "& span": {
        cursor: "pointer",
      },
    },
    mainTitle: {
      fontSize: "18px",
      fontWeight: "bold",
      marginTop: "10px",
    },
    subTitle: {
      color: "gray",
      fontWeight: "bold",
      fontSize: "14px",
      marginTop: "5px",
      textAlign: "center",
    },
    userMenuItem: {
      color: "black",
      paddingLeft: "10px",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
    active: {
      transform: "rotate(180deg)",
    },
    notificationPaper: {
      "& .MuiPopover-paper": {
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "white",
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: colors.icon_color,
          borderRadius: "5px",
        },
      },
      "& .MuiPaper-elevation": {
        marginLeft: "-140px",
      },
    },
    notification: {
      padding: "10px",
      display: "flex",
      "&& .MuiSvgIcon-root": {
        color: colors.icon_color,
        height: "40px",
        width: "40px",
        background: colors.bg_notification,
        borderRadius: "5px",
        boxShadow: "0px 3px 6px #00000029",
      },
    },
    notificationInfo: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginLeft: "10px",
    },
    notificationName: {
      fontSize: "14px",
      fontWeight: "bold",
      color: colors.theme_fg_two,
    },
    notificationDescription: {
      fontSize: "12px",
      color: colors.notification_desc,
    },
    cart: {
      display: "flex",
      alignItems: "center",
      marginRight: "40px",
      marginBottom: "5px",
      color: colors.theme_fg,
      cursor: "pointer",
      "& span": {
        fontSize: "20px",
        marginRight: "5px",
      },
      [theme.breakpoints.down("md")]: {
        marginRight: "20px",
      },
    },
  };
});

export default Header;
