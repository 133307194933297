/** @format */

import * as Actions from "../actions/ActionTypes";
const HomeReducer = (
  state = {
    isLoading: false,
    error: undefined,
    data: [],
    activeOrder: [],
    message: undefined,
    status: undefined,
    mainParentId: undefined,
    orderError: undefined,
    completeOrderDetails: [],
    pendingOrderDetails: [],
    newOrderDetails: [],
    orderStatusList: [],
    orderStatusListError: undefined,
    activeOrderLoading: false,
    tabValue: undefined,
    msgSeenOfOrderId: undefined,
    addUserLoading: false,
    addUserError: undefined,
    completeOrderCount: 0,
    pendingOrderCount: 0,
    todaysOrderCount: 0,
    totalOrderCount: 0,
  },
  action
) => {
  switch (action.type) {
    case Actions.HOME_SERVICE_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        data: [],
      });
    case Actions.HOME_SERVICE_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        data: [],
      });
    case Actions.HOME_SERVICE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        data: action.data,
      });
    case Actions.HOME_ACTIVE_ORDERS_PENDING:
      return Object.assign({}, state, {
        activeOrderLoading: true,
        activeOrder: action.offset === 0 ? [] : [...state.activeOrder],
        orderError: undefined,
        activeOrderCount: action.offset === 0 ? 0 : state.activeOrderCount,
      });
    case Actions.HOME_ACTIVE_ORDERS_SUCCESS: {
      let order = [...state.activeOrder];
      order = order.concat(action.data.getOrder);
      return Object.assign({}, state, {
        activeOrderLoading: false,
        activeOrder: order,
        orderError: undefined,
        activeOrderCount: action.data.count,
      });
    }
    case Actions.PARTNER_COMPLETE_ORDERS: {
      return Object.assign({}, state, {
        completeOrderDetails: action.orderDetail.orders,
      });
    }
    case Actions.CLEAR_ALL_ORDERS: {
      return Object.assign({}, state, {
        completeOrderDetails: [],
        pendingOrderDetails: [],
        newOrderDetails: [],
      });
    }
    case Actions.PARTNER_PENDING_ORDERS: {
      return Object.assign({}, state, {
        pendingOrderDetails: action.orderDetail,
      });
    }
    case Actions.PARTNER_NEW_ORDERS: {
      return Object.assign({}, state, {
        newOrderDetails: action.orderDetail,
      });
    }
    case Actions.HOME_ACTIVE_ORDERS_ERROR:
      return Object.assign({}, state, {
        activeOrderLoading: false,
        orderError: action.error,
        activeOrder: [],
      });
    case Actions.GET_ORDER_STATUS_LIST_ERROR:
      return Object.assign({}, state, {
        orderStatusListError: action.error,
      });
    case Actions.GET_ORDER_STATUS_LIST_SUCCESS:
      return Object.assign({}, state, {
        orderStatusList: action.orderStatusList,
      });
    case Actions.MAIN_PARENT_ID:
      return Object.assign({}, state, {
        mainParentId: action.mainParentId,
      });
    case Actions.SET_TAB_VALUE:
      return Object.assign({}, state, {
        tabValue: action.data,
      });

    case Actions.RESET_TAB_VALUE:
      return Object.assign({}, state, {
        tabValue: undefined,
      });
    case Actions.UPDATE_UNSEEN_MSG_COUNT_ACTIVE: {
      // check in active orders
      const activeOrderIndex = state?.newOrderDetails?.findIndex(
        (order) => order.order_id === action.data
      );
      if (state.newOrderDetails.length > 0 && activeOrderIndex != -1)
        state.newOrderDetails[activeOrderIndex].unseen_message_count = 0;

      const completeOrderIndex = state?.completeOrderDetails?.findIndex(
        (order) => order.order_id === action.data
      );
      if (state.completeOrderDetails.length > 0 && completeOrderIndex != -1)
        state.completeOrderDetails[completeOrderIndex].unseen_message_count = 0;

      const pendingOrderIndex = state?.pendingOrderDetails?.findIndex(
        (order) => order.order_id === action.data
      );

      if (state.pendingOrderDetails.length > 0 && pendingOrderIndex != -1)
        state.pendingOrderDetails[pendingOrderIndex].unseen_message_count = 0;

      return Object.assign({}, state, {
        newOrderDetails: state.newOrderDetails,
        completeOrderDetails: state.completeOrderDetails,
        pendingOrderDetails: state.pendingOrderDetails,
      });
    }
    case Actions.UPDATE_MSG_COUNT_COMPLETED: {
      // check in completed orders
      const activeOrderIndex = state?.newOrderDetails?.findIndex(
        (order) => order.order_id === action.data.order_id
      );
      if (state.newOrderDetails.length > 0 && activeOrderIndex != -1)
        state.newOrderDetails[activeOrderIndex].unseen_message_count =
          action.data.count;

      const completeOrderIndex = state?.completeOrderDetails?.findIndex(
        (order) => order.order_id === action.data.order_id
      );
      if (state.completeOrderDetails.length > 0 && completeOrderIndex != -1)
        state.completeOrderDetails[completeOrderIndex].unseen_message_count =
          action.data.count;

      const pendingOrderIndex = state?.pendingOrderDetails?.findIndex(
        (order) => order.order_id === action.data.order_id
      );
      if (state.pendingOrderDetails.length > 0 && pendingOrderIndex != -1)
        state.pendingOrderDetails[pendingOrderIndex].unseen_message_count =
          action.data.count;

      return Object.assign({}, state, {
        newOrderDetails: state.newOrderDetails,
        completeOrderDetails: state.completeOrderDetails,
        pendingOrderDetails: state.pendingOrderDetails,
      });
    }
    case Actions.ORDER_STATUS_CHANGED: {
      const orderIndex = state.pendingOrderDetails.findIndex(
        (item) => item.order_id === action?.data?.order_id
      );
      state.pendingOrderDetails[orderIndex] = action.data;

      return Object.assign({}, state, {
        pendingOrderDetails: state.pendingOrderDetails,
      });
    }
    case Actions.NEW_ORDER_CREATED:
      state.pendingOrderDetails.unshift(action.data);
      return Object.assign({}, state, {
        activeOrder: state.pendingOrderDetails,
      });
    case Actions.ADD_PARTNER_USER_PENDING:
      return Object.assign({}, state, {
        addUserLoading: true,
      });
    case Actions.ADD_PARTNER_USER_SUCCESS:
      return Object.assign({}, state, {
        addUserLoading: false,
        addUserError: undefined,
      });
    case Actions.ADD_PARTNER_USER_ERROR:
      return Object.assign({}, state, {
        addUserLoading: false,
        addUserError: action.error,
      });
    case Actions.CLEAR_ADD_USER_ERROR:
      return Object.assign({}, state, {
        addUserError: undefined,
      });
    case Actions.ORDER_COUNT_SUCCESS:
      return Object.assign({}, state, {
        completeOrderCount: action.data.completeOrderCount,
        pendingOrderCount: action.data.pendingOrderCount,
        todaysOrderCount: action.data.todaysOrderCount,
        totalOrderCount: action.data.totalOrderCount,
      });

    case Actions.ORDER_COUNT_ERROR:
      return Object.assign({}, state, {
        completeOrderCount: 0,
        pendingOrderCount: 0,
        todaysOrderCount: 0,
        totalOrderCount: 0,
      });
    default:
      return state;
  }
};

export default HomeReducer;
