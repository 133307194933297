/** @format */

import * as ActionTypes from "./ActionTypes";

export const getPartnerRolesPending = () => ({
  type: ActionTypes.GET_PARTNER_ROLES_PENDING,
});

export const getPartnerRolesError = (error) => ({
  type: ActionTypes.GET_PARTNER_ROLES_ERROR,
  error: error,
});

export const getPartnerRolesSuccess = (data) => ({
  type: ActionTypes.GET_PARTNER_ROLES_SUCCESS,
  data,
});

export const getPartnerUsersPending = () => ({
  type: ActionTypes.GET_PARTNER_USERS_PENDING,
});

export const getPartnerUsersError = (error) => ({
  type: ActionTypes.GET_PARTNER_USERS_ERROR,
  error: error,
});

export const getPartnerUsersSuccess = (data) => ({
  type: ActionTypes.GET_PARTNER_USERS_SUCCESS,
  data,
});

export const deletePartnerUsersPending = () => ({
  type: ActionTypes.DELETE_PARTNER_USER_PENDING,
});

export const deletePartnerUsersError = (error) => ({
  type: ActionTypes.DELETE_PARTNER_USER_ERROR,
  error: error,
});

export const deletePartnerUsersSuccess = (data) => ({
  type: ActionTypes.DELETE_PARTNER_USER_SUCCESS,
  data,
});
