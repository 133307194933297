/** @format */

import * as Actions from "../actions/ActionTypes";
const SearchReducer = (
  state = {
    isLoading: false,
    error: undefined,
    customers: [],
  },
  action
) => {
  switch (action.type) {
    case Actions.SEARCH_CUSTOMER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        customers: action.data,
      });
    case Actions.SEARCH_CUSTOMER_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        customers: [],
      });
    case Actions.RESET_SEARCHED_CUSTOMER:
      return Object.assign({}, state, {
        customers: [],
      });
    case Actions.CONTACT_US_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case Actions.CONTACT_US_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

export default SearchReducer;
