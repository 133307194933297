/** @format */

import * as Actions from "../actions/ActionTypes";
const AddressListReducer = (
  state = {
    isLoading: false,
    error: undefined,
    addressList: undefined,
    message: undefined,
    status: undefined,
    address_count: undefined,
    addressId: undefined,
    type: undefined,
    addressInfo: null,
    isLocationPermissionDenied: false,
    nearByServiceAddressLoader: false,
    nearByServiceAddress: [],
    nearByServiceAddressError: undefined,
    nearByServiceAddressErrorIndexed: undefined,
    locationCoordinates: undefined,
  },
  action
) => {
  switch (action.type) {
    case Actions.ADDRESS_LIST_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        addressList: [],
        addressInfo: null,
        error: undefined,
      });
    case Actions.ADDRESS_LIST_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        addressInfo: null,
        addressList: [],
      });
    case Actions.ADDRESS_LIST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        addressList: action.data,
        address_count: action.data.length,
        error: undefined,
      });

    case Actions.ADDRESS_DELETE_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case Actions.ADDRESS_DELETE_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case Actions.ADDRESS_DELETE_SUCCESS:
      if (action.data.status != 1) {
        return Object.assign({}, state, {
          isLoading: false,
          status: action.data.status,
          message: action.data.message,
        });
      } else {
        return Object.assign({}, state, {
          isLoading: false,
          status: action.data.status,
          message: action.data.message,
          addressList: action.data,
          address_count: action.data.length,
        });
      }
    case Actions.GET_CUSTOMER_SERVICE_ADDRESS_ID: {
      return Object.assign({}, state, {
        addressId: action.addressInfo.id,
        addressInfo: action.addressInfo,
        type: action.addressType,
      });
    }
    case Actions.SET_LOCATION_COORDINATES:
      return Object.assign({}, state, {
        locationCoordinates: action.data,
      });
    case Actions.LOCATION_PERMISSION_DENIED:
      return Object.assign({}, state, {
        isLocationPermissionDenied: action.isLocationPermissionDenied,
      });
    case Actions.GET_NEAR_BY_SERVICE_ADDRESS:
      return Object.assign({}, state, {
        nearByServiceAddressLoader: true,
        nearByServiceAddress: [],
        nearByServiceAddressError: null,
        nearByServiceAddressErrorIndexed: undefined,
      });
    case Actions.GET_NEAR_BY_SERVICE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        nearByServiceAddressLoader: false,
        nearByServiceAddress: action.nearByServiceAddress,
        nearByServiceAddressError: null,
        nearByServiceAddressErrorIndexed: undefined,
      });
    case Actions.GET_NEAR_BY_SERVICE_ADDRESS_ERROR:
      return Object.assign({}, state, {
        nearByServiceAddressLoader: false,
        nearByServiceAddress: [],
        nearByServiceAddressError: action.error.error,
        nearByServiceAddressErrorIndexed: action.error.index,
      });
    default:
      return state;
  }
};

export default AddressListReducer;
