/** @format */

import { makeStyles } from "@mui/styles";
import { boxShadow, semiBold } from "../../config/Constants";
import * as colors from "../../assets/css/Colors";

const localStyles = makeStyles((theme) => {
  return {
    addressCard: {
      borderRadius: "5px",
      boxShadow: boxShadow,
      padding: "15px 45px",
      marginBottom: "30px",
      [theme.breakpoints.down("md")]: {
        padding: "15px",
      },
    },
    cardName: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "33px",
      display: "flex",
      justifyContent: "left",
      marginBottom: "10px",
    },
    buttons: {
      marginTop: "10px",
      paddingLeft: "10px",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
    addressEditBtn: {
      background: colors.dark_color,
      fontSize: "14px",
      fontFamily: "Montserrat, sans-serif",
      border: "none",
      cursor: "pointer",
      padding: "15px",
      boxShadow: boxShadow,
      marginRight: "20px",
      borderRadius: "5px",
      width: "120px",
      [theme.breakpoints.down("md")]: {
        marginRight: 0,
        marginBottom: "20px",
        width: "100%",
      },
    },
    addressDeleteBtn: {
      background: colors.icon_color,
      fontSize: "14px",
      color: colors.text_white,
      fontFamily: "Montserrat, sans-serif",
      border: "none",
      cursor: "pointer",
      padding: "15px",
      boxShadow: boxShadow,
      borderRadius: "5px",
      width: "120px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },

    container: {
      paddingTop: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    head: {
      fontSize: "24px",
      fontWeight: semiBold,
    },
    subHead: {
      fontSize: "22px",
      marginTop: "10px",
      textAlign: "center",
    },
    orderCard: {
      boxShadow: boxShadow,
      borderRadius: "5px",
      marginTop: "20px",
      padding: "20px",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
    },
    status_tab_image_view: {
      borderStyle: "solid",
      borderRadius: "50%",
      borderColor: colors.theme_bg_red,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "20px",
    },
    orderNumber: {
      fontSize: "16px",
      fontWeight: semiBold,
      display: "block",
    },
    date: {
      fontSize: "12px",
      opacity: "0.5",
      display: "block",
      marginTop: "5px",
    },
    status: {
      fontSize: "12px",
      fontWeight: semiBold,
      display: "block",
      marginTop: "5px",
    },
    descriptionBlock: {
      display: "flex",
      alignItems: "center",
    },
    amountBlock: {
      marginLeft: "20px",
      marginTop: "30px",
    },
    total: {
      fontSize: "12px",
      display: "block",
      textAlign: "end",
    },
    info: {
      maxHeight: "43em",
      minHeight: "43em",
      "&::-webkit-scrollbar-track": {
        background: "#EDEDED",
        marginTop: "40px",
        borderRadius: "5px",
        [theme.breakpoints.down("md")]: {
          marginTop: "60px",
        },
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#C90000",
        borderRadius: "5px",
      },
      [theme.breakpoints.down("lg")]: {
        padding: "15px",
      },
    },
    detailsHead: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 20px",
      background: colors.theme_fg,
      alignItems: "center",
      position: "sticky",
      top: 60,
      zIndex: 2,
      [theme.breakpoints.down("lg")]: {
        padding: "10px",
      },
    },
    icon: {
      cursor: "pointer",
      color: colors.icon_color,
    },
    headingText: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },

    title: {
      fontWeight: semiBold,
      fontSize: "24px",
      lineHeight: "35px",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
        lineHeight: "30px",
        marginLeft: "30px",
      },
      [theme.breakpoints.between("md", "lg")]: {
        fontSize: "18px",
        marginLeft: "25px",
      },
    },
    cardsBlock: {
      marginTop: "30px",
      padding: "0 40px",
    },

    containerAddPayment: {
      paddingTop: "35px",
      paddingBottom: "0px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: "5px",
      background: colors.theme_bg,
      maxWidth: "100%",
    },
    closeIcon: {
      marginLeft: "90%",
      marginTop: "-20px",
      cursor: "pointer",
    },
    dpfIcon: {
      display: "flex",
      marginTop: "-30px",
    },
    headAddPayment: {
      fontSize: "22px",
      fontWeight: semiBold,
      marginBottom: "5px",
    },
    cardElement: {
      backgroundColor: "white",
      padding: "30px",
      width: "100%",
    },
    innerContainerCard: {
      width: "95%",
    },
    cardField: {
      backgroundColor: "#e5e5e5",
      borderColor: "#000000",
      borderRadius: "10px",
      fontSize: 14,
      color: "black",
      height: "30px",
      padding: "7px",
      marginBottom: "10px",
    },
    outerContainer: {
      display: "flex",
    },
    widthContainer: {
      width: "50%",
    },
    inputEmailAddress: {
      width: "100%",
      height: "45px",
    },
    addCard: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      marginTop: "10px",
      justifyContent: "space-between",
    },
    addAddressContainer: {
      display: "flex",
      alignItems: "center",
    },
    newCardText: {
      marginLeft: "5px",
    },
    addressListContainer: {
      height: "250px",
      overflowX: "auto",
    },
    selectedCard: {
      borderRadius: "5px",
      boxShadow: boxShadow,
      padding: "15px 45px",
      marginBottom: "30px",
      [theme.breakpoints.down("md")]: {
        padding: "15px",
      },
      width: "70%",
      textAlign: "Center",
      display: "flex",
      alignItems: "center",
      backgroundColor: colors.icon_color,
      color: "white",
      cursor: "pointer",
      "& $cardIcon": {
        color: "white",
      },
    },
    addressListingContainer: {
      borderRadius: "5px",
      padding: "10px 0px",
      [theme.breakpoints.down("md")]: {
        padding: "15px",
      },
      textAlign: "Center",
      display: "flex",
      alignItems: "center",
      color: "black",
      cursor: "pointer",
    },
    addressCard1: {
      borderRadius: "5px",
      boxShadow: boxShadow,
      padding: "15px 45px",
      marginBottom: "30px",
      [theme.breakpoints.down("md")]: {
        padding: "15px",
      },
      width: "90%",
      marginLeft: "15px",
      textAlign: "Center",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      "&:hover": {
        backgroundColor: colors.icon_color,
        color: "white",
        cursor: "pointer",
        "& $cardIcon": {
          color: "white",
        },
      },
    },
    cardIcon: {
      color: colors.icon_color,
    },
    addressCardContainer: {
      textAlign: "center",
      paddingLeft: "15px",
    },
    submitButton: {
      width: "100%",
      background: colors.icon_color,
      color: colors.text_white,
      display: "flex",
      justifyContent: "center",
      fontSize: "18px",
      padding: "14px",
      margin: "30px auto 0",
      border: "none",
      cursor: "pointer",
      borderRadius: "5px",
      fontFamily: "Montserrat, sans-serif",
    },
    modalContact: {
      backgroundColor: "white",
      marginTop: "2%",
      marginLeft: "35%",
      width: "500px",
      padding: "20px",
      borderRadius: "5px",
      textAlign: "center",
    },

    submitButtonLocation: {
      width: "260px",
      background: colors.icon_color,
      color: colors.text_white,
      display: "flex",
      justifyContent: "center",
      fontSize: "18px",
      padding: "14px",
      margin: "30px auto 0",
      border: "none",
      cursor: "pointer",
      borderRadius: "5px",
      fontFamily: "Montserrat, sans-serif",
    },
  };
});

export default localStyles;
