/** @format */

import React, { useContext, useEffect, useState } from "react";
import strings from "../../languages/strings";
import * as colors from "../../assets/css/Colors";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAddressValue,
  clearWorkingHours,
  editClosingHours,
  editOpeningHours,
  editWorkingDay,
  getAddressValue,
  resetAddressError,
  serviceActionError,
  setClosingHours,
  setErrorMessage,
  setOpeningHours,
  setShowAdditionalDetails,
  toggleWorkingDay,
} from "../../actions/AddressActions";
import AutocompleteAddress from "../AutocompleteAddress";
import { toast } from "react-toastify";
import {
  location_update,
  location_Added,
  GOOGLE_KEY,
  PHONE_FIELD_LENGTH,
} from "../../config/Constants";
import { onSaveAddress, onUpdateAddress, getPartnerProfile } from "./service";
import useStyles from "../../GlobalStyle";
import { Checkbox, TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import localStyles from "./localStyles";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { getCustomerList } from "../customersHistory/services";
import { DPFContext } from "../../context";
import Switch from "@mui/material/Switch";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { formatPhoneNumber } from "../../config/Helper";
import { VALIDATE_PHONE } from "../../config/validation";
import { Loader } from "../GeneralComponents";

export default function AddAddress({
  setAddAddress,
  customer,
  fromModal,
  fromEdit,
  addCustomerAddress,
  serviceLocation,
}) {
  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");

    // Specify the source URL of the script you want to load
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_KEY}&libraries=places`;

    // Specify the async attribute to load the script asynchronously
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const dispatch = useDispatch();
  const globalStyles = useStyles();
  const styles = localStyles();
  const [workingHours, setWorkingHours] = useState(false);
  const [state, setState] = useState({
    pick_up_radius: "",
    phone_number: "",
    service_radius: "",
    cleaning_radius: "",
    terms_and_conditions: "",
    validation: true,
    errorMessage: {
      phone_number: "",
    },
  });
  const currentTime = dayjs();

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const addressState = useSelector((state) => state.address);
  const { addressValue, error, showAdditionalDetails, errorMessage } =
    addressState;

  const context = useContext(DPFContext);

  const onChange = (key, value) => {
    dispatch(getAddressValue({ ...addressValue, [key]: value }));
    dispatch(setErrorMessage({ [key]: "" }));
  };

  const checkValidate = () => {
    let errorMessage = { ...state.errorMessage };
    errorMessage.phone_number = VALIDATE_PHONE(state.phone_number);
    setState({ ...state, errorMessage });

    if (errorMessage.phone_number) {
      state.validation = false;
      return false;
    }
    state.validation = true;
    return true;
  };

  const onChangeInput = async (key, value) => {
    if (
      key === "phone_number" &&
      value.toString().replace(/[- )(]/g, "").length === 10
    ) {
      setState({
        ...state,
        phone_number: formatPhoneNumber(value),
        errorMessage: {},
      });
    } else {
      setState({
        ...state,
        [key]: value,
        errorMessage: { ...state.errorMessage, [key]: "" },
      });
    }
  };

  const deleteAddressUnnecessaryFields = (addressValue) => {
    if (fromEdit) {
      delete addressValue.created_by_partner_id;
      delete addressValue.city_short_name;
      delete addressValue.country_long_name;
      delete addressValue.country_short_name;
      delete addressValue.latitude;
      delete addressValue.longitude;
    }

    delete addressValue.door_no;
    delete addressValue.service_id;
    delete addressValue.static_map;
    delete addressValue.status;
    delete addressValue.created_at;
    delete addressValue.updated_at;
    delete addressValue.phone_number;
    delete addressValue.delivery_boy_id;
    delete addressValue.partner;
    delete addressValue.pick_up_radius;
    delete addressValue.is_service_address;
    delete addressValue.login_type;
    delete addressValue.working_days;
    delete addressValue.opening_hours;
    delete addressValue.closing_hours;
    delete addressValue.id;

    addressValue.address_id = addressState.addressId;

    return addressValue;
  };

  const saveAddress = async (setHours) => {
    if (setHours) {
      checkValidate();
      if (state.validation) {
        setWorkingHours(true);
      }
      return;
    }
    if (!fromModal) {
      if (addressState.addressId) {
        const body = deleteAddressUnnecessaryFields(addressValue);
        checkValidate();
        if (state.validation) {
          const response = await onUpdateAddress(
            dispatch,
            body,
            fromEdit,
            state,
            customer
          );
          if (response) {
            setAddAddress(false);
            if (fromEdit) {
              await getCustomerList(dispatch, 0, 100);
              toast.success("Location updated successfully");
            } else {
              await getPartnerProfile(dispatch);
              toast.success(location_update);
            }
          } else {
            toast.success("Something went wrong, Please try again");
          }
          return;
        }
      }
    }
    let response;

    if (addCustomerAddress) {
      response = await onSaveAddress(
        dispatch,
        addressValue,
        addressValue.is_default_billing,
        customer,
        addCustomerAddress,
        context.customerDetails
      );
      if (!fromModal && response) {
        await getCustomerList(dispatch, 0, 100);
      }
    } else {
      response = await onSaveAddress(
        dispatch,
        addressValue,
        addressValue.is_default_billing,
        undefined,
        false,
        undefined,
        addressState,
        state
      );
    }

    if (response) {
      toast.success(location_Added);
      setAddAddress(false);
    }
  };

  useEffect(() => {
    if (addressValue.line_1 != "") {
      setState({
        ...state,
        pick_up_radius:
          addressValue.pick_up_radius || state.pick_up_radius || "",
        phone_number: addressValue.phone_number
          ? formatPhoneNumber(addressValue.phone_number)
          : state?.phone_number
          ? formatPhoneNumber(state.phone_number)
          : "",
        service_radius:
          addressValue.mobile_service_radius?.toString() ||
          state.service_radius?.toString() ||
          "",
        cleaning_radius:
          addressValue.mobile_cleaning_radius?.toString() ||
          state.cleaning_radius?.toString() ||
          "",
        terms_and_conditions:
          addressValue.terms_and_conditions || state.terms_and_conditions || "",
        errorMessage: {},
      });
    }
  }, [addressValue]);

  const onChangeAddress = async (details) => {
    let body = {
      line_1: "",
      pin_code: "",
      address: "",
      latitude: "",
      longitude: "",
      city_long_name: "",
      city_short_name: "",
      state_long_name: "",
      state_short_name: "",
      country_long_name: "",
      country_short_name: "",
    };
    await getGeocode({ address: details.description }).then((results) => {
      dispatch(getAddressValue({ address: results[0].formatted_address }));
      const { lat, lng } = getLatLng(results[0]);
      let door_no;
      body = {
        ...body,
        address: results[0].formatted_address,
        latitude: lat.toString(),
        longitude: lng.toString(),
      };
      results[0].address_components.map((value) => {
        if (
          value?.types?.indexOf("street_number") > -1 ||
          value?.types?.indexOf("premise") > -1
        ) {
          door_no = value?.long_name;
        } else if (value.types.indexOf("route") > -1) {
          body = { ...body, line_1: `${door_no} ${value?.short_name}` };
        } else if (
          value.types.indexOf("sublocality_level_1") > -1 ||
          value.types.indexOf("locality") > -1
        ) {
          body = {
            ...body,
            city_long_name: value.long_name,
            city_short_name: value.short_name,
          };
        } else if (value.types.indexOf("administrative_area_level_1") > -1) {
          body = {
            ...body,
            state_short_name: value.short_name,
            state_long_name: value.long_name,
          };
        } else if (value.types.indexOf("postal_code") > -1) {
          body = { ...body, pin_code: value.long_name };
        } else if (value.types.indexOf("country") > -1) {
          body = {
            ...body,
            country_long_name: value.long_name,
            country_short_name: value.short_name,
          };
        }
      });
    });

    const checkValues = Object.keys(body).map((key) => {
      if (key !== "door_no") if (body[key] === "") return false;
      return true;
    });
    if (checkValues.indexOf(false) > -1)
      return dispatch(serviceActionError("Please enter full address"));
    dispatch(getAddressValue({ ...body }));
    dispatch(setShowAdditionalDetails(true));
  };

  const handleBackClick = () => {
    dispatch(clearWorkingHours());
    setAddAddress(false);
    dispatch(clearAddressValue());
    dispatch(resetAddressError());
  };

  const handleToggleClick = (index, editLocation) => {
    if (editLocation) dispatch(editWorkingDay(index));
    else dispatch(toggleWorkingDay(index));
  };

  const handleOpeningHours = (newValue, index, editLocation) => {
    if (editLocation) {
      dispatch(
        editOpeningHours({
          index,
          opening_hours: newValue,
        })
      );
    } else {
      dispatch(
        setOpeningHours({
          index,
          opening_hours: newValue,
        })
      );
    }
  };

  const handleClosingHours = (newValue, index, editLocation) => {
    if (editLocation) {
      dispatch(
        editClosingHours({
          index,
          closing_hours: newValue,
        })
      );
    } else {
      dispatch(
        setClosingHours({
          index,
          closing_hours: newValue,
        })
      );
    }
  };

  const timeIntoTimePickerValue = (inputTime) => {
    if (typeof inputTime != "string") return inputTime;
    // Parse the input UTC time string into a day.js object in UTC mode

    const utcTime = dayjs.utc(inputTime, "HH:mm:ss");

    // Convert UTC time to local time
    const localTime = utcTime.local();

    return localTime;
  };

  return (
    <>
      {workingHours ? (
        <div style={{ width: "100%" }}>
          <div className={styles.detailsHead}>
            <ArrowBackIcon
              className={styles.icon}
              sx={{ color: colors.icon_color }}
              fontSize={"large"}
              onClick={handleBackClick}
            />
            <span className={styles.title}>Set Working Hours</span>
          </div>
          <div style={{ padding: "20px" }}>
            {serviceLocation
              ? addressState?.working_hours?.map((item, index) => {
                  return (
                    <>
                      <div
                        key={item.day}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px 0px",
                        }}>
                        <span className={styles.notesText}>{item.day}</span>
                        <Switch
                          className={styles.switchButton}
                          checked={item.is_opened}
                          onClick={() => handleToggleClick(index, false)}
                        />
                      </div>
                      {item.is_opened && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}>
                          <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                label='Opening Hours'
                                value={
                                  item.opening_hours === ""
                                    ? dayjs(currentTime)
                                    : item.opening_hours
                                }
                                onChange={(newValue) =>
                                  handleOpeningHours(newValue, index, false)
                                }
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                className={styles.timePick}
                              />
                            </LocalizationProvider>
                          </div>
                          <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                label='Closing Hours'
                                value={
                                  item.closing_hours === ""
                                    ? dayjs(currentTime)
                                    : item.closing_hours
                                }
                                onChange={(newValue) =>
                                  handleClosingHours(newValue, index, false)
                                }
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })
              : addressValue?.serviceHours
                  ?.sort((a, b) => a.day_sequence - b.day_sequence)
                  ?.map((item, index) => {
                    return (
                      <>
                        <div
                          key={item.day}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "10px 0px",
                          }}>
                          <span className={styles.notesText}>{item.day}</span>
                          <Switch
                            className={styles.switchButton}
                            checked={item.is_opened}
                            onClick={() => handleToggleClick(index, true)}
                          />
                        </div>
                        {item.is_opened && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <div>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  label='Opening Hours'
                                  value={
                                    item.opening_hours === ""
                                      ? dayjs(currentTime)
                                      : timeIntoTimePickerValue(
                                          item.opening_hours
                                        )
                                  }
                                  onChange={(newValue) =>
                                    handleOpeningHours(newValue, index, true)
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  className={styles.timePick}
                                />
                              </LocalizationProvider>
                            </div>

                            <div>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  label='Closing Hours'
                                  value={
                                    item.closing_hours === ""
                                      ? dayjs(currentTime)
                                      : timeIntoTimePickerValue(
                                          item.closing_hours
                                        )
                                  }
                                  onChange={(newValue) =>
                                    handleClosingHours(newValue, index, true)
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
            {addressState.isLoading && <Loader />}
            <button
              onClick={() => saveAddress(false)}
              className={styles.submitButton}>
              Submit
            </button>
          </div>
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <div className={styles.detailsHead}>
            <ArrowBackIcon
              className={styles.icon}
              sx={{ color: colors.icon_color }}
              fontSize={"large"}
              onClick={handleBackClick}
            />
            <span className={styles.title}>
              {addressState.addressId ? "Edit Location" : "Add Location"}
            </span>
          </div>
          <div
            className={styles.editForm}
            // In-line style because conditional styling required
            style={
              fromModal || serviceLocation
                ? { height: "650px" }
                : { minHeight: "min-content", maxHeight: "710px" }
            }>
            <div className={styles.editFormInputs}>
              <AutocompleteAddress
                onChangeAddress={onChangeAddress}
                addressValue={addressValue}
                addressState={addressState}
              />
              {error && <span className={globalStyles.errorTest}>{error}</span>}
              {addressValue?.city_long_name && (
                <>
                  <TextField
                    placeholder={strings.Address_2}
                    label={strings.Address_2}
                    fullWidth
                    variant='filled'
                    color='grey'
                    className={styles.input}
                    onChange={(e) => onChange("line_2", e.target.value)}
                    error={!!errorMessage?.line_2}
                    helperText={errorMessage?.line_2}
                    value={addressValue?.line_2}
                  />
                  <TextField
                    placeholder={strings.city}
                    label={strings.city}
                    fullWidth
                    variant='filled'
                    color='grey'
                    className={styles.input}
                    onChange={(e) => onChange("city_long_name", e.target.value)}
                    value={addressValue?.city_long_name}
                    error={!!errorMessage?.city_long_name}
                    helperText={errorMessage?.city_long_name}
                  />
                  <TextField
                    placeholder={strings.state}
                    label={strings.state}
                    fullWidth
                    variant='filled'
                    color='grey'
                    className={styles.input}
                    onChange={(e) =>
                      onChange("state_long_name", e.target.value)
                    }
                    value={addressValue?.state_short_name}
                    error={!!(errorMessage && errorMessage?.state_long_name)}
                    helperText={errorMessage.state_long_name}
                  />

                  <TextField
                    placeholder={strings.pin_code}
                    label={strings.pin_code}
                    fullWidth
                    variant='filled'
                    color='grey'
                    className={styles.input}
                    inputProps={{
                      maxLength: 5,
                      minLength: 5,
                    }}
                    onChange={(e) => onChange("pin_code", e.target.value)}
                    value={addressValue?.pin_code}
                    error={!!errorMessage?.pin_code}
                    helperText={errorMessage?.pin_code}
                  />
                </>
              )}
              {serviceLocation || addressState.addressId ? (
                <>
                  <TextField
                    placeholder='Phone Number'
                    label='Phone Number'
                    fullWidth
                    variant='filled'
                    color='grey'
                    className={styles.input}
                    inputProps={{
                      maxLength: PHONE_FIELD_LENGTH,
                    }}
                    onChange={(e) =>
                      onChangeInput("phone_number", e.target.value)
                    }
                    value={state?.phone_number}
                    error={state?.errorMessage?.phone_number}
                    helperText={state?.errorMessage?.phone_number}
                  />
                  <div className={styles.blockHead}>
                    <hr />
                    <span>Service Type Radius</span>
                    <hr />
                  </div>
                  <TextField
                    placeholder='Pick Up Radius'
                    label='Pick Up Radius'
                    fullWidth
                    variant='filled'
                    color='grey'
                    className={styles.input}
                    onChange={(e) =>
                      onChangeInput("pick_up_radius", e.target.value)
                    }
                    value={state?.pick_up_radius}
                  />
                  <TextField
                    placeholder='Mobile Service Radius'
                    label='Mobile Service Radius'
                    fullWidth
                    variant='filled'
                    color='grey'
                    className={styles.input}
                    onChange={(e) =>
                      onChangeInput("service_radius", e.target.value)
                    }
                    value={state?.service_radius}
                  />
                  <TextField
                    placeholder='Mobile Cleaning Radius'
                    label='Mobile Cleaning Radius'
                    fullWidth
                    variant='filled'
                    color='grey'
                    className={styles.input}
                    onChange={(e) =>
                      onChangeInput("cleaning_radius", e.target.value)
                    }
                    value={state?.cleaning_radius}
                  />
                </>
              ) : (
                <></>
              )}
              {showAdditionalDetails && !error && !fromModal && (
                <>
                  <div>
                    <div className={styles.blockHead}>
                      <hr />
                      <span>Optional</span>
                      <hr />
                    </div>
                    <TextField
                      placeholder={strings.address_name}
                      onChange={(e) => onChange("address_name", e.target.value)}
                      fullWidth
                      variant='filled'
                      color='grey'
                      value={addressValue?.address_name || ""}
                      className={styles.input}
                      error={!!errorMessage?.address_name}
                      helperText={errorMessage?.address_name}
                    />
                  </div>
                  <div>
                    <TextField
                      multiline={true}
                      rows={3}
                      placeholder={strings.address_note}
                      fullWidth
                      variant='filled'
                      color='grey'
                      className={styles.input}
                      onChange={(e) => onChange("address_note", e.target.value)}
                      value={addressValue?.address_note || ""}
                    />
                  </div>
                </>
              )}
              {addCustomerAddress && (
                <div className={styles.checkboxBlock}>
                  <Checkbox
                    checked={addressValue.is_default_billing}
                    className={styles.checkbox}
                    onChange={() =>
                      onChange(
                        "is_default_billing",
                        !addressValue?.is_default_billing
                      )
                    }
                  />
                  <span>Set as default billing address</span>
                </div>
              )}
            </div>
            {serviceLocation || addressState.addressId ? (
              <>
                <div className={styles.blockHead}>
                  <hr />
                  <span>Terms and Conditions</span>
                  <hr />
                </div>
                <TextField
                  multiline={true}
                  rows={5}
                  placeholder='Terms and Conditions'
                  fullWidth
                  variant='filled'
                  color='grey'
                  className={styles.input}
                  onChange={(e) =>
                    onChangeInput("terms_and_conditions", e.target.value)
                  }
                  value={state?.terms_and_conditions}
                />
              </>
            ) : (
              <></>
            )}
            <button
              onClick={() =>
                saveAddress(!!(serviceLocation || addressState.addressId))
              }
              className={styles.submitButton}
              style={
                !addressValue?.city_long_name
                  ? { background: colors.dark_gray, cursor: "default" }
                  : {}
              }
              disabled={!addressValue?.city_long_name}>
              {serviceLocation || addressState.addressId
                ? "Next"
                : strings.save}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
