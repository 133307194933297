/** @format */

import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPaymentMethod,
  getCustomerCards,
  getCustomerWalletAmount,
} from "./services";
import {
  PUBLISHABLE,
  boxShadow,
  paymentExtraDetails,
  payment_method,
  proceed,
  select_method,
  semiBold,
} from "../../config/Constants";

import { DPFContext } from "../../context";
import * as colors from "../../assets/css/Colors";

import { getAddressList } from "../account/service";
import { formatter } from "../../config/Helper";
import CardPayment from "./CardPayment";
import WalletPayment from "./WalletPayment";
import { InputAdornment, TextField } from "@mui/material";
import { Elements, StripeProvider } from "react-stripe-elements";

const PaymentMethod = ({ showModal, handleClose, setShowModal }) => {
  const styles = localStyles();
  const dispatch = useDispatch();
  const context = useContext(DPFContext);

  const [paymentAmount, setPaymentAmount] = useState(0);
  const [state, setState] = useState({
    errorMessage: {
      paymentAmount: "",
    },
  });

  const { paymentMethod, walletAmount, isWalletActive } = useSelector(
    (state) => state.checkout
  );
  const { orderDetail } = useSelector((state) => state.myOrders);

  useEffect(() => {
    getAllPaymentMethod(dispatch);
    getCustomerWalletAmount(dispatch, orderDetail);
  }, [orderDetail]);

  const onChangeInput = (value) => {
    const reg = /^[0-9]*(?:\.[0-9]*)?$/;
    if (!value.match(reg)) {
      setState({
        ...state,
        errorMessage: {
          ...state.errorMessage,
          paymentAmount: "Only numeric values allowed",
        },
      });
    } else {
      setPaymentAmount(value);
      setState({
        ...state,
        errorMessage: {
          ...state.errorMessage,
          paymentAmount: "",
        },
      });
    }
  };

  const onPressPaymentMethod = async (item) => {
    if (orderDetail.amount_paid + Number(paymentAmount) > orderDetail?.total) {
      setState({
        ...state,
        errorMessage: {
          ...state.errorMessage,
          paymentAmount: "Payment balance cannot exceed total amount",
        },
      });
      return;
    }
    if (paymentAmount <= 0) {
      setState({
        ...state,
        errorMessage: {
          ...state.errorMessage,
          paymentAmount: "Payment Amount is required",
        },
      });
      return;
    }
    let modalDetails = {};
    if (item.payment_method_key === "card") {
      if (Number(paymentAmount) < 0.5) {
        setState({
          ...state,
          errorMessage: {
            ...state.errorMessage,
            paymentAmount: "Minimum payment amount should be $0.5",
          },
        });
        return;
      }
      await getCustomerCards(dispatch, orderDetail);
      await getAddressList(dispatch, orderDetail);

      setState({
        ...state,
        errorMessage: {
          ...state.errorMessage,
          paymentAmount: "",
        },
      });
      modalDetails = {
        ...item,
        description: "",
        walletAmount: paymentAmount,
      };
      context.setPaymentType({ type: "card", details: modalDetails });
    }
    if (
      item.payment_method_key === "wallet" ||
      item.payment_method_key === "account"
    ) {
      modalDetails = {
        ...item,
        description: "Available Balance: ",
        walletAmount,
        total: paymentAmount,
      };
      setState({
        ...state,
        errorMessage: {
          ...state.errorMessage,
          paymentAmount: "",
        },
      });
      context.setPaymentType({ type: "wallet", details: modalDetails });
    }
  };

  const displayButton = (paymentMode) => {
    if (paymentMode === "Account") {
      if (isWalletActive && walletAmount && walletAmount >= orderDetail?.total)
        return "inline";
      return "none";
    }
    if (paymentMode === "Cash") {
      return "none";
    }

    return "inline";
  };

  return (
    <Modal open={showModal} onClose={handleClose}>
      <div className={styles.container}>
        {context?.paymentType?.type === "card" ? (
          <>
            <StripeProvider apiKey={PUBLISHABLE}>
              <Elements>
                <CardPayment
                  paymentAmount={paymentAmount}
                  handleClose={handleClose}
                  setShowModal={setShowModal}
                />
              </Elements>
            </StripeProvider>
          </>
        ) : context?.paymentType?.type === "wallet" ? (
          <WalletPayment
            paymentAmount={paymentAmount}
            handleClose={handleClose}
            setShowModal={setShowModal}
          />
        ) : (
          <>
            <div style={{ marginBottom: "30px" }}>
              <span className={styles.head}>
                Order: {orderDetail?.customer_order_id}
              </span>
            </div>
            <>
              <span className={styles.head}>{payment_method}</span>
              <p className={styles.text}>
                {select_method} <br /> {proceed}
              </p>
            </>

            <div className={styles.innerContainer2}>
              <div className={styles.leftContainer}>
                <p className={styles.searchItemBold}>Total Due</p>
                <p className={styles.searchItemBold}>Amount Paid</p>
                <p className={styles.searchItemBold}>Remaining Balance</p>
              </div>
              <div className={styles.rightContainer1}>
                <p className={styles.searchItem}>
                  {formatter.format(orderDetail?.total)}
                </p>
                <p className={styles.searchItem}>
                  {formatter.format(orderDetail?.amount_paid)}
                </p>

                <p className={styles.searchItem}>
                  {formatter.format(
                    orderDetail?.total - orderDetail?.amount_paid
                  )}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
              }}>
              <TextField
                variant='filled'
                placeholder='Payment Amount'
                onChange={(e) => onChangeInput(e.target.value)}
                color='grey'
                value={paymentAmount}
                label='Payment Amount'
                className={styles.input}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>$</InputAdornment>
                  ),
                  endAdornment: <InputAdornment position='end' />,
                }}
                error={!!state?.errorMessage?.paymentAmount}
                helperText={state?.errorMessage?.paymentAmount}
              />
            </div>
            <div className={styles.innerContainer}>
              {paymentMethod?.map((item, index) => (
                <div key={item?.payment_id}>
                  <button
                    onClick={() => onPressPaymentMethod(item)}
                    className={styles.payButton}
                    style={{
                      backgroundColor: `${paymentExtraDetails[index].color}`,
                      display: displayButton(item.payment_mode),
                    }}>
                    {item?.payment_mode}
                  </button>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

const localStyles = makeStyles((theme) => {
  return {
    container: {
      width: "500px",
      height: "auto",
      marginLeft: "35%",
      marginTop: "5%",
      background: colors.theme_fg,
      gridColumnStart: 1,
      gridColumnEnd: 3,
      padding: "20px",
      borderRadius: "5px",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        order: 4,
        marginRight: 0,
        padding: 0,
      },
      [theme.breakpoints.between("md", "lg")]: {
        marginRight: "20px",
        padding: "5px",
      },
    },
    innerContainer2: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    searchItemBold: {
      paddingLeft: 10,
      marginBottom: 5,
      fontWeight: "bold",
      textAlign: "right",
    },
    input: {
      backgroundColor: "none",
      border: "none",
      paddingLeft: "0px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      marginBottom: "20px",
      "& .MuiInputBase-root": {
        boxShadow: boxShadow,
        fontFamily: "Montserrat, sans-serif",
      },
    },
    searchItem: {
      paddingLeft: 10,
      marginBottom: 5,
      color: "grey",
    },
    notesContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "90%",
    },
    leftContainer: {
      width: "50%",
      textAlign: "left",
    },
    rightContainer1: {
      width: "50%",
      textAlign: "start",
    },
    innerContainer: {
      padding: "25px",
      width: "fit-content",
      height: "fit-content",
      display: "contents",
      justifyContent: "center",
      borderRadius: "10px",
    },
    innerContainer1: {
      textAlign: "center",
      padding: "15px",
      height: "fit-content",
      borderRadius: "10px",
      marginBottom: "30px",
    },
    head: {
      fontWeight: semiBold,
      fontSize: "24px",
      lineHeight: "35px",
      marginTop: "60px",
      textAlign: "center",
    },
    text: {
      margin: "10px 0 30px",
      padding: 0,
      fontSize: "22px",
      lineHeight: "26px",
      textAlign: "center",
    },
    image: {
      marginRight: "105px",
    },
    payButton: {
      border: "none",
      color: colors.text_white,
      fontSize: "20px",
      padding: "15px",
      width: "265px",
      borderRadius: "5px",
      cursor: "pointer",
      margin: "10px 0",
    },
    totalText: {
      fontSize: "18px",
    },
    totalAmount: {
      fontSize: "30px",
      color: colors.icon_color,
      fontWeight: semiBold,
    },
  };
});

export default PaymentMethod;
