/** @format */

import * as ActionTypes from "./ActionTypes";

export const serviceActionPending = () => ({
  type: ActionTypes.HOME_SERVICE_PENDING,
});

export const serviceActionError = (error) => ({
  type: ActionTypes.HOME_SERVICE_ERROR,
  error: error,
});

export const serviceActionSuccess = (data) => ({
  type: ActionTypes.HOME_SERVICE_SUCCESS,
  data: data,
});

export const serviceActiveOrdersPending = (offset) => ({
  type: ActionTypes.HOME_ACTIVE_ORDERS_PENDING,
  offset,
});

export const serviceActiveOrdersError = (error) => ({
  type: ActionTypes.HOME_ACTIVE_ORDERS_ERROR,
  error: error,
});

export const clearAllOrders = () => ({
  type: ActionTypes.CLEAR_ALL_ORDERS,
});

export const partnerCompletedOrders = (orderDetail) => ({
  type: ActionTypes.PARTNER_COMPLETE_ORDERS,
  orderDetail,
});

export const partnerNewOrders = (orderDetail) => ({
  type: ActionTypes.PARTNER_NEW_ORDERS,
  orderDetail,
});
export const partnerPendingOrders = (orderDetail) => ({
  type: ActionTypes.PARTNER_PENDING_ORDERS,
  orderDetail,
});

export const serviceActiveOrdersSuccess = (data) => ({
  type: ActionTypes.HOME_ACTIVE_ORDERS_SUCCESS,
  data: data,
});

export const getMainParentId = (mainParentId) => ({
  type: ActionTypes.MAIN_PARENT_ID,
  mainParentId,
});
export const getOrderStatusListError = (error) => ({
  type: ActionTypes.GET_ORDER_STATUS_LIST_ERROR,
  error,
});
export const getOrderStatusListSuccess = (orderStatusList) => ({
  type: ActionTypes.GET_ORDER_STATUS_LIST_SUCCESS,
  orderStatusList,
});
export const setTabValue = (data) => ({
  type: ActionTypes.SET_TAB_VALUE,
  data,
});
export const resetTabValue = () => ({
  type: ActionTypes.RESET_TAB_VALUE,
});
export const orderStatusChanged = (data) => ({
  type: ActionTypes.ORDER_STATUS_CHANGED,
  data,
});
export const newOrderCreated = (data) => ({
  type: ActionTypes.NEW_ORDER_CREATED,
  data,
});

export const addPartnerUserPending = () => ({
  type: ActionTypes.ADD_PARTNER_USER_PENDING,
});

export const addPartnerUserSuccess = (data) => ({
  type: ActionTypes.ADD_PARTNER_USER_SUCCESS,
  data,
});

export const addPartnerUserError = (error) => ({
  type: ActionTypes.ADD_PARTNER_USER_ERROR,
  error,
});

export const clearAddUserError = () => ({
  type: ActionTypes.CLEAR_ADD_USER_ERROR,
});

export const orderCountSuccess = (data) => ({
  type: ActionTypes.ORDER_COUNT_SUCCESS,
  data,
});

export const orderCountError = (error) => ({
  type: ActionTypes.ORDER_COUNT_ERROR,
  error,
});
