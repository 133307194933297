/** @format */

import * as ActionTypes from "./ActionTypes";

export const getPartnerPermissionsPending = () => ({
  type: ActionTypes.GET_PARTNER_PERMISSIONS_PENDING,
});

export const getPartnerPermissionsError = (error) => ({
  type: ActionTypes.GET_PARTNER_PERMISSIONS_ERROR,
  error: error,
});

export const getPartnerPermissionsSuccess = (data) => ({
  type: ActionTypes.GET_PARTNER_PERMISSIONS_SUCCESS,
  data,
});
