/** @format */

import React, { useState, useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "./config/history";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { getNewToken } from "./firebase";
import { SERVER_URL } from "./config/Constants";
import { checkIsAuthError, checkIsAuthSuccess } from "./actions/AuthAction";
import { ToastContainer } from "react-toastify";
import Header from "./components/header/Header";
import {
  getCompletedOrders,
  getOrderCounts,
  // getMyActiveOrders,
} from "./components/ordersHistory/services";
import { Loader } from "./components/GeneralComponents";
import { ThemeProvider } from "@mui/styles";
import { theme } from "./theme/theme";
import { io } from "socket.io-client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { PermifyProvider } from "@permify/react-role";
import Footer from "./components/footer/Footer";
import EditOrder from "./components/ordersHistory/editOrder";
import EmailVerification from "./views/EmailVerification";

export let socket = null;

function App() {
  const { isAuth } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const Login = React.lazy(() => import("./views/Login"));
  const Forgot = React.lazy(() => import("./views/Forgot"));
  const Dashboard = React.lazy(() => import("./views/Dashboard"));
  const Orders = React.lazy(() => import("./views/Orders"));
  const Customers = React.lazy(() => import("./views/Customers"));

  const NewOrder = React.lazy(() => import("./views/NewOrder"));
  const Checkout = React.lazy(() => import("./components/checkout/Checkout"));
  const OTP = React.lazy(() => import("./views/Otp"));
  const OrderAssignment = React.lazy(() => import("./views/OrderAssignment"));
  const SERVER = SERVER_URL;

  const userData = localStorage.getItem("user_data");
  const permify = JSON.parse(localStorage.getItem("__permifyUser"));

  useEffect(() => {
    if (isAuth) {
      getCompletedOrders(
        dispatch,
        permify.roles.includes("admin_owner") || permify.roles.includes("admin")
      );
      getOrderCounts(
        dispatch,
        permify.roles.includes("admin_owner") || permify.roles.includes("admin")
      );
      socket = io(SERVER, {
        auth: {
          token: JSON.parse(userData).auth_token,
        },
      });
    }
    getNewToken();
    home();
  }, [isAuth]);

  const home = async () => {
    const user_id = localStorage.getItem("user_id");
    if (user_id !== null && socket) {
      dispatch(checkIsAuthSuccess());
      setIsLoading(false);
    } else if (user_id !== null) {
      dispatch(checkIsAuthSuccess());
    } else {
      dispatch(checkIsAuthError());
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <PermifyProvider>
            {isAuth ? (
              <>
                <Header />
                <Routes>
                  <Route
                    path='*'
                    element={<Navigate replace to='/dashboard' />}
                  />
                  <Route
                    path='/dashboard'
                    element={
                      <Suspense fallback={<Loader />}>
                        <Dashboard />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/OrderAssignment'
                    element={
                      <Suspense fallback={<Loader />}>
                        <OrderAssignment />
                      </Suspense>
                    }
                  />

                  <Route
                    path='/edit-order/:id'
                    element={
                      <Suspense fallback={<Loader />}>
                        <EditOrder />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/orders'
                    element={
                      <Suspense fallback={<Loader />}>
                        <Orders />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/customers'
                    element={
                      <Suspense fallback={<Loader />}>
                        <Customers />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/new'
                    element={
                      <Suspense fallback={<Loader />}>
                        <NewOrder />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/checkout'
                    element={
                      <Suspense fallback={<Loader />}>
                        <Checkout />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/verify-email'
                    element={
                      <Suspense fallback={<Loader />}>
                        <EmailVerification />
                      </Suspense>
                    }
                  />
                </Routes>
                <Footer />
              </>
            ) : (
              <Routes>
                <Route path='*' element={<Navigate replace to='/login' />} />
                <Route
                  path='/login'
                  element={
                    <Suspense fallback={<Loader />}>
                      <GoogleReCaptchaProvider
                        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
                        <Login />
                      </GoogleReCaptchaProvider>
                    </Suspense>
                  }
                />
                <Route
                  path='/otp'
                  element={
                    <Suspense fallback={<Loader />}>
                      <OTP />
                    </Suspense>
                  }
                />
                <Route
                  path='/forgot'
                  element={
                    <Suspense fallback={<Loader />}>
                      <Forgot />
                    </Suspense>
                  }
                />
                <Route
                  path='/verify-email'
                  element={
                    <Suspense fallback={<Loader />}>
                      <EmailVerification />
                    </Suspense>
                  }
                />
              </Routes>
            )}
          </PermifyProvider>
        </ThemeProvider>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
