/** @format */

import * as ActionTypes from "./ActionTypes";

export const getCustomerPending = () => ({
  type: ActionTypes.GET_CUSTOMERS_PENDING,
});

export const getCustomerError = (error) => ({
  type: ActionTypes.GET_CUSTOMERS_ERROR,
  error: error,
});

export const sendEmailSuccess = (data) => ({
  type: ActionTypes.SEND_EMAIL_SUCCESS,
  data: data,
});

export const sendEmailPending = () => ({
  type: ActionTypes.SEND_EMAIL_PENDING,
});

export const sendEmailError = (error) => ({
  type: ActionTypes.SEND_EMAIL_ERROR,
  error: error,
});


export const getCustomerSuccess = (data) => ({
  type: ActionTypes.GET_CUSTOMERS_SUCCESS,
  data,
});

export const getCustomerOrderSuccess = (data) => ({
  type: ActionTypes.GET_CUSTOMER_ORDER_SUCCESS,
  data,
});

export const getCustomerOrderError = (error) => ({
  type: ActionTypes.GET_CUSTOMER_ORDER_ERROR,
  error,
});
