/** @format */

import * as Actions from "../actions/ActionTypes";
const CustomerListReducer = (
  state = {
    isLoading: false,
    error: undefined,
    customers: null,
    customerCount: 0,
    customerOrder: undefined,
  },
  action
) => {
  switch (action.type) {
    case Actions.SEND_EMAIL_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        error: undefined,
      });

    case Actions.GET_CUSTOMERS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        customers: null,
      });
    case Actions.GET_CUSTOMERS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        customers: null,
        customerCount: 0,
      });
    case Actions.SEND_EMAIL_SUCCESS:
    case Actions.GET_CUSTOMERS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        customers: action.data.customerList,
        customersCount: action.data.totalCustomers,
      });
    case Actions.GET_CUSTOMER_ORDER_SUCCESS:
      return Object.assign({}, state, {
        customerOrder: action.data,
      });
    case Actions.SEND_EMAIL_ERROR:
    case Actions.GET_CUSTOMER_ORDER_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        customerOrder: undefined,
      });
    default:
      return state;
  }
};

export default CustomerListReducer;
