/** @format */

import * as ActionTypes from "./ActionTypes";

export const editServiceActionPending = () => ({
  type: ActionTypes.EDIT_PROFILE_PENDING,
});

export const editServiceActionError = (error) => ({
  type: ActionTypes.EDIT_PROFILE_ERROR,
  error: error,
});

export const editServiceActionSuccess = (data) => ({
  type: ActionTypes.EDIT_PROFILE_SUCCESS,
  data: data,
});

export const updateServiceActionPending = () => ({
  type: ActionTypes.UPDATE_PROFILE_PENDING,
});

export const updateServiceActionError = (error) => ({
  type: ActionTypes.UPDATE_PROFILE_ERROR,
  error: error,
});

export const updateServiceActionSuccess = () => ({
  type: ActionTypes.UPDATE_PROFILE_SUCCESS,
});

export const onChangeProfileInput = (profileInput) => ({
  type: ActionTypes.ON_CHNAGE_PROFILE_INPUT,
  profileInput,
});
export const profileErrorMessage = (errorMessage) => ({
  type: ActionTypes.PROFILE_ERROR_MESSAGE,
  errorMessage,
});

export const updateProfilePicture = (profileImage) => ({
  type: ActionTypes.UPDATE_PROFILE_PICTURE,
  profileImage,
});
export const updateProfilePictureSuccess = () => ({
  type: ActionTypes.UPDATE_PROFILE_PICTURE_SUCCESS,
});
export const updateProfilePictureError = (error) => ({
  type: ActionTypes.UPDATE_PROFILE_PICTURE_ERROR,
  error,
});
export const toggleEditProfile = () => ({
  type: ActionTypes.TOGGLE_OPEN_EDIT_PROFILE,
});

export const resetProfileData = () => ({
  type: ActionTypes.RESET_PROFILE_DATA,
});

export const setEditOrder = (data) => ({
  type: ActionTypes.SET_EDIT_ORDER,
  data,
});

export const resetEditOrder = () => ({
  type: ActionTypes.RESET_EDIT_ORDER,
});

export const getPartnerDataPending = () => ({
  type: ActionTypes.PARTNER_DATA_PENDING,
});

export const getPartnerDataSuccess = (data) => ({
  type: ActionTypes.PARTNER_DATA_SUCCESS,
  data,
});

export const getPartnerDataError = (error) => ({
  type: ActionTypes.PARTNER_DATA_ERROR,
  error,
});

export const partnerInsertOrderPending = () => ({
  type: ActionTypes.PARTNER_INSERT_ORDER_PENDING,
});

export const partnerInsertOrderSuccess = (data) => ({
  type: ActionTypes.PARTNER_INSERT_ORDER_SUCCESS,
  data,
});

export const partnerInsertOrderError = (error) => ({
  type: ActionTypes.PARTNER_INSERT_ORDER_ERROR,
  error,
});
