/** @format */

import {
  getCardListError,
  getCardListPending,
  getCardListSuccess,
} from "../../actions/CardListActions";
import {
  getPaymentMethodError,
  getPaymentMethodSuccess,
} from "../../actions/CheckoutAction";
import {
  changeOrderStatusError,
  changeOrderStatusPending,
  changeOrderStatusSuccess,
} from "../../actions/MyOrdersActions";
import {
  cardPaymentError,
  getWalletAmount,
  getWalletAmountError,
  getWalletAmountSuccess,
  getWalletError,
} from "../../actions/WalletActions";
import {
  GET_CARD_URL,
  GET_PAYMENT_METHODS_URL,
  GET_WALLET_AMOUNT_URL,
  STRIPE_PAYMENT_URL,
  UPDATE_ORDER_STATUS,
  WALLET_PAYMENT_URL,
} from "../../config/Constants";
import { fetchGet, fetchPost } from "../../config/request";

export const getAllPaymentMethod = async (dispatch) => {
  const paymentMethods = await fetchGet(`${GET_PAYMENT_METHODS_URL}`);
  if (paymentMethods.error) {
    dispatch(getPaymentMethodError(paymentMethods.error));
  }
  dispatch(getPaymentMethodSuccess(paymentMethods));
};

export const getCustomerCards = async (dispatch, orderDetail) => {
  dispatch(getCardListPending());
  const data = await fetchGet(
    `${GET_CARD_URL}?customer_id=${orderDetail.customer.id}`
  );
  if (data.error) dispatch(getCardListError(data.error));
  else dispatch(getCardListSuccess(data));
};

export const getCustomerWalletAmount = async (dispatch, orderDetail) => {
  dispatch(getWalletAmount());
  const walletAmount = await fetchGet(
    `${GET_WALLET_AMOUNT_URL}?customer_id=${orderDetail?.customer?.id}`
  );
  if (walletAmount.error) dispatch(getWalletAmountError(walletAmount.error));
  else dispatch(getWalletAmountSuccess(walletAmount));
};

export const walletPayment = async (dispatch, order_id, amount) => {
  const body = { order_id, amount };
  const walletPayment = await fetchPost(`${WALLET_PAYMENT_URL}`, body);
  if (walletPayment.error) {
    dispatch(getWalletError(walletPayment.error));
    return false;
  } else {
    getCustomerWalletAmount(dispatch);
    return true;
  }
};

export const stripePayment = async (
  dispatch,
  cardDetails,
  orderDetail,
  isTemp,
  paymentAmount,
  tempCardDetails
) => {
  const body = {
    stripeTokenId: cardDetails?.id || tempCardDetails?.token?.id,
    amount: Number(paymentAmount) * 100,
    isTemp: cardDetails ? false : isTemp,
    customerStripToken: orderDetail?.customer?.stripe_token,
    order_id: orderDetail?.order_id,
  };
  const CardPayment = await fetchPost(`${STRIPE_PAYMENT_URL}`, body);
  if (CardPayment.error) {
    dispatch(cardPaymentError(CardPayment.error));
    return false;
  } else {
    return true;
  }
};

export const updateOrderStatus = async (dispatch, order) => {
  dispatch(changeOrderStatusPending());
  const body = {
    order_id: order?.order_id,
    send_notificatino: false,
    customer_status_images: [],
    internal_notes: undefined,
    customer_notes: undefined,
    internal_status_images: [],
    status_id: 43,
    partner_id: undefined,
    order_updated_by: "customer",
  };
  const updateStatus = await fetchPost(`${UPDATE_ORDER_STATUS}`, body);
  if (updateStatus) {
    dispatch(changeOrderStatusError(updateStatus.error));
    return false;
  }
  dispatch(changeOrderStatusSuccess());
  return true;
};
