/** @format */

import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  OAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import "firebase/compat/auth";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const messaging = getMessaging(app);
const vapidKey = process.env.REACT_APP_VAPID_KEY;

export const googleProvider = new GoogleAuthProvider();
export const fbProvider = new FacebookAuthProvider();
export const appleProvider = new OAuthProvider("apple.com");
googleProvider.setCustomParameters({
  prompt: "select_account",
});

export const getNewToken = async () => {
  let fcm_token = localStorage.getItem("fcm_token");
  if (!fcm_token) {
    await getToken(messaging, { vapidKey: vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          localStorage.setItem("fcm_token", currentToken);
          return currentToken;
        } else {
          return fcm_token;
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  }
};
