/** @format */

import * as Actions from "../actions/ActionTypes";
const PermissionReducer = (
  state = {
    isLoading: false,
    error: undefined,
    partnerPermissionList: [],
    partnerPermissionCount: 0,
  },
  action
) => {
  switch (action.type) {
    case Actions.GET_PARTNER_PERMISSIONS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        error: undefined,
        partnerPermissionList: [],
        partnerPermissionCount: 0,
      });
    case Actions.GET_PARTNER_PERMISSIONS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        error: undefined,
        partnerPermissionList: action.data.permissions,
        partnerPermissionCount: action.data.count,
      });
    }
    case Actions.GET_PARTNER_PERMISSIONS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        partnerPermissionList: [],
        partnerPermissionCount: 0,
      });
    default:
      return state;
  }
};

export default PermissionReducer;
