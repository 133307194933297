/** @format */

import * as ActionTypes from "./ActionTypes";

export const addUserPending = () => ({
  type: ActionTypes.ADD_USER_PENDING,
});

export const addUserError = (error) => ({
  type: ActionTypes.ADD_USER_ERROR,
  error: error,
});

export const addUserSuccess = (data) => ({
  type: ActionTypes.ADD_USER_SUCCESS,
  data,
});

export const linkCustomerError = (error) => ({
  type: ActionTypes.LINK_CUSTOMER_ERROR,
  error: error,
});

export const setSelectedUser = (data) => ({
  type: ActionTypes.SET_SELECTED_USER,
  data,
});

export const setFromCustomer = (data) => ({
  type: ActionTypes.SET_FROM_CUSTOMER,
  data,
});

export const setIsSearchedFalse = () => ({
  type: ActionTypes.SET_IS_SEARCHED_FALSE,
});

export const setSelectedProduct = (data) => ({
  type: ActionTypes.SET_SELECTED_PRODUCT,
  data,
});

export const deleteSelectedProduct = (data) => ({
  type: ActionTypes.DELETE_SELECTED_PRODUCT,
  data,
});

export const resetUser = () => ({
  type: ActionTypes.RESET_USER,
});

export const customerValidationError = (error) => ({
  type: ActionTypes.VALIDATE_CUSTOMER_ERROR,
  error: error,
});

export const customerValidationSuccess = (data) => ({
  type: ActionTypes.VALIDATE_CUSTOMER_SUCCESS,
  data,
});

export const customerValidationPending = () => ({
  type: ActionTypes.VALIDATE_CUSTOMER_PENDING,
});

export const resetValidation = () => ({
  type: ActionTypes.RESET_VALIDATION,
});
