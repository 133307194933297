/** @format */

import * as Actions from "../actions/ActionTypes";
const CardReducer = (
  state = {
    isLoading: false,
    error: undefined,
    cardData: [],
    errorMessage: {},
    cardAdded: undefined,
    cardEdited: undefined,
    selectedCard: undefined,
    selectedAddress: undefined,
    selectedPaymentCard: undefined,
    clearCard: undefined,
    selectedRowId: undefined,
    selectedTab: undefined,
  },
  action
) => {
  switch (action.type) {
    case Actions.CARD_LIST_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        error: undefined,
        cardData: undefined,
        errorMessage: {},
      });
    case Actions.CARD_LIST_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        error: undefined,
        cardData: action.data,
        errorMessage: {},
      });
    }
    case Actions.CARD_LIST_ERROR:
      return Object.assign({}, state, {
        isLoading: true,
        error: action.error,
        cardData: undefined,
        errorMessage: {},
      });
    case Actions.ADD_CARD_PENDING:
    case Actions.ADD_CARD_WITH_TOKEN_PENDING:
      return Object.assign({}, state, {
        isLoading: false,
        error: undefined,
        errorMessage: {},
        cardAdded: undefined,
      });
    case Actions.ADD_CARD_SUCCESS:
    case Actions.ADD_CARD_WITH_TOKEN_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        error: undefined,
        cardAdded: action.data,
        errorMessage: {},
      });
    }
    case Actions.ADD_CARD_ERROR:
    case Actions.ADD_CARD_WITH_TOKEN_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        cardData: [],
        errorMessage: {},
        cardAdded: {},
      });
    case Actions.EDIT_CARD_PENDING:
      return Object.assign({}, state, {
        isLoading: false,
        error: undefined,
        errorMessage: {},
        cardEdited: undefined,
      }); 
    case Actions.EDIT_CARD_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        error: undefined,
        cardEdited: action.data,
        errorMessage: {},
      });
    }
    case Actions.EDIT_CARD_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        cardData: [],
        errorMessage: {},
        cardEdited: {},
      });
    case Actions.SET_SELECTED_CARD:
      return Object.assign({}, state, {
        selectedCard: action.data,
      });
    case Actions.SET_SELECTED_ROW:
      return Object.assign({}, state, {
        selectedRowId: action.data,
      });
    case Actions.DISCARD_SELECTED_ROW:
      return Object.assign({}, state, {
        selectedRowId: undefined,
      });
    case Actions.SET_SELECTED_TAB:
      return Object.assign({}, state, {
        selectedTab: action.data,
      });
    case Actions.RESET_SELECTED_TAB:
      return Object.assign({}, state, {
        selectedTab: undefined,
      });
    case Actions.SET_SELECTED_ADDRESS:
      return Object.assign({}, state, {
        selectedAddress: action.data,
      });
    case Actions.SET_SELECTED_PAYMENT_CARD:
      return Object.assign({}, state, {
        selectedPaymentCard: action.data,
      });
    case Actions.DISCARD_SELECTED_PAYMENT_CARD:
      return Object.assign({}, state, {
        selectedPaymentCard: undefined,
      });
    case Actions.DISCARD_SELECTED_CARD:
      return Object.assign({}, state, {
        selectedCard: undefined,
      });
    case Actions.DISCARD_SELECTED_ADDRESS:
      return Object.assign({}, state, {
        selectedAddress: undefined,
      });
    case Actions.CLEAR_CARD_FORM: {
      return Object.assign({}, state, {
        clearCard: true,
      });
    }
    default:
      return state;
  }
};

export default CardReducer;
