/** @format */

import { makeStyles } from "@mui/styles";
import * as colors from "../../assets/css/Colors";
import { bold, boxShadow, semiBold } from "../../config/Constants";

const localStyles = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
    },
    detailsHead: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 20px",
      background: colors.theme_fg,
      alignItems: "center",
      zIndex: 2,
    },

    addUserContainer: {
      padding: "20px",

      paddingTop: "5px",
      maxHeight: "39.5em",
      minHeight: "39.5em",
      overflowX: "hidden",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: colors.theme_bg,
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: colors.icon_color,
        borderRadius: "5px",
      },
    },
    userContainer: {
      padding: "20px",
      paddingLeft: "50px",
      paddingRight: "50px",
      paddingTop: "0px",
      maxHeight: "39.5em",
      minHeight: "39.5em",
      overflowX: "hidden",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: colors.theme_bg,
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: colors.icon_color,
        borderRadius: "5px",
      },
    },
    addressesContainer: {
      padding: "20px",
      paddingTop: "0px",
      maxHeight: "39.5em",
      minHeight: "39.5em",
      overflowX: "hidden",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: colors.theme_bg,
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: colors.icon_color,
        borderRadius: "5px",
      },
    },
    tabHeading: {
      fontWeight: semiBold,
      fontSize: "1.3vw",
      lineHeight: "35px",
      color: "#C90000",
    },
    orderNoCard: {
      padding: "5px",
      backgroundColor: "#C90000",
      fontWeight: "bold",
      color: "white",
      borderRadius: "5px",
      cursor: "pointer",
    },

    select: {
      height: "50px",
      display: "flex",
      alignItems: "center",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "5px",
      },
    },
    timePick: {
      "& .MuiClock-pin": {
        backgroundColor: "#C90000", // Change the clock pointer (thumb) color here
      },
      "& .MuiClock-root": {
        backgroundColor: "#C90000", // Change the clock pointer (thumb) color here
      },
      "& .MuiClock-thumb": {
        backgroundColor: "#C90000", // Change the clock pointer (thumb) color here
      },
    },
    switchButton: {
      color: "#C90000",
      "& .Mui-checked .MuiSwitch-track": {
        backgroundColor: "#C90000", // Change the checked track color here
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: "#C90000", // Change the checked track color here
      },
    },
    notesText: {
      fontSize: "18px",
      lineHeight: "27px",
      margin: 0,
    },
    cardText: {
      fontSize: "0.8vw",
      fontWeight: "600",
    },
    tabs: {
      "& .MuiTab-root": {
        fontWeight: semiBold,
        fontSize: "1.3vw",
        lineHeight: "35px",
        color: colors.text_white,
        textTransform: "none",
      },
      "& .MuiTab-root.Mui-selected": {
        color: colors.text_white,
      },
      "& .MuiTabs-indicator": {
        background: colors.text_white,
      },
      background: colors.icon_color,
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "5px 5px 0 0",
      position: "sticky",
      top: 0,
      zIndex: 2,
    },
    tabsReset: {
      width: "100%",
      padding: "12px 16px",
      textAlign: "center",
      display: "flex",
      fontWeight: semiBold,
      fontSize: "24px",
      lineHeight: "35px",
      color: colors.text_white,
      textTransform: "none",
      background: colors.icon_color,
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "5px 5px 0 0",
      position: "sticky",
      top: 0,
      zIndex: 2,
    },
    orderCard: {
      width: "100%",
      boxShadow: boxShadow,
      borderRadius: "5px",
      marginTop: "20px",
      padding: "15px",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
      [theme.breakpoints.down("1541")]: {
        width: "96%",
      },
    },
    status_tab_image_view: {
      borderStyle: "solid",
      // borderRadius: "50%",
      borderColor: colors.theme_bg_red,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "20px",
    },
    orderNumber: {
      fontSize: "0.8vw",
      fontWeight: semiBold,
      display: "block",
    },
    date: {
      fontSize: "0.7vw",
      opacity: "0.5",
      display: "block",
      marginTop: "5px",
    },
    status: {
      fontSize: "0.7vw",
      fontWeight: semiBold,
      display: "block",
      marginTop: "5px",
    },
    descriptionBlock: {
      display: "flex",
      alignItems: "center",
    },
    amountBlock: {
      marginTop: "0px",
    },
    total: {
      fontSize: "0.7vw",
      display: "block",
      textAlign: "end",
    },
    title1: {
      fontWeight: semiBold,
      fontSize: "24px",
      lineHeight: "35px",
      textAlign: "center",
      marginLeft: "35px",
      [theme.breakpoints.between("md", "lg")]: {
        marginRight: 0,
      },
    },
    title: {
      fontWeight: semiBold,
      fontSize: "24px",
      lineHeight: "35px",
      width: "100%",
      textAlign: "center",
      marginRight: "35px",
      [theme.breakpoints.between("md", "lg")]: {
        marginRight: 0,
      },
    },
    icon: {
      cursor: "pointer",
    },
    profileBody: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px 70px",
      [theme.breakpoints.down("md")]: {
        padding: "20px 40px",
      },
      [theme.breakpoints.between("md", "lg")]: {
        padding: "20px 30px",
      },
    },
    profilePhoto: {
      borderRadius: "50%",
      border: "4px solid #c90000",
      width: "150px",
      height: "150px",
      boxSizing: "border-box",
      objectFit: "contain",
    },
    inputsBlock: {
      marginTop: "57px",
      width: "100%",
      [theme.breakpoints.between("md", "lg")]: {
        marginTop: "20px",
      },
    },
    input: {
      backgroundColor: "none",
      border: "none",
      paddingLeft: "0px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      marginBottom: "20px",
      "& .MuiInputBase-root": {
        boxShadow: boxShadow,
        fontFamily: "Montserrat, sans-serif",
      },
    },
    addressInput: {
      marginBottom: "0px",
      marginTop: "0px",
      backgroundColor: "none",
      border: "none",
      paddingLeft: "0px",
    },
    orderNumberCard: {
      fontSize: "1.2vw",
      fontWeight: bold,
      color: "#C90000",
      display: "block",
      // width: "70%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      cursor: "pointer",
    },
    flexStyleCard: {
      display: "flex",
      justifyContent: "space-between",
    },
    deleteIconCard: {
      color: "#c90000",
      marginLeft: "10px",
      marginBottom: "5px",
      cursor: "pointer",
    },
    unassignCard: {
      color: "#c90000",
      fontWeight: "bold",
      cursor: "pointer",
    },
    assigneeNameCard: {
      fontSize: "0.8vw",
      fontWeight: "600",
      color: "#C90000",
      cursor: "pointer",
    },
    statusCard: {
      fontSize: "0.8vw",
      display: "block",
      marginTop: "5px",
    },
    submitButton: {
      width: "100%",
      background: colors.icon_color,
      color: colors.text_white,
      display: "flex",
      justifyContent: "center",
      fontSize: "18px",
      padding: "14px",
      margin: "30px 0px 30px 0px",
      border: "none",
      cursor: "pointer",
      borderRadius: "5px",
      fontFamily: "Montserrat, sans-serif",
    },
    submitButtonLocation: {
      width: "260px",
      background: colors.icon_color,
      color: colors.text_white,
      display: "flex",
      justifyContent: "center",
      fontSize: "18px",
      padding: "14px",
      margin: "30px auto 0",
      border: "none",
      cursor: "pointer",
      borderRadius: "5px",
      fontFamily: "Montserrat, sans-serif",
    },
    cancelButton: {
      width: "100%",
      padding: "14px",
      marginTop: "20px",
      cursor: "pointer",
      borderRadius: "5px",
      margin: "30px auto 0",
      border: "none",
      background: colors.text_gray,
      color: colors.text_white,
      fontSize: "18px",
      fontFamily: "Montserrat, sans-serif",
      [theme.breakpoints.between("md", "lg")]: {
        width: "100%",
      },
    },
    editPhoto: {
      position: "relative",
      cursor: "pointer",
      borderRadius: "50%",
    },
    photoIcon: {
      position: "absolute",
      top: "57px",
      left: "57px",
    },
    passwordInputs: {
      display: "flex",
      flexDirection: "column",
      padding: "0 100px",
      marginTop: "57px",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "0 40px 30px",
        marginTop: "20px",
      },
      [theme.breakpoints.between("md", "lg")]: {
        padding: "20px 30px",
        marginTop: "20px",
      },
    },
    cardsBlock: {
      marginTop: "30px",
      padding: "0 40px",
    },
    addressCard: {
      borderRadius: "5px",
      boxShadow: boxShadow,
      padding: "15px 45px",
      marginBottom: "30px",
      [theme.breakpoints.down("md")]: {
        padding: "15px",
      },
    },
    map: {
      width: "100%",
      height: "130px",
      position: "relative",
    },
    default: {
      position: "absolute",
      bottom: 0,
      right: 0,
      color: colors.icon_color,
      fontSize: "14px",
      fontWeight: semiBold,
      background: colors.theme_fg,
    },
    address: {
      fontSize: "14px",
      marginTop: "12px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    buttons: {
      marginTop: "10px",
      paddingLeft: "10px",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
    buttonsContainer: {
      marginTop: "10px",
      marginLeft: "10px",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
    modalContact: {
      backgroundColor: "white",
      width: "330px",
      height: "175px",
      padding: "20px",
      borderRadius: "5px",
      textAlign: "center",
      position: "absolute",
      left: "50%",
      top: "30%",
      transform: "translate(-50%, -50%)",
      ["@media  (max-width: 400px)"]: {
        width: "270px",
      },
    },
    addCustomer: {
      backgroundColor: "#C90000",
      color: "white",
      border: "none",
      padding: "6px",
      borderRadius: "4px",
    },
    formcontroller: {
      "& .MuiPaper-root": {
        margin: "100px",
      },
    },
    save: {
      backgroundColor: "#C90000",
      color: "white",
      border: "none",
      padding: "6px",
      borderRadius: "4px",
    },
    cancel: {
      backgroundColor: "#BDBDBD",
      color: "white",
      border: "none",
      padding: "6px",
      borderRadius: "4px",
    },
    confirmHead: {
      fontWeight: semiBold,
      fontSize: "20px",
      marginTop: "20px",
    },
    callToday: {
      fontWeight: semiBold,
      fontSize: "16px",
      marginTop: "4px",
    },
    telLink: {
      color: colors.icon_color,
      marginTop: "4px",
      fontWeight: semiBold,
      fontSize: "19px",
      cursor: "pointer",
      textDecoration: "none",
    },
    confirmText: {
      fontWeight: semiBold,
      fontSize: "14px",
      marginTop: "6px",
      textAlign: "center",
    },
    addressEditBtn: {
      background: colors.dark_color,
      fontSize: "14px",
      fontFamily: "Montserrat, sans-serif",
      border: "none",
      cursor: "pointer",
      padding: "15px",
      boxShadow: boxShadow,
      marginRight: "20px",
      borderRadius: "5px",
      width: "130px",
      [theme.breakpoints.down("md")]: {
        marginRight: 0,
        marginBottom: "20px",
        width: "100%",
      },
    },
    addressEditButton: {
      background: colors.dark_color,
      fontSize: "14px",
      fontFamily: "Montserrat, sans-serif",
      border: "none",
      cursor: "pointer",
      padding: "15px",
      boxShadow: boxShadow,
      marginRight: "20px",
      borderRadius: "5px",
      width: "120px",
      [theme.breakpoints.down("md")]: {
        marginRight: 0,
        marginBottom: "20px",
        width: "100%",
      },
    },
    addressDeleteButton: {
      background: colors.icon_color,
      fontSize: "14px",
      color: colors.text_white,
      fontFamily: "Montserrat, sans-serif",
      border: "none",
      cursor: "pointer",
      padding: "15px",
      boxShadow: boxShadow,
      borderRadius: "5px",
      width: "120px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    addressDeleteBtn: {
      background: colors.icon_color,
      fontSize: "14px",
      color: colors.text_white,
      fontFamily: "Montserrat, sans-serif",
      border: "none",
      cursor: "pointer",
      padding: "15px",
      boxShadow: boxShadow,
      borderRadius: "5px",
      width: "130px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    addressList: {
      display: "flex",
      flexDirection: "column",
    },
    addressItem: {
      fontSize: "18px",
      borderBottom: "1px solid grey",
      marginTop: "5px",
      cursor: "pointer",
      height: "50px",
    },
    editForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "0px 30px",
      overflowX: "auto",
      // height: "760px",
      overflowY: window.location.href.includes("/new") ? "auto" : "none",
      boxSizing: "border-box",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: colors.theme_bg,
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: colors.icon_color,
        borderRadius: "5px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "26px 20px 60px",
      },
    },
    cardIcon: {
      color: colors.icon_color,
    },
    addressListingContainer: {
      borderRadius: "5px",
      padding: "10px 0px",
      [theme.breakpoints.down("md")]: {
        padding: "15px",
      },
      textAlign: "Center",
      display: "flex",
      alignItems: "center",
      color: "black",
      cursor: "pointer",
    },
    addressCardContainer: {
      textAlign: "left",
      paddingLeft: "15px",
    },
    editFormInputs: {
      // height: "350px",
      // padding: "30px 50px",
      // boxShadow: boxShadow,
      // borderRadius: "5px",
      // [theme.breakpoints.down("md")]: {
      //   padding: "30px",
      // },
    },
    blockHead: {
      fontSize: "18px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      marginBottom: "10px",
      "& hr": {
        width: "33%",
        height: "3px",
        background: colors.black_text_color,
        margin: "0",
        border: "none",
      },
      "& span": {
        whiteSpace: "nowrap",
        padding: "0 30px",
      },
    },
    addressTab: {
      paddingBottom: "30px",
    },
    addressName: {
      fontWeight: bold,
      fontSize: "24px",
      lineHeight: "33px",
      display: "flex",
      justifyContent: "center",
      marginBottom: "10px",
    },
    checkbox: {
      padding: 0,
      marginRight: "10px",
      "& .MuiSvgIcon-root": {
        fontSize: "32px",
        color: colors.icon_color,
      },
    },
    checkboxBlock: {
      display: "flex",
      alignItems: "center",
      fontSize: "18px",
    },
  };
});

export default localStyles;
