/** @format */

/* eslint-disable quotes */

import React from "react";

import { makeStyles } from "@mui/styles";
import { normal } from "../../config/Constants";

import CopyrightIcon from "@mui/icons-material/Copyright";
import { master_major, master_minor, master_patch } from "../../version";

const Footer = () => {
  const styles = localStyles();

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className={styles.container}>
        <div
          style={{ display: "flex", alignItems: "center", paddingRight: 20 }}>
          <CopyrightIcon style={{ color: "white", fontSize: "20px" }} />
          <span className={styles.containerSpan}>
            2018 - {currentYear} | All Rights Reserved | $99DPF is a FleetServ™
            Product v{master_major}.{master_minor}.{master_patch}
          </span>
        </div>
      </div>
    </>
  );
};

const localStyles = makeStyles((theme) => {
  return {
    container: {
      height: 30,
      width: "100%",
      background: "black",
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      fontWeight: normal,
      boxShadow: "0px 3px 6px #00000029",
      position: "fixed",
      bottom: 0,
      zIndex: 5,
      paddingTop: 5,
      paddingBottom: 5,
      boxSizing: "unset",
      [theme.breakpoints.down("md")]: {
        paddingRight: "20px",
      },
    },
    containerSpan: {
      color: "white",
      marginLeft: 5,
      fontWeight: 400,
      fontSize: "14px",
      [theme.breakpoints.down("1370")]: {
        fontSize: "12px",
      },
    },
  };
});

export default Footer;
