/** @format */

import * as Actions from "../actions/ActionTypes";
const CartReducer = (
  state = {
    isLoading: false,
    sub_total: 0,
    promo: undefined,
    promo_id: 0,
    total_amount: 0,
    delivery_cost: 0,
    pickup_date: undefined,
    pickup_time: undefined,
    delivery_date: undefined,
    delivery_time: undefined,
    promo_amount: 0,
    address: 0,
    total_item: 0,
    cardFormValue: { order_name: "", promo_code: "", customer_comments: "" },
    isDatePicker: false,
    dateValue: undefined,
    error: undefined,
    cardList: [],
    appConfig: null,
    feeName: "",
    feeAmount: 0.0,
    totalAmount: 0.0,
    discountAmount: 0.0,
    addOnFee: {
      parent_service_fee_name: "",
      parent_service_fee: "",
      addon: [],
    },
    promoCodeDetails: null,
    addOnAmount: 0.0,
    showCartModal: false,
    showPromoSuccessMessage: false,
    selectedRowId: undefined,
    selectedCustomerId: undefined,
    selectedTab: undefined,
    selectedCustomerTab: undefined,
    selectedDate: "",
  },
  action
) => {
  switch (action.type) {
    case Actions.CALCULATE_PRICING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case Actions.SUB_TOTAL:
      return Object.assign({}, state, {
        sub_total: action.data,
      });
    case Actions.DELIVERY_COST:
      return Object.assign({}, state, {
        delivery_cost: action.data,
      });
    case Actions.PROMO:
      return Object.assign({}, state, {
        promo: action.data,
        promo_id: action.data.id,
      });
    case Actions.TOTAL:
      return Object.assign({}, state, {
        promo_amount: action.data.promo_amount,
        total_amount: action.data.total,
        isLoading: false,
      });
    case Actions.SELECT_ADDRESS:
      return Object.assign({}, state, {
        address: action.data,
      });
    case Actions.TOTAL_ITEM:
      return Object.assign({}, state, {
        total_item: action.data,
      });
    case Actions.SELECT_DATE:
      return Object.assign({}, state, {
        delivery_date: action.data,
      });
    case Actions.SELECT_PICKUP_DATE:
      return Object.assign({}, state, {
        pickup_date: action.data,
      });
    case Actions.SELECT_PICKUP_TIME:
      return Object.assign({}, state, {
        pickup_time: action.data,
      });
    case Actions.SELECT_DELIVERY_DATE:
      return Object.assign({}, state, {
        delivery_date: action.data,
      });
    case Actions.SELECT_DELIVERY_TIME:
      return Object.assign({}, state, {
        delivery_time: action.data,
      });
    case Actions.RESET:
      return Object.assign({}, state, {
        isLoading: false,
        sub_total: 0,
        promo: undefined,
        promo_id: 0,
        total_amount: 0,
        delivery_cost: 0,
        pickup_date: undefined,
        pickup_time: undefined,
        delivery_time: undefined,
        promo_amount: 0,
        address: 0,
        delivery_date: undefined,
        total_item: 0,
        cardFormValue: {
          order_name: "",
          promo_code: "",
          customer_comments: "",
        },
        isDatePicker: false,
        dateValue: undefined,
        error: undefined,
        cardList: [],
        appConfig: null,
        feeName: "",
        feeAmount: 0.0,
        totalAmount: 0.0,
        discountAmount: 0.0,
        addOnFee: {
          parent_service_fee_name: "",
          parent_service_fee: "",
          addon: [],
        },
        promoCodeDetails: null,
        addOnAmount: 0.0,
        showPromoSuccessMessage: false,
      });
    case Actions.GET_CART_FORM_VALUE:
      return Object.assign({}, state, {
        cardFormValue: {
          ...state.cardFormValue,
          ...action.formValue,
        },
      });
    case Actions.TOGGLE_DATE:
      return Object.assign({}, state, {
        isDatePicker: !state.isDatePicker,
      });
    case Actions.GET_DATE:
      return Object.assign({}, state, {
        dateValue: action.date,
        isDatePicker: false,
      });
    case Actions.GET_CARD_PRODUCT_LIST:
      return Object.assign({}, state, {
        isLoading: true,
        cardList: [],
        error: undefined,
      });
    case Actions.GET_CARD_PRODUCT_LIST_SUCCESS: {
      let addOnFee = {
        parent_service_fee_name: "",
        parent_service_fee: "",
        addon: [],
      };
      let addOnAmount = 0;
      for (let i = 0; i < action.cardList.length; i++) {
        if (addOnFee.addon.length > 0) {
          const addon = addOnFee.addon.find(
            (addon) => addon.service_id === action.cardList[i].service_id
          );
          if (
            !addon &&
            action.cardList[i].service_fee_name &&
            action.cardList[i].service_fee
          ) {
            addOnFee.addon.push({
              service_id: action.cardList[i].service_id,
              service_fee_name: action.cardList[i].service_fee_name,
              service_fee: action.cardList[i].service_fee,
            });
            addOnAmount += action.cardList[i].service_fee;
          }
        } else {
          addOnFee = {
            ...addOnFee,
            parent_service_fee_name: action.cardList[i].parent_service_fee_name,
            parent_service_fee: action.cardList[i].parent_service_fee,
          };
          addOnAmount += action.cardList[i].parent_service_fee;
          if (
            action.cardList[i].service_fee_name &&
            action.cardList[i].service_fee
          ) {
            addOnFee.addon.push({
              service_id: action.cardList[i].service_id,
              service_fee_name: action.cardList[i].service_fee_name,
              service_fee: action.cardList[i].service_fee,
            });
            addOnAmount += action.cardList[i].service_fee;
          }
        }
        if (
          action.cardList[i].product_fee_name &&
          action.cardList[i].product_fee_amount
        ) {
          addOnFee.addon.push({
            service_id: action.cardList[i].service_id,
            service_fee_name: action.cardList[i].product_fee_name,
            service_fee: action.cardList[i].product_fee_amount,
          });
          addOnAmount += action.cardList[i].product_fee_amount;
        }
      }
      return Object.assign({}, state, {
        isLoading: false,
        cardList: action.cardList,
        addOnFee,
        addOnAmount,
        error: undefined,
      });
    }
    case Actions.GET_CARD_PRODUCT_LIST_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        isLoading: false,
        cardList: [],
        promoCodeDetails: null,
        addOnAmount: 0,
        discountAmount: 0,
      });
    case Actions.GET_APP_CONFIG:
      return Object.assign({}, state, {
        error: undefined,
        isLoading: true,
        appConfig: null,
      });
    case Actions.GET_APP_CONFIG_SUCCESS:
      return Object.assign({}, state, {
        error: undefined,
        isLoading: false,
        appConfig: action.configData,
      });
    case Actions.SET_SELECTED_CUSTOMER_DROP_OFF_DATE:
      return Object.assign({}, state, {
        error: undefined,
        isLoading: false,
        selectedDate: action.data,
      });
    case Actions.GET_APP_CONFIG_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        isLoading: false,
        appConfig: null,
      });
    case Actions.GET_EXTRA_FEE:
      return Object.assign({}, state, {
        feeName: action.feeName,
        feeAmount: action.feeAmount,
      });
    case Actions.GET_ADD_ON_SERVICE:
      return Object.assign({}, state, {
        isLoading: true,
        error: undefined,
      });
    case Actions.GET_ADD_ON_SERVICE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: undefined,
      });
    case Actions.GET_ADD_ON_SERVICE_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: state.error,
      });
    case Actions.GET_TOTAL_AMOUNT:
      return Object.assign({}, state, {
        totalAmount: action.totalAmount,
      });
    case Actions.CHECK_VALID_PROMO_CODE:
      return Object.assign({}, state, {
        discountAmount: 0,
        discountLoader: true,
        showPromoSuccessMessage: false,
      });
    case Actions.CHECK_VALID_PROMO_CODE_SUCCESS:
      return Object.assign({}, state, {
        discountAmount: action.discount.discount,
        promoCodeDetails: action.discount.deals,
        discountLoader: false,
        showPromoSuccessMessage: true,
      });
    case Actions.CHECK_VALID_PROMO_CODE_ERROR:
      return Object.assign({}, state, {
        discountAmount: 0,
        discountLoader: false,
        error: action.error,
        promoCodeDetails: null,
        showPromoSuccessMessage: false,
      });
    case Actions.DELETE_CART:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case Actions.DELETE_CART_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case Actions.DELETE_CART_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case Actions.TOGGLE_CART_MODAL:
      return Object.assign({}, state, {
        showCartModal: !state.showCartModal,
      });

    case Actions.GET_CART_ORDER_INPUT:
      return Object.assign({}, state, {
        // cardFormValue: {order_name: '', promo_code: '', customer_comments: ''},
        showPromoSuccessMessage: false,
      });

    case Actions.GET_CART_PROMO_CODE_NULL:
      return Object.assign({}, state, {
        cardFormValue: { promo_code: "" },
        discountAmount: 0,
      });
    case Actions.SET_SELECTED_ROW:
      return Object.assign({}, state, {
        selectedRowId: action.data,
      });
    case Actions.DISCARD_SELECTED_ROW:
      return Object.assign({}, state, {
        selectedRowId: undefined,
      });
    case Actions.SET_SELECTED_CUSTOMER:
      return Object.assign({}, state, {
        selectedCustomerId: action.data,
      });
    case Actions.DISCARD_SELECTED_CUSTOMER:
      return Object.assign({}, state, {
        selectedCustomerId: undefined,
      });
    case Actions.SET_SELECTED_TAB:
      return Object.assign({}, state, {
        selectedTab: action.data,
      });
    case Actions.RESET_SELECTED_TAB:
      return Object.assign({}, state, {
        selectedTab: undefined,
      });
    case Actions.SET_SELECTED_CUSTOMER_TAB:
      return Object.assign({}, state, {
        selectedCustomerTab: action.data,
      });
    case Actions.RESET_SELECTED_CUSTOMER_TAB:
      return Object.assign({}, state, {
        selectedCustomerTab: undefined,
      });
    default:
      return state;
  }
};

export default CartReducer;
