/** @format */

import React from "react";
import { Dialog, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { semiBold } from "../../config/Constants";
import { useNavigate } from "react-router-dom";
import * as colors from "../../assets/css/Colors";
import { getProfilePicture } from "../../config/Helper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='left' ref={ref} {...props} />;
});

const MobileMenu = ({ openMenu, closeMenu, openAccount, logOut }) => {
  const styles = localStyles();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user_data"));

  const openOrders = () => {
    navigate("/orders");
    closeMenu();
  };

  const openCustomers = () => {
    navigate("/customers");
    closeMenu();
  };

  return (
    <Dialog
      fullScreen
      open={openMenu}
      onClose={closeMenu}
      TransitionComponent={Transition}
      className={styles.container}>
      <div className={styles.mobileMenu}>
        <img
          src={getProfilePicture(user.profile_picture)}
          className={styles.profilePhoto}
          alt=''
        />
        {/* <span className={styles.menuItem}>Chat</span> */}
        <span onClick={openOrders} className={styles.menuItem}>
          Order
        </span>
        <span onClick={openCustomers} className={styles.menuItem}>
          Customers
        </span>
        <span onClick={openAccount} className={styles.menuItem}>
          Account
        </span>
        <span className={styles.menuItem}>
          <a href={"https://www.99dpf.com/"} target='_blank' rel='noreferrer'>
            FAQ / Help
          </a>
        </span>
        <span onClick={logOut} className={styles.menuItem}>
          Log out
        </span>
      </div>
    </Dialog>
  );
};

const localStyles = makeStyles(() => {
  return {
    container: {
      top: "80px",
    },
    mobileMenu: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      padding: "20px 0",
      background: colors.theme_bg,
      height: "100%",
    },
    closeButton: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      margin: "20px",
      paddingRight: "20px",
    },
    profilePhoto: {
      borderRadius: "50%",
      border: "4px solid #c90000",
      width: "150px",
      height: "150px",
      boxSizing: "border-box",
      objectFit: "contain",
    },
    menuItem: {
      marginTop: "20px",
      fontSize: "26px",
      fontWeight: semiBold,
      "&:hover": {
        cursor: "pointer",
      },
    },
  };
});

export default MobileMenu;
