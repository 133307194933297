/** @format */

// import strings from "../languages/strings.js";

export const API = process.env.REACT_APP_BASE_URL;
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const RECAPTCHA_CONSTANT = "6LdoMLUeAAAAAIur2-zMyuDPxYuf3io256ut-HJR";
export const LOGIN_URL = "partner/login";
export const CUSTOMER_EMAIL_URL = "partner/sendCustomerCredentials";
export const ORDER_NOTIFICATION_URL = "order/sendNotification";
export const GET_USER_URL = "customer/getCustomerById";
export const UPDATE_PARTNER = "partner/profile_edit";
export const UPDATE_CUSTOMER = "customer/updateCustomer";
export const VERIFY_EMAIL = "partnerUser/verifyEmail";
export const UPDATE_CUSTOMER_SETTINGS = "customer/updateCustomerSetting";
export const GET_SIGNED_URL = "customer/getSignedURL";
export const UPLOAD_PROFILE_IMAGE = "partner/profile_picture_edit";
export const FORGOT_PASSWORD_URL = "partner/forgetPassword";
export const RESET_PASSWORD_URL = "partner/updatePassword";
export const UPDATE_PASSWORD_URL = "partner/resetPassword";
export const RESET_USER_PASSWORD_URL = "partnerUser/resetPassword";
export const SOCIAL_MEDIA_LOGIN_URL = "customer/socialMediaLogin";
export const SOCIAL_MEDIA_SIGNUP_URL = "/customer/socialMediaSignup";
export const GET_SERVICES_URL = "service/getService";
export const GET_CATEGORY_BY_SERVICE_ID_URL = "category/getCategoryByServiceId";
export const GET_PRODUCT_BY_CATEGORY_ID = "product/getProductByCategoryId";
export const GET_ADD_ON_PRODUCT = "product/getAddOnProduct";
export const GET_SERVICE_ADDRESS = "address/getServiceAddress";
export const GET_CUSTOMER_ADDRESS = "address/getCustomerAddress";
export const GET_NEAR_BY_SERVICE_ADDRESS = "address/getNearByPickupAddress";
export const CREATE_ADDRESS_URL = "address/addAddress";
export const ADD_PARTNER_ADDRESS = "serviceLocation";
export const GET_CUSTOMER_ADDRESS_BY_ID_URL = "address/getCustomerAddressById";
export const UPDATE_ADDRESS_URL = "address/updateAddress";
export const DELETE_ADDRESS = "address/deleteAddress";
export const CREATE_ADD_TO_CART_URL = "cart/create"; // done
export const DELETE_ADD_TO_CART_URL = "cart/deleteCart";
export const UPDATE_CART_ADDRESS = "cart/updateAddress"; // not used
export const GET_ADD_TO_CART_URL = "cart/getCart"; // done
export const GET_CART_TOTAL_AMOUNT = "cart/getCartTotalAmount"; // done
export const GET_APP_CONFIG_URL = "config/getAppConfig";
export const GET_PAYMENT_METHODS_URL = "payment/getPaymentMethods";
export const GET_PARTNER_PROFILE = "partner/profile";
export const GET_ADD_ON_SERVICE_URL = "service/getAddOnService";
export const CHECK_PROMO_CODE = "deals/checkPromoCode";
export const GET_BANNER_IMAGE_URL = "config/getBannerImages";
export const GET_FORM_COPY_URL = "config/getFormCopy";
export const GET_FORM_COPY_BY_NAME_URL = "config/getFormCopyByName";
export const GET_DEALS_URL = "deals/getDeals";
export const CREATE_ORDER_URL = "order/create";
export const GET_ORDER_ITEMS_URL = "order/getOrderItems";
export const GET_ORDER_BY_ID = "order/getOrderDetail";
export const GET_PARTNER_USERS = "partnerUser/assigned_orders";
export const GET_PARTNER_USER_ORDER_URL = "order/getPartnerUserOrder";
export const GET_ORDER_URL = "order/getPartnerOrder"; // yes
export const GET_ORDER_STATUS_LIST = "order/getOrderStatusList"; // yes
export const UPDATE_ORDER_STATUS = "order/updateOrder"; // yes
export const UPDATE_STATUS_HISTORY = "order/updateOrderStatus";
export const STATUS_IMAGE_DELETE_URL = "order/deleteOrderStatusImage";
export const STATUS_DELETE_URL = "order/updateOrderStatus";
export const GET_ORDER_STATUS = "order/getOrderStatus";
export const STRIPE_PAYMENT_URL = "payment/stripePayment";
export const STRIPE_RECAPTCHA_URL = "payment/recaptcha";
export const GET_WALLET_AMOUNT_URL = "wallet/getCustomerWallet";
export const WALLET_PAYMENT_URL = "wallet/walletPayment";
export const ADD_WALLET_URL = "wallet/addWallet";
export const GET_CARD_URL = "wallet/getCard";
export const ADD_CARD_URL = "wallet/addCard";
export const EDIT_CARD_URL = "wallet/editCard";
export const DELETE_CARD_URL = "wallet/deleteCard";
export const GET_BILLING_ADDRESS_URL = "address/getBillingAddress";
export const CREATE_SERVICE_AREA_REQUEST_URL = "requestServiceArea/create";
export const GET_FAQ_URL = "config/getFAQ";
export const SUBMIT_FEATURE_REQUEST_URL = "config/submitFeatureRequest";
export const GET_SERVICE_AREA_URL = "config/getServiceArea";
export const ADD_BE_PARTNER_URL = "config/becomePartner";
export const CUSTOMER_DELETE = "customer/deleteRequest";
export const GET_PRIVACY_POLICY = "config/getPrivacyPolicy";
export const GET_TERMS_CONDITIONS = "config/getTermsConditions";
export const CUSTOMER_FORGOT_PASSWORD = "customer/forgotPassword";
export const CUSTOMER_CHECK_OTP = "customer/checkOTP";
export const SHARE_ORDER = "customer/shareOrder";
export const GET_NOTIFICATIONS = "notification/getNotifications";
export const CLEAR_NOTIFICATIONS = "notification/clearNotifications";
export const UPDATE_NOTIFICATION_COUNT = "notification/updateNotificationCount";
export const UNSEEN_NOTIFICATION_COUNT = "notification/getNotificationCount";
export const GET_MESSAGE_BY_ORDER_ID = "chat/getMessagesByOrderId";
export const GET_CUSTOMERS_URL = "partner/getCustomers";
export const GET_CUSTOMER_ORDER = "customer/getOrderByPartner";
export const CREATE_CUSTOMER = "partner/createCustomer";
export const LINK_CUSTOMER = "partner/linkCustomer";
export const SEARCH_CUSTOMER = "partner/searchCustomers";
export const SEARCH_PRODUCT = "/product/searchProduct";
export const EDIT_ORDER = "order/editOrder";
export const GET_INVOICE = "order/getOrderInvoice";
export const CONTACT_US = "config/contactUs";
export const LOGOUT = "customer/signOut";
export const GET_PARTNER_ROLES_URL = "partner/get_roles";
export const GET_PARTNER_PERMISSIONS_URL = "partner/get_permissions";
export const GET_PARTNER_USERS_URL = "partnerUser/all";
export const PARTNER_INSERT_ORDER_URL = "partner/insert_order";
export const PARTNER_ASSIGN_ORDER_URL = "partner/assign_order";
export const PARTNER_REARRANGE_ORDER_URL = "partner/rearrange_order_position";
export const PARTNER_REARRANGE_URL = "partnerUser/sequence";
export const PARTNER_UNASSIGN_ORDER_URL = "partnerUser/unassign_order";
export const HIDE_UNHIDE_ORDER_URL = "order/updateOrderVisibility";
export const HIDE_PARTNER_USERS_URL = "partnerUser/setHide";
export const VALIDATE_CUSTOMER = "partner/validateCustomer";
export const ADD_PARTNER_USER = "partnerUser/add_user";
export const UPDATE_PARTNER_USER = "partnerUser/update";
export const DELETE_PARTNER_USER = "partnerUser";
export const DASHBOARD_COUNT_URL = "order/getDashboardOrderCount";
export const ORDER_COUNT_URL = "order/partnerUserOrderCount";
export const ADD_CARD_WITH_TOKEN = "wallet/addCardWithToken";

export const IMAGE_URL = process.env.REACT_APP_MEDIA_URL;
export const app_title = "$99DPF";
export const add_address = "Add New Address";
export const change = "Change";
export const no_record = "No record found";
export const card_details = "Please Enter Card Details Properly";
export const select_billing_address = "Please Select Billing Address";

export const drop_off = "Select Drop Off Location";
export const pick_up = "Select Pick Up Location";
export const no_drop_off_msg =
  "Sorry, we do not have a $99DPF partner servicing your location yet. We are constantly adding more partners and service areas";
export const support_text =
  "Let us know what you think, be sure to include as much details as possible so that we can get back to you quickly";
export const request_service = "Request Service Area";

export const settings = "app_setting";
export const service = "service";
export const faq = "faq";
export const privacy = "privacy_policy";
export const product = "product";
export const register = "customer";
export const login = "customer/login";
export const address = "address";
export const address_list = "address/all";
export const address_delete = "address/delete";
export const my_orders = "get_orders";
export const promo_code = "promo";
export const profile = "customer";
export const profile_picture = "customer/profile_picture";
export const forgot = "customer/forgot_password";
export const reset = "customer/reset_password";
export const place_order = "order";
export const payment_list = "payment";
export const get_region = "get_region";
export const get_area = "get_area";
export const stripe_payment = "stripe_payment";
export const get_wallet = "customer/wallet";
export const add_wallet = "customer/add_wallet";
export const get_time = "get_time";
export const get_delivery_charge = "get_delivery_charge";
export const get_labels = "get_labels";
export const check_order_count = "check_order_count";
export const no_wallet = "Wallet balance is empty";
export const no_data = "No data found";
export const no_address = "No address found";
export const comapny_coment = "Company Comments ";
export const img_upload = "Image Upload ";
export const img_drag1 = " Drag a file here or click to upload";
export const img_drag2 = " We accept .png .jpeg and .jpg files";
export const change_status = "Update Status";
export const share_order = "Share our order";
export const complete_form =
  "Complete the form below to share a PDF of this order's current status and details";
export const total_nontaxable = "Total Nontaxable";
export const total_taxable = "Total Taxable";
export const payment_method = "Payments Method";
export const add_payment_method = "Add Payment Method";

export const tabValues = ["0", "1", "2"];

//Size
export const screenHeight = "100%";
export const height_45 = Math.round((45 / 100) * screenHeight);
export const height_40 = Math.round((40 / 100) * screenHeight);
export const height_50 = Math.round((50 / 100) * screenHeight);
export const height_60 = Math.round((60 / 100) * screenHeight);
export const height_35 = Math.round((35 / 100) * screenHeight);
export const height_25 = Math.round((20 / 100) * screenHeight);
export const height_30 = Math.round((30 / 100) * screenHeight);
export const height_26 = Math.round((26 / 100) * screenHeight);

//Path
export const logo = require(".././assets/img/logo_with_name.png");
export const gLogo = require(".././assets/img/Google__G__Logo.png");
export const fbLogo = require(".././assets/img/facebook_logo.png");
export const appleLogo = require(".././assets/img/apple_logo.png");
export const forgot_password = require(".././assets/img/forgot_password.png");
export const reset_password = require(".././assets/img/reset_password.png");
export const loading = require(".././assets/img/loading.png");
export const pin = require(".././assets/img/location_pin.png");
export const login_image = require(".././assets/img/logo_with_name.png");
export const washing_machine = require(".././assets/img/washing-machine.png");
export const completed_icon = require(".././assets/img/completed.png");
export const active_icon = require(".././assets/img/active.png");
export const plus = require(".././assets/img/plus.png");
export const minus = require(".././assets/img/minus.png");
export const pickup = require(".././assets/img/pickup.png");
export const delivery = require(".././assets/img/delivery.png");
export const low_wallet = require(".././assets/img/wallet.png");
export const user_image = require("../assets/img/user.png");
export const logo99 = require("../assets/img/applogo.png");
export const smallLogo99 = require("../assets/img/99Logo.png");
export const smallLogo99Partner = require("../assets/img/99DPF_Partner_Logo.png");
export const emptyCart = require("../assets/img/cart.jpg");
export const cartIcon = require("../assets/img/cartalt.png");
export const acumediaA = require("../assets/img/Acumedia_A_Gray.png");
export const introSlider1 = require("../assets/img/introSlider1.png");
export const introSlider2 = require("../assets/img/introSlider2.png");
export const introSlider3 = require("../assets/img/introSlider3.png");
export const introSlider4 = require("../assets/img/introSlider4.png");

//Font Family
export const normal = "400";
export const semiBold = "600";
export const bold = "700";

//Map
export const AUTOCOMPLETE_API_URL =
  "https://maps.googleapis.com/maps/api/place/autocomplete/json?";
export const PLACE_API_URL =
  "https://maps.googleapis.com/maps/api/place/details/json?";
export const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY_ENV;
export const LATITUDE_DELTA = 0.015;
export const LONGITUDE_DELTA = 0.0152;

export const REACT_APP_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

// acumedia url
export const ACUMEDIA_URL = "https://www.acumedia.com/";
export const ACUMEDIA_99DPF_URL = "https://www.99dpf.com";
// aws
export const ACCESS_KEY_ID = process.env.ACCESS_KEY_ID_ENV;
export const SECRET_ACCESS_KEY = process.env.SECRET_ACCESS_KEY_ENV;
export const S3_BUCKET = process.env.S3_BUCKET_ENV;
export const S3_REGION = process.env.S3_REGION_ENV;

// media
export const MEDIA_BASE_URL = process.env.REACT_APP_MEDIA_URL;

// stripe
export const PUBLISHABLE = process.env.REACT_APP_PUBLISHABLE_ENV;

// zoho
export const ANDROID_SALESIQ_APP_KEY = process.env.ANDROID_SALESIQ_APP_KEY_ENV;
export const ANDROID_SALESIQ_ACCESS_KEY =
  process.env.ANDROID_SALESIQ_ACCESS_KEY_ENV;
export const IOS_SALESIQ_APP_KEY = process.env.IOS_SALESIQ_APP_KEY_ENV;
export const IOS_SALESIQ_ACCESS_KEY = process.env.IOS_SALESIQ_ACCESS_KEY_ENV;

export const REACT_APP_GOOGLE_KEY_ENV = process.env.REACT_APP_GOOGLE_KEY_ENV;

// web client id
export const WEB_CLIENT_ID = process.env.WEB_CLIENT_ID_ENV;

export const CHECKSUM_KEY = process.env.CHECKSUM_KEY_ENV;
export const APPLE_TEXT = "apple";
export const GOOGLE_TEXT = "google";
export const FACEBOOK_TEXT = "facebook";

// menu item
export const menuItem = [
  // {name: 'My Profile', icon: 'account', navigateTo: 'Profile'},
  { name: "My Addresses", icon: "place", navigateTo: "Address" },
  { name: "Payment Methods", icon: "payments", navigateTo: "PaymentMethod" },
  { name: "Service Areas", icon: "map", navigateTo: "ServiceAreas" },
  { name: "Feature Request", icon: "forum", navigateTo: "FeatureRequest" },
  {
    name: "Become a Partner",
    icon: "people",
    navigateTo: "BecomePartner",
  },
  // {name: 'Account Delete', icon: 'account-remove', navigateTo: 'AccountDelete'},
  { name: "FAQ", icon: "help", navigateTo: "Faq" },
  { name: "Support", icon: "live-help", navigateTo: "" },
  { name: "Settings", icon: "settings", navigateTo: "Settings" },
];

export const pageList = [
  { name: "Notifications", icon: "chevron-right", navigateTo: "Notification" },
  {
    name: "Privacy Policy",
    icon: "chevron-right",
    navigateTo: "PrivacyPolicy",
  },
];

export const languageItem = [
  {
    name: "English",
  },
  {
    name: "Spanish",
  },
];

export const temp_hours = [
  {
    day_sequence: 0,
    day: "Monday",
    is_opened: false,
    closing_hours: "",
    opening_hours: "",
  },
  {
    day_sequence: 1,
    day: "Tuesday",
    is_opened: false,
    closing_hours: "",
    opening_hours: "",
  },
  {
    day_sequence: 2,
    day: "Wednesday",
    is_opened: false,
    closing_hours: "",
    opening_hours: "",
  },
  {
    day_sequence: 3,
    day: "Thursday",
    is_opened: false,
    closing_hours: "",
    opening_hours: "",
  },
  {
    day_sequence: 4,
    day: "Friday",
    is_opened: false,
    closing_hours: "",
    opening_hours: "",
  },
  {
    day_sequence: 5,
    day: "Saturday",
    is_opened: false,
    closing_hours: "",
    opening_hours: "",
  },
  {
    day_sequence: 6,
    day: "Sunday",
    is_opened: false,
    closing_hours: "",
    opening_hours: "",
  },
];

export const STATE_LIST = [
  {
    id: "Alabama",
    title: "Alabama",
    state_long_name: "Alabama",
    state_short_name: "AL",
  },
  {
    id: "Alaska",
    title: "Alaska",
    state_long_name: "Alaska",
    state_short_name: "AK",
  },
  {
    id: "American Samoa",
    title: "American Samoa",
    state_long_name: "American Samoa",
    state_short_name: "AS",
  },
  {
    id: "Arizona",
    title: "Arizona",
    state_long_name: "Arizona",
    state_short_name: "AZ",
  },
  {
    id: "Arkansas",
    title: "Arkansas",
    state_long_name: "Arkansas",
    state_short_name: "AR",
  },
  {
    id: "California",
    title: "California",
    state_long_name: "California",
    state_short_name: "CA",
  },
  {
    id: "Colorado",
    title: "Colorado",
    state_long_name: "Colorado",
    state_short_name: "CO",
  },
  {
    id: "Connecticut",
    title: "Connecticut",
    state_long_name: "Connecticut",
    state_short_name: "CT",
  },
  {
    id: "Delaware",
    title: "Delaware",
    state_long_name: "Delaware",
    state_short_name: "DE",
  },
  {
    id: "District Of Columbia",
    title: "District Of Columbia",
    state_long_name: "District Of Columbia",
    state_short_name: "DC",
  },
  {
    id: "Federated States Of Micronesia",
    title: "Federated States Of Micronesia",
    state_long_name: "Federated States Of Micronesia",
    state_short_name: "FM",
  },
  {
    id: "Florida",
    title: "Florida",
    state_long_name: "Florida",
    state_short_name: "FL",
  },
  {
    id: "Georgia",
    title: "Georgia",
    state_long_name: "Georgia",
    state_short_name: "GA",
  },
  {
    id: "Guam",
    title: "Guam",
    state_long_name: "Guam",
    state_short_name: "GU",
  },
  {
    id: "Hawaii",
    title: "Hawaii",
    state_long_name: "Hawaii",
    state_short_name: "HI",
  },
  {
    id: "Idaho",
    title: "Idaho",
    state_long_name: "Idaho",
    state_short_name: "ID",
  },
  {
    id: "Illinois",
    title: "Illinois",
    state_long_name: "Illinois",
    state_short_name: "IL",
  },
  {
    id: "Indiana",
    title: "Indiana",
    state_long_name: "Indiana",
    state_short_name: "IN",
  },
  {
    id: "Iowa",
    title: "Iowa",
    state_long_name: "Iowa",
    state_short_name: "IA",
  },
  {
    id: "Kansas",
    title: "Kansas",
    state_long_name: "Kansas",
    state_short_name: "KS",
  },
  {
    id: "Kentucky",
    title: "Kentucky",
    state_long_name: "Kentucky",
    state_short_name: "KY",
  },
  {
    id: "Louisiana",
    title: "Louisiana",
    state_long_name: "Louisiana",
    state_short_name: "LA",
  },
  {
    id: "Maine",
    title: "Maine",
    state_long_name: "Maine",
    state_short_name: "ME",
  },
  {
    id: "Marshall Islands",
    title: "Marshall Islands",
    state_long_name: "Marshall Islands",
    state_short_name: "MH",
  },
  {
    id: "Maryland",
    title: "Maryland",
    state_long_name: "Maryland",
    state_short_name: "MD",
  },
  {
    id: "Massachusetts",
    title: "Massachusetts",
    state_long_name: "Massachusetts",
    state_short_name: "MA",
  },
  {
    id: "Michigan",
    title: "Michigan",
    state_long_name: "Michigan",
    state_short_name: "MI",
  },
  {
    id: "Minnesota",
    title: "Minnesota",
    state_long_name: "Minnesota",
    state_short_name: "MN",
  },
  {
    id: "Mississippi",
    title: "Mississippi",
    state_long_name: "Mississippi",
    state_short_name: "MS",
  },
  {
    id: "Missouri",
    title: "Missouri",
    state_long_name: "Missouri",
    state_short_name: "MO",
  },
  {
    id: "Montana",
    title: "Montana",
    state_long_name: "Montana",
    state_short_name: "MT",
  },
  {
    id: "Nebraska",
    title: "Nebraska",
    state_long_name: "Nebraska",
    state_short_name: "NE",
  },
  {
    id: "Nevada",
    title: "Nevada",
    state_long_name: "Nevada",
    state_short_name: "NV",
  },
  {
    id: "New Hampshire",
    title: "New Hampshire",
    state_long_name: "New Hampshire",
    state_short_name: "NH",
  },
  {
    id: "New Jersey",
    title: "New Jersey",
    state_long_name: "New Jersey",
    state_short_name: "NJ",
  },
  {
    id: "New Mexico",
    title: "New Mexico",
    state_long_name: "New Mexico",
    state_short_name: "NM",
  },
  {
    id: "New York",
    title: "New York",
    state_long_name: "New York",
    state_short_name: "NY",
  },
  {
    id: "North Carolina",
    title: "North Carolina",
    state_long_name: "North Carolina",
    state_short_name: "NC",
  },
  {
    id: "North Dakota",
    title: "North Dakota",
    state_long_name: "North Dakota",
    state_short_name: "ND",
  },
  {
    id: "Northern Mariana Islands",
    title: "Northern Mariana Islands",
    state_long_name: "Northern Mariana Islands",
    state_short_name: "MP",
  },
  {
    id: "Ohio",
    title: "Ohio",
    state_long_name: "Ohio",
    state_short_name: "OH",
  },
  {
    id: "Oklahoma",
    title: "Oklahoma",
    state_long_name: "Oklahoma",
    state_short_name: "OK",
  },
  {
    id: "Oregon",
    title: "Oregon",
    state_long_name: "Oregon",
    state_short_name: "OR",
  },
  {
    id: "Palau",
    title: "Palau",
    state_long_name: "Palau",
    state_short_name: "PW",
  },
  {
    id: "Pennsylvania",
    title: "Pennsylvania",
    state_long_name: "Pennsylvania",
    state_short_name: "PA",
  },
  {
    id: "Puerto Rico",
    title: "Puerto Rico",
    state_long_name: "Puerto Rico",
    state_short_name: "PR",
  },
  {
    id: "Rhode Island",
    title: "Rhode Island",
    state_long_name: "Rhode Island",
    state_short_name: "RI",
  },
  {
    id: "South Carolina",
    title: "South Carolina",
    state_long_name: "South Carolina",
    state_short_name: "SC",
  },
  {
    id: "South Dakota",
    title: "South Dakota",
    state_long_name: "South Dakota",
    state_short_name: "SD",
  },
  {
    id: "Tennessee",
    title: "Tennessee",
    state_long_name: "Tennessee",
    state_short_name: "TN",
  },
  {
    id: "Texas",
    title: "Texas",
    state_long_name: "Texas",
    state_short_name: "TX",
  },
  {
    id: "Utah",
    title: "Utah",
    state_long_name: "Utah",
    state_short_name: "UT",
  },
  {
    id: "Vermont",
    title: "Vermont",
    state_long_name: "Vermont",
    state_short_name: "VT",
  },
  {
    id: "Virgin Islands",
    title: "Virgin Islands",
    state_long_name: "Virgin Islands",
    state_short_name: "VI",
  },
  {
    id: "Virginia",
    title: "Virginia",
    state_long_name: "Virginia",
    state_short_name: "VA",
  },
  {
    id: "Washington",
    title: "Washington",
    state_long_name: "Washington",
    state_short_name: "WA",
  },
  {
    id: "West Virginia",
    title: "West Virginia",
    state_long_name: "West Virginia",
    state_short_name: "WV",
  },
  {
    id: "Wisconsin",
    title: "Wisconsin",
    state_long_name: "Wisconsin",
    state_short_name: "WI",
  },
  {
    id: "Wyoming",
    title: "Wyoming",
    state_long_name: "Wyoming",
    state_short_name: "WY",
  },
];

export const searching_keys = [
  "customer_order_id",
  "company_name",
  "customer_name",
  "order_status",
  "service_type_name",
];

export const service_types = [
  {
    id: 1,
    label: "All",
  },
  {
    id: 2,
    label: "Drop Off & Pick Up",
  },
  {
    id: 3,
    label: "Pick Up & Delivery",
  },
  {
    id: 4,
    label: "Full Service",
  },
  {
    id: 5,
    label: "Mobile",
  },
  {
    id: 6,
    label: "Add Ons",
  },
  {
    id: 7,
    label: "EGR Drop Off & Pick Up",
  },
  {
    id: 8,
    label: "EGR Pick Up & Drop Off",
  },
];

export const CHECKOUT_ACTION = "CHECKOUT_ACTION";
export const BILLING_ACTION = "BILLING_ACTION";
export const CARD_ACTION = "CARD_ACTION";

export const PHONE_FIELD_LENGTH = 14;
export const FULL_NAME_MIN_LENGTH = 3;
export const FULL_NAME_MAX_LENGTH = 70;
export const COMPANY_NAME_MIN_LENGTH = 2;
export const COMPANY_NAME_MAX_LENGTH = 120;
export const EMAIL_MIN_LENGTH = 5;
export const EMAIL_MAX_LENGTH = 255;
export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 128;
export const APARTMENT_MAX_LENGTH = 20;
export const CITY_MIN_LENGTH = 2;
export const CITY_MAX_LENGTH = 28;
export const STATE_MIN_LENGTH = 2;
export const STATE_MAX_LENGTH = 2;
export const FULL_STATE_MAX_LENGTH = 52;
export const POSTAL_CODE_MIN_LENGTH = 5;
export const POSTAL_CODE_MAX_LENGTH = 5;
export const PROMO_CODE_MIN_LENGTH = 5;
export const PROMO_CODE_MAX_LENGTH = 20;
export const CARD_NICKNAME_MAX_LENGTH = 20;
export const CARD_NUMBER_MIN_LENGTH = 15;
export const CARD_NUMBER_MAX_LENGTH = 16;
export const CVC_MIN_LENGTH = 3;
export const CVC_MAX_LENGTH = 4;

export const select_service = "select_service";
export const select_category = "select_category";
export const select_location = "select_location";
export const select_type = "select_type";
export const select_product = "select_product";
export const order_confirm = "order_confirm";
export const ERROR_MESSAGE = "Something went wrong";
export const different_status = "Please select diffrent status";

export const new_orders = "orders pending";
export const orders_pending = "orders pending";
export const payment_pending = "payment pending";
export const completed_orders = "completed Service Orders";
export const total_orders = "total orders";

export const create_an_order = "Create a Service Order";
export const search_for_customer = "Search for a customer";
export const add_customer = "Add Customer";
export const Orders = "Orders";
export const Customers = "Customers";
export const confirm_customer = "Enter Customer Information";
export const customer_address = "Enter Customer Address";
export const customer_name = "Customer Name";
export const phone_number = "Phone Number";
export const company_name = "Company Name";
export const email = "Email";
export const agree = "By submitting this order, you agree to our";
export const code_applied = "Promo code successfully applied";
export const order_confirmation = "Service Order Confirmation";
export const services_ordered = "Services Ordered";
export const forgot_your_password = "Forgot Your Password?";
export const forgot_password_info =
  "Enter your email below to receive the instructions to reset your password.";

export const otp_info =
  "Enter the OTP received via Email to change your Password.";

export const boxShadow = "0px 3px 6px #00000029";

export const checkout = "Checkout";
export const proceed = "To Proceed Further";

export const no_record_found = "No record found";

export const payment_description =
  "You are electing to pay by cash/cheque at the time of service.";

export const select_method = "Select a Payment Method";

export const invalid_promo_code = "Invalid promo code";

export const customer_details = "Customer Details";

export const select_category_view = "Select Customer to view order";

export const no_customer_found = "No customers found";

export const customer_found = "Customer Found";

export const order_summary = "Service Order Summary";

export const tax_rate = "Tax Rate";

export const adjustment = "Adjustment";
export const adjustment_reason = "Adjustment Reason";

export const sub_total = "Sub Total";

export const Discount = "Discount";

export const total = "Total";

export const customer_exists = "Customer already exists and linked with you";

export const customer_info = "Customer Information";

export const payment_info = "Payment Information";

export const contact_dpf = "Please Contact $99DPF";

export const call_us = "Call us today!";

export const contact_number = "(877) 367-9937";

export const confirm_email = "Send email confirmation";

export const confirm_email_message =
  "Are you sure you want to send the welcome email to this customer?";

export const confirm_send_status = "Send order status confirmation";

export const confirm_send_status_message =
  "Are you sure you want to send the order status to this customer?";

export const expert_staff = "Our expert staff will assist you in";

export const address_changes = "to make changes to your address.";

export const no_notifications = "No notifications at the moment";

export const add_location = "Add location";

export const not_empty = "Cannot be empty";

export const customer_notes = "customer_notes";

export const internal_notes = "internal_notes";

export const media_url = "services-image-99dpf";

export const original_order = "Show Original Order";

export const edited_order = "Show Edited Order";

export const checkbox_lable = "Send push notification to customer";

export const internal_notes_optional = "Internal Notes";

export const internal_button = "Upload Internal Image (s)";

export const customer_notes_optional = "Customer Notes";

export const customer_button = "Upload Customer Image (s)";

export const partner_button = "Upload Partner Image (s)";

export const customer_status = "Customer Status";

export const regexp = /^[0-9]*(\.[0-9]{0,2})?$/;

export const regexFileExtension = /(\w+)$/;

export const default_location = "Default Location";

export const drop_off_pick_up = "Drop Off & Pick Up";

export const no_orders = "You have no orders";

export const UNABLE_CHAT =
  "Chat cannot be initiated for this order at the moment because the order is not assigned yet";

export const Add_Services = "Add Additional Services";
export const profile_update = "Profile Update Successfully";

export const location_Added = "Location Added Successfully";

export const location_update = "Location Updated Successfully";

export const status_update = "Status Update Successfully";

export const order_update = "Order Update Successfully";

export const login_alert = "Login Successful";

export const email_send = "Email sent successfully";
export const notification_send = "Notification send successfully";

export const password_reset = "Password updated successfully";

export const password_reset_fail = "Current Password is incorrect";

export const limit_exceed = "Password Mail will be sent only once a day";

export const notification_send_error =
  "There is an error while sending this notification";

export const verify_email_success = "You have successfully verify your email.";

export const verify_email_fail = "Kindly verify your email again.";

export const no_card = "No card found";

export const paymentExtraDetails = [
  { icon: "cash-multiple", color: "#3a9215" },
  { icon: "credit-card", color: "#c90000" },
  { icon: "wallet", color: "#000000" },
];

export const permissions = {
  all: "*",
  dashboard: "dashboard",
  login: "login",
  setting: "setting",
  management: "management",
  form_copy: "form_copy",
  partner_requests: "partner_requests",
  update_order_status_images: "update_order_status_images",
  update_order_status_notes: "update_order_status_notes",
  orders: "orders",
  complete_archived_order: "complete_archived_order",
  customer_wallet_histories: "customer_wallet_histories",
  customers: "customers",
  customer_address: "customer_address",
  pricing: "pricing",
  view_orders: "view_orders",
  delete_pricing: "delete_pricing",
  delete_order: "delete_order",
  edit_order: "edit_order",
  edit_customer: "edit_customer",
  delete_customer: "delete_customer",
  delete_customer_address: "delete_customer_address",
  edit_customer_address: "edit_customer_address",
  edit_form_copy: "edit_form_copy",
  delete_form_copy: "delete_form_copy",
  edit_customer_wallet_history: "edit_customer_wallet_history",
  delete_customer_wallet_history: "delete_customer_wallet_history",
  partners: "partners",
  edit_partner: "edit_partner",
  delete_partner: "delete_partner",
  edit_partner_request: "edit_partner_request",
  delete_partner_request: "delete_partner_request",
  statuses: "statuses",
  edit_status: "edit_status",
  delete_status: "delete_status",
  services: "services",
  categories: "categories",
  edit_service: "edit_service",
  delete_service: "delete_service",
  edit_category: "edit_category",
  delete_category: "delete_category",
  products: "products",
  edit_product: "edit_product",
  delete_product: "delete_product",
  edit_pricing: "edit_pricing",
  payment_methods: "payment-methods",
  edit_payment_methods: "edit_payment-methods",
  delete_payment_methods: "delete_payment-methods",
  service_address: "service_address",
  edit_service_address: "edit_service_address",
  delete_service_address: "delete_service_address",
  service_areas: "service_areas",
  edit_service_area: "edit_service_area",
  delete_service_area: "delete_service_area",
  deals: "deals",
  edit_deal: "edit_deal",
  delete_deal: "delete_deal",
  faqs: "faqs",
  edit_faq: "edit_faq",
  delete_faq: "delete_faq",
  privacy_policies: "privacy_policies",
  edit_privacy_policy: "edit_privacy_policy",
  delete_privacy_policy: "delete_privacy_policy",
  app_settings: "app_settings",
  edit_app_setting: "edit_app_setting",
  email_templates: "email_templates",
  edit_email_template: "edit_email_template",
  delete_email_template: "delete_email_template",
  banner_images: "banner-images",
  edit_banner_image: "edit_banner_image",
  delete_banner_image: "delete_banner_image",
  feature_requests: "feature_requests",
  delete_feature_request: "delete_feature_request",
  terms_and_conditions: "terms_and_conditions",
  edit_terms_and_condition: "edit_terms_and_condition",
  delete_terms_and_condition: "delete_terms_and_condition",
  delete_request: "delete_request",
};
