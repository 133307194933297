/** @format */

import * as Actions from "../actions/ActionTypes";
const ServicesReducer = (
  state = {
    parent_id: "",
    isLoading: false,
    error: undefined,
    service_list: undefined,
    available_services: undefined,
    unavailable_services: undefined,
    serviceId: "",
    serviceTypeName: null,
    serviceTypeKey: null,
    parentServiceData: null,
    toggleCartModal: false,
    productServiceId: "",
  },
  action
) => {
  switch (action.type) {
    case Actions.GET_SERVICE_BY_PARENT_ID:
      return Object.assign({}, state, {
        isLoading: true,
        parent_id: action.parent_id,
        service_list: [],
        available_services: [],
        unavailable_services: [],
      });
    case Actions.GET_SERVICE_BY_PARENT_ID_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        service_list: action.data,
        available_services: action.data.availableService,
        unavailable_services: action.data.unAvailableService,
      });
    case Actions.GET_SERVICE_BY_PARENT_ID_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        service_list: [],
        available_services: [],
        unavailable_services: [],
      });
    case Actions.GET_SERVICE_ID:
      return Object.assign({}, state, {
        serviceId: action.serviceId?.id,
        serviceTypeName: action.serviceId?.serviceType?.name,
        serviceTypeKey: action.serviceId?.serviceType?.key,
        serviceData: action?.serviceId,
      });
    case Actions.GET_PARENT_SERVICE_DATA:
      return Object.assign({}, state, {
        parentServiceData: action.parentServiceData,
      });
    case Actions.TOGGLE_CART_DELETE_MODAL:
      return Object.assign({}, state, {
        toggleCartModal: !state.toggleCartModal,
      });
    case Actions.RESER_SERVICES:
      return Object.assign({}, state, {
        isLoading: true,
        service_list: [],
        serviceData: null,
        toggleCartModal: false,
      });
    case Actions.PRODUCT_SERVICE_ID:
      return Object.assign({}, state, {
        productServiceId: action.productServiceId,
      });
    default:
      return state;
  }
};

export default ServicesReducer;
