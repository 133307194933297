/** @format */

import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import * as colors from "../../assets/css/Colors";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkout from "../checkout/Checkout";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Input,
  TextField,
  Button,
  InputAdornment,
  useMediaQuery,
  Tooltip,
  OutlinedInput,
} from "@mui/material";
import {
  adjustment,
  IMAGE_URL,
  order_summary,
  semiBold,
  sub_total,
  tax_rate,
  total,
  checkbox_lable,
  customer_notes_optional,
  internal_notes_optional,
  total_nontaxable,
  total_taxable,
  drop_off_pick_up,
} from "../../config/Constants";
import moment from "moment";

import FormControlLabel from "@mui/material/FormControlLabel";
import {
  capitalizeFirstLetter,
  convertToLocaleTime,
  dateFormatter,
  formatPhoneNumber,
  formatter,
  getLocalTimeZone,
  validateDecimal,
} from "../../config/Helper";
import GoogleMapReact from "google-map-react";
import { CommonError, Loader, Marker } from "../GeneralComponents";
import AddIcon from "@mui/icons-material/Add";
import strings from "../../languages/strings";
import { useDispatch, useSelector } from "react-redux";
import { editTheOrder, getOrderById, getOrderDetails } from "./services";
import { toast } from "react-toastify";
import { order_update } from "../../config/Constants";
import { addNewRow, handleEditOrder } from "../../actions/MyOrdersActions";
import { searchProduct } from "../customersHistory/services";
import {
  deleteSelectedProduct,
  setSelectedProduct,
} from "../../actions/NewOrderActions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

import { setSelectedRow, setSelectedTab } from "../../actions/CartActions";
import { DPFContext } from "../../context";
import { setEditOrder } from "../../actions/ProfileActions";
import { InputOutlined } from "@mui/icons-material";

const EditOrder = () => {
  useEffect(() => {
    const getOrderId = window.location.href.split("/");
    getOrderById(dispatch, getOrderId[getOrderId.length - 1]);
    getOrderDetails(dispatch, getOrderId[getOrderId.length - 1]);
  }, [window.location.href]);

  const styles = localStyles();
  const dispatch = useDispatch();

  const { products, searchIndexed, editOrderLoader } = useSelector(
    (state) => state.myOrders
  );

  const context = useContext(DPFContext);

  const { orderDetail } = useSelector((state) => state.myOrders);

  const order = useSelector((state) => state.myOrders.orderItems);
  const matchesMobile = useMediaQuery("(max-width:725px)");

  const [sendNotification, setSendNotification] = useState(false);
  const [quantityError, setQuantityError] = useState();
  const [priceError, setPriceError] = useState();
  const [error, setError] = useState();

  const [showModal, setShowModal] = useState(false);
  const fileInputRefInternal = useRef(null);
  const fileInputRef = useRef(null);

  const [fileBase64, setFileBase64] = useState([]);
  const [fileName, setFileName] = useState([]);

  const [fileBase64Internal, setFileBase64Internal] = useState([]);
  const [fileNameInternal, setFileNameInternal] = useState([]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const isImageOrPdf = (fileExtension) => {
    switch (fileExtension.toLowerCase()) {
      case "jpg":
      case "png":
      case "jpeg":
      case "pdf":
        return true;
    }
    return false;
  };

  const multiImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    const base64 = [];
    const fileNames = [];
    for (const file of files) {
      const regexFileExtension = /(\w+)$/;
      const extension = file.name.match(regexFileExtension)[0];
      if (isImageOrPdf(extension)) {
        base64.push(await toBase64(file));
        fileNames.push({
          ...file,
          fileName: file.name,
          base64: await toBase64(file),
          type: file.type,
        });
      }
    }
    setFileBase64([...base64]);
    setFileName([...fileName, ...fileNames]);
  };

  const imageUploadClose = (index) => {
    const images = [...fileName];
    const base64Images = [...fileBase64];
    images.splice(index, 1);
    base64Images.splice(index, 1);
    setFileName([...images]);
    setFileBase64([...base64Images]);
    if (fileInputRef) {
      fileInputRef.current.value = "";
    }
  };

  const multiImageUploadInternal = async (e) => {
    const files = Array.from(e.target.files);
    const base64 = [];
    const fileNames = [];
    for (const file of files) {
      const regexFileExtension = /(\w+)$/;
      const extension = file.name.match(regexFileExtension)[0];
      if (isImageOrPdf(extension)) {
        base64.push(await toBase64(file));
        fileNames.push({
          ...file,
          fileName: file.name,
          base64: await toBase64(file),
          type: file.type,
        });
      }
    }
    setFileBase64Internal([...base64]);
    setFileNameInternal([...fileNameInternal, ...fileNames]);
  };

  const imageUploadCloseInternal = (index) => {
    const images = [...fileNameInternal];
    const base64Images = [...fileBase64Internal];
    images.splice(index, 1);
    base64Images.splice(index, 1);
    setFileNameInternal([...images]);
    setFileBase64Internal([...base64Images]);
    if (fileInputRefInternal) {
      fileInputRefInternal.current.value = "";
    }
  };

  const handleQtyOrDescriptionChange = (e, index, changedKey) => {
    dispatch(
      handleEditOrder({
        key: "orderItems",
        updateKey: changedKey,
        index: index,
        value: e.target.value,
      })
    );
  };

  const handleTaxableChange = (e, index) => {
    dispatch(
      handleEditOrder({
        key: "orderItems",
        updateKey: "is_taxable",
        index: index,
        value: e.target.checked ? 1 : 0,
      })
    );
  };

  const handleAnyChange = (e, changedKey) => {
    dispatch(
      handleEditOrder({
        key: changedKey,
        value: e.target.value,
      })
    );
  };

  const handleNumericValueChange = (e, index, changedKey) => {
    if (validateDecimal(e.target.value)) {
      dispatch(
        handleEditOrder({
          key: "orderItems",
          updateKey: changedKey,
          index: index,
          value: e.target.value,
        })
      );
    }
  };

  const handleTaxRateChange = (e) => {
    if (validateDecimal(e.target.value)) {
      dispatch(
        handleEditOrder({
          key: "tax_rate",
          value: e.target.value,
        })
      );
    }
  };

  const handleNotificationCheckBoxChange = (e) => {
    setSendNotification(e.target.checked ? 1 : 0);
    dispatch(
      handleEditOrder({
        key: "send_notification",
        value: e.target.checked,
      })
    );
  };

  const ChangeOrderStatus = async (orderDetail, fileName, fileNameInternal) => {
    const internalImages = [];
    const customerImages = [];
    const productsList = [];

    fileNameInternal?.map((fileItem) => {
      internalImages.push({ imgName: fileItem });
    });

    fileName?.map((fileItem) => {
      customerImages.push({ imgName: fileItem });
    });

    orderDetail?.orderItems.map((item) => {
      productsList.push({
        id: item?.service ? item?.id : null,
        price: item?.price?.amount || item?.price,
        product_addon_amount:
          item?.price?.fee_amount || item?.product_addon_amount,
        product_addon_name:
          item?.price?.fee_name || item?.product_addon_name
            ? item?.product_addon_name
            : item?.product_name,
        product_id: item?.price?.product_id || item?.product_id,
        qty: item?.qty,
        service_id: item?.price?.service_id || orderDetail?.service_id,
        is_taxable: item?.is_taxable,
        discount: item?.discount ? Number(item?.discount) : 0,
        description: item?.product?.description
          ? item?.product?.description
          : item?.description,
      });
    });

    const body = {
      order_id: orderDetail?.order_id,
      tax_rate: orderDetail?.tax_rate === "" ? 0 : orderDetail.tax_rate,
      tax_amount: orderDetail?.tax_amount,
      adjustment: Number(orderDetail?.adjustment),
      adjustment_reason: orderDetail?.adjustment_reason,
      sub_total: orderDetail?.sub_total,
      total: orderDetail?.total,
      discount: orderDetail?.discount,
      customer_notes: orderDetail?.customer_notes,
      internal_notes: orderDetail?.internal_notes,
      customer_status_images: customerImages,
      internal_status_images: internalImages,
      products: productsList,
      order_edited_by: "partner",
      send_notification: orderDetail?.send_notification,
      non_taxable_total: orderDetail?.non_taxable_total,
      taxable_total: orderDetail?.taxable_total,
      tax_rate_subtotal: orderDetail?.tax_rate_subtotal,
    };
    if (
      orderDetail?.adjustment_reason === null ||
      orderDetail?.adjustment_reason === ""
    ) {
      delete body.adjustment_reason;
    }

    const check = await checkChangeValidation(orderDetail);
    if (check) {
      const response = await editTheOrder(dispatch, body);
      if (response) {
        toast.success(order_update);
        setSendNotification(false);
        navigate("/dashboard");
      }
    }
  };

  const checkChangeValidation = async (orderDetail) => {
    const qtyChecking = orderDetail?.orderItems.some((item) => item?.qty === 0);
    const priceChecking = orderDetail?.orderItems.some(
      (item) => item?.price === "0.00"
    );
    const productChecks = orderDetail?.orderItems?.length === 0;
    if (productChecks) {
      setError("An order must have at least one order item");
    } else {
      setError(undefined);
    }
    if (qtyChecking) {
      setQuantityError("Quantity cannot be empty or zero");
    } else {
      setQuantityError(undefined);
    }
    if (priceChecking) {
      setPriceError("Price cannot be empty or zero");
    } else {
      setPriceError(undefined);
    }
    return !(qtyChecking || priceChecking || productChecks);
  };

  const handleSearch = async (name, order_id, index) => {
    const body = {
      product_name: name,
      order_id,
    };
    await searchProduct(dispatch, body, index);
    dispatch(
      handleEditOrder({
        key: "orderItems",
        updateKey: "product_name",
        index: index,
        value: name,
      })
    );
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleDiscountOnBlur = (e, index) => {
    dispatch(
      handleEditOrder({
        key: "orderItems",
        updateKey: "discount",
        index: index,
        value: Number(e.target.value).toFixed(2),
      })
    );
  };

  const handlePriceOnBlur = (e, index) => {
    dispatch(
      handleEditOrder({
        key: "orderItems",
        updateKey: "price",
        index: index,
        value: Number(e.target.value).toFixed(2),
      })
    );
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    if (context?.paymentType?.type === undefined) {
      setShowModal(false);
      context.setPaymentType(null);
    } else {
      setShowModal(false);
      context.setPaymentType(null);
      const getOrderId = window.location.href.split("/");
      getOrderById(dispatch, getOrderId[getOrderId.length - 1]);
      getOrderDetails(dispatch, getOrderId[getOrderId.length - 1]);
    }
  };

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
    dispatch(setEditOrder(true));
    dispatch(setSelectedRow(orderDetail?.order_id));
    dispatch(setSelectedTab("Tracking"));
    context.setOrderDetails(orderDetail?.order_id);
  };

  const showSummary = () => {
    return (
      <div className={styles.innerContainer}>
        <div className={styles.leftContainer}>
          <p className={styles.searchItem}>{total_nontaxable}</p>
          <p className={styles.searchItem}>{total_taxable}</p>
          <p
            className={
              styles.searchItemBold
            }>{`Sales Tax (${orderDetail?.tax_rate})%`}</p>
          <p className={styles.searchItem}>{sub_total}</p>
          {order?.addONs.map((item) => (
            <p className={styles.searchItem} key={item?.id}>
              {item?.add_on_name}
            </p>
          ))}

          <p className={styles.searchItem}>
            {orderDetail?.adjustment_reason !== null &&
            orderDetail?.adjustment_reason !== ""
              ? capitalizeFirstLetter(orderDetail?.adjustment_reason)
              : adjustment}
          </p>
          {orderDetail?.deal && (
            <p className={styles.searchItem}>{`Promo ${
              orderDetail?.deal
                ? `(${
                    orderDetail?.deal?.promo_code ||
                    orderDetail?.promo_code_label
                  })`
                : ""
            }`}</p>
          )}
          {order?.paymentResponses?.map((item) => {
            if (item.payment_mode === "stripe") {
              return (
                <p key={item.id} className={styles.searchItem}>
                  Payment {capitalizeFirstLetter(item?.card_brand)}{" "}
                  {`(${item?.last_four_digits})`} {" - "}{" "}
                  {dateFormatter(item?.created_at)}
                </p>
              );
            } else {
              return (
                <>
                  <p className={styles.searchItem}>
                    Paid by Wallet {" - "} {dateFormatter(item?.created_at)}
                  </p>
                </>
              );
            }
          })}

          <p className={styles.searchItem}>Remaining Balance</p>
          {orderDetail?.amount_paid != orderDetail?.total && (
            <button className={styles.applyButton} onClick={handleShowModal}>
              Apply Payment
            </button>
          )}
          <p className={styles.searchItemBold}>{total}</p>
        </div>
        <div className={styles.rightContainer1}>
          <p className={styles.searchItem}>
            {(orderDetail?.non_taxable_total &&
              formatter.format(orderDetail?.non_taxable_total)) ||
              0}
          </p>
          <p className={styles.searchItem}>
            {(orderDetail?.taxable_total &&
              formatter.format(orderDetail?.taxable_total)) ||
              0}
          </p>

          <p className={styles.searchItem}>
            {(orderDetail?.tax_rate_subtotal &&
              formatter.format(orderDetail?.tax_rate_subtotal)) ||
              0}
          </p>
          <p className={styles.searchItem}>
            {(orderDetail?.sub_total &&
              formatter.format(orderDetail?.sub_total)) ||
              0}
          </p>
          {order?.addONs.map((item) => (
            <p className={styles.searchItem} key={item?.id}>
              {formatter.format(item?.add_on_amount)}
            </p>
          ))}
          <p className={styles.searchItem}>
            {formatter.format(orderDetail?.adjustment) || 0}
          </p>
          {orderDetail?.deal && (
            <p className={styles.searchItem}>
              {formatter.format(orderDetail?.promo_discount)}
            </p>
          )}
          {order?.paymentResponses?.map((item) => {
            if (item.payment_mode === "stripe") {
              return (
                <p key={item.id} className={styles.searchItem}>
                  -{formatter.format(item?.amount_paid)}
                </p>
              );
            } else {
              return (
                <>
                  <p className={styles.searchItem}>
                    -{formatter.format(item?.amount_paid)}
                  </p>
                </>
              );
            }
          })}

          <p className={styles.searchItem}>
            {formatter.format(orderDetail?.total - orderDetail?.amount_paid) ||
              0}
          </p>
          {orderDetail?.amount_paid != orderDetail?.total && (
            <div className={styles.space}></div>
          )}

          <p className={styles.searchItemBoldRight}>
            {(orderDetail?.total && formatter.format(orderDetail?.total)) || 0}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      {!orderDetail ? (
        <Loader />
      ) : (
        <div style={{ backgroundColor: "white", padding: "20px" }}>
          <div
            style={{
              display: "flex",
              paddingTop: 20,
              paddingLeft: 100,
              marginBottom: "-40px",
            }}>
            <ArrowBackIcon
              style={{ marginLeft: 10 }}
              sx={{ color: colors.icon_color }}
              fontSize={"large"}
              onClick={handleBackClick}
            />
          </div>
          <div className={styles.orderImageBlock}>
            <span className={styles.titleOrderId}>
              {strings.order_no}: {orderDetail?.customer_order_id}
            </span>
            <span className={styles.orderCreate}>
              {getLocalTimeZone(orderDetail?.created_at, "MM/DD/YYYY")}
            </span>
            <div className={styles.status_tab_image_view}>
              <div className={styles.inner_circle}>
                <img
                  src={`${IMAGE_URL}${
                    orderDetail?.partner_status?.image ||
                    orderDetail?.partner_status_image
                  }`}
                  className={styles.card_image}
                  alt=''
                />
              </div>
            </div>
            <span className={styles.orderStatus}>
              {orderDetail?.label?.label_for_partner}
            </span>
            <hr className={styles.divider} />
            <div className={styles.outerContainer}>
              <div className={styles.innerContainer}>
                <div>
                  <p
                    className={styles.searchItemBold}
                    style={{ textAlign: "center" }}>
                    Customer Information
                  </p>
                  <br />
                  <p className={styles.searchItem}>
                    {orderDetail?.customer?.customer_name}
                  </p>
                  {orderDetail?.customer?.company?.name && (
                    <p className={styles.searchItem}>
                      {orderDetail?.customer?.company?.name}
                    </p>
                  )}
                  <p className={styles.searchItem}>
                    {orderDetail?.customer?.email}
                  </p>
                  <p className={styles.searchItem}>
                    {formatPhoneNumber(
                      orderDetail?.customer?.phone_number || ""
                    )}
                  </p>
                  <p className={styles.searchItem}>
                    {orderDetail?.address && orderDetail?.address[0]?.address}
                  </p>
                </div>

                <div>
                  <p
                    className={styles.searchItemBold}
                    style={{ textAlign: "center", paddingLeft: 0 }}>
                    {drop_off_pick_up}
                  </p>
                  <br />
                  <p className={styles.searchItem}>
                    {orderDetail?.address?.line_1 ||
                      order?.service_address_line_1}{" "}
                    <br />
                  </p>
                  {orderDetail?.address?.line_2 ||
                  order?.service_address_line_2 ? (
                    <p className={styles.searchItem}>
                      {orderDetail?.address?.line_2 ||
                        order?.service_address_line_2}{" "}
                      <br />
                    </p>
                  ) : (
                    ""
                  )}
                  <p className={styles.searchItem}>{`${
                    orderDetail?.address?.city_long_name ||
                    order?.service_address_city_long_name
                  }, ${
                    orderDetail?.address?.state_short_name ||
                    order?.service_address_state_short_name
                  } ${
                    orderDetail?.address?.pin_code ||
                    order?.service_address_pin_code
                  }`}</p>
                </div>
                <div>
                  <p className={styles.searchItemBoldRight}>
                    {orderDetail.service_type_label} Date & Service Location
                    Hours
                  </p>
                  <br />
                  <p className={styles.searchItem}>
                    Date: {dateFormatter(orderDetail?.pickup_date)}
                  </p>
                  <p className={styles.searchItem}>
                    {orderDetail?.serviceAddress?.serviceHours
                      ?.sort((a, b) => a.day_sequence - b.day_sequence)
                      .map((serviceAddressItem) => {
                        if (serviceAddressItem.is_opened) {
                          return (
                            <div
                              key={serviceAddressItem.id}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}>
                              <span>{serviceAddressItem.day}</span>

                              <span>
                                {moment(
                                  `2021-09-10 ${convertToLocaleTime(
                                    serviceAddressItem?.opening_hours
                                  )}`
                                ).format("hh:mm a")}{" "}
                                -{" "}
                                {moment(
                                  `2021-09-10 ${convertToLocaleTime(
                                    serviceAddressItem?.closing_hours
                                  )}`
                                ).format("hh:mm a")}{" "}
                              </span>
                            </div>
                          );
                        } else {
                          <></>;
                        }
                      })}
                  </p>
                </div>
                {orderDetail?.address && (
                  <div style={{ width: "15%" }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOGLE_KEY_ENV,
                      }}
                      defaultCenter={{
                        lat: Number(orderDetail?.address?.latitude),
                        lng: Number(orderDetail?.address?.longitude),
                      }}
                      defaultZoom={16}>
                      <Marker
                        lat={Number(orderDetail?.address?.latitude)}
                        lng={Number(orderDetail?.address?.longitude)}
                      />
                    </GoogleMapReact>
                  </div>
                )}
              </div>
            </div>

            <hr className={styles.divider} />
            <div className={styles.outerContainer}>
              <TableContainer
                style={{
                  border: "1px solid rgba(224, 224, 224, 1)",
                  borderRadius: "5px",
                }}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                  <TableHead>
                    <TableRow style={{ borderBottom: "3px solid #EBE8E8" }}>
                      <TableCell align='center' style={{ fontWeight: "bold" }}>
                        Services Ordered
                      </TableCell>

                      <TableCell align='center' style={{ fontWeight: "bold" }}>
                        Quantity
                      </TableCell>
                      <TableCell align='center' style={{ fontWeight: "bold" }}>
                        Price
                      </TableCell>
                      <TableCell align='center' style={{ fontWeight: "bold" }}>
                        Discount
                      </TableCell>
                      <TableCell align='center' style={{ fontWeight: "bold" }}>
                        Total
                      </TableCell>
                      <TableCell align='center' style={{ fontWeight: "bold" }}>
                        Taxable
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderDetail?.orderItems?.length > 0 &&
                      orderDetail?.orderItems.map((row, index) => (
                        <div key={row.name} style={{ display: "contents" }}>
                          <TableRow
                            // dynamic styling required only here to had to in-line it
                            style={{ borderStyle: "hidden !important" }}
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}>
                            <TableCell
                              align='center'
                              style={{ borderBottom: "none" }}>
                              <OutlinedInput
                                inputProps={{
                                  style: {
                                    padding: 0,
                                    borderWidth: 0,
                                    borderColor: "transparent",
                                  },
                                }}
                                className={styles.quantityInput}
                                onChange={(e) =>
                                  handleSearch(
                                    e.target.value,
                                    orderDetail?.order_id,
                                    index
                                  )
                                }
                                value={
                                  row?.product?.product_name ||
                                  row?.product_name ||
                                  row?.product_addon_name
                                }
                              />

                              {products.length > 0 &&
                                index === searchIndexed && (
                                  <div className={styles.searchResultContainer}>
                                    {products.length > 0 &&
                                      index === searchIndexed &&
                                      products.map((item) => {
                                        return (
                                          <div
                                            key={item.id}
                                            className={styles.searchList}
                                            onClick={() =>
                                              dispatch(
                                                setSelectedProduct({
                                                  data: item,
                                                  index,
                                                })
                                              )
                                            }>
                                            {item?.product_name}
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                            </TableCell>

                            <TableCell
                              align='center'
                              width='10%'
                              style={{ borderBottom: "none" }}>
                              <OutlinedInput
                                inputProps={{
                                  style: {
                                    padding: 0,
                                  },
                                }}
                                className={styles.quantityInputNumber}
                                style={
                                  row?.qty === 0
                                    ? { border: "1px solid red" }
                                    : {}
                                }
                                onChange={(e) =>
                                  handleQtyOrDescriptionChange(e, index, "qty")
                                }
                                value={row?.qty}
                                error={row?.qty <= 0}
                              />
                            </TableCell>

                            <TableCell
                              align='center'
                              style={{ borderBottom: "none" }}>
                              ${" "}
                              <OutlinedInput
                                inputProps={{
                                  style: {
                                    padding: 0,
                                  },
                                }}
                                className={styles.quantityInput2}
                                value={row?.price?.amount || row?.price}
                                placeholder='Price'
                                onBlur={(e) => handlePriceOnBlur(e, index)}
                                onChange={(e) =>
                                  handleNumericValueChange(e, index, "price")
                                }
                              />
                            </TableCell>
                            <TableCell
                              align='center'
                              style={{ borderBottom: "none" }}>
                              ${" "}
                              <OutlinedInput
                                inputProps={{
                                  style: {
                                    padding: 0,
                                  },
                                }}
                                className={styles.quantityInput2}
                                value={row?.discount ? row?.discount : ""}
                                placeholder='0.00'
                                onBlur={(e) => handleDiscountOnBlur(e, index)}
                                onChange={(e) =>
                                  handleNumericValueChange(e, index, "discount")
                                }
                              />
                            </TableCell>
                            <TableCell
                              align='center'
                              style={{ borderBottom: "none" }}>
                              <p className={styles.quantityPrice}>
                                {formatter.format(
                                  (row?.qty || 0) *
                                    (row?.price?.amount || row?.price || 0) -
                                    (row?.discount || 0)
                                )}
                              </p>
                            </TableCell>
                            <TableCell
                              align='center'
                              style={{ borderBottom: "none" }}>
                              <Checkbox
                                {...label}
                                checked={row.is_taxable === 1}
                                onChange={(e) => handleTaxableChange(e, index)}
                                style={{ color: "#C90000" }}
                              />
                            </TableCell>
                            <TableCell
                              align='center'
                              style={{
                                borderBottom: "none",
                                paddingTop: "34px",
                              }}>
                              <Tooltip title='Delete product'>
                                <DeleteIcon
                                  className={styles.deleteIcon}
                                  onClick={() =>
                                    dispatch(
                                      deleteSelectedProduct({
                                        index,
                                      })
                                    )
                                  }
                                />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              colSpan={7}
                              // inline styling required for this particular cell
                              style={{
                                paddingTop: 0,
                                paddingBottom: 10,
                                borderBottom: "3px solid #EBE8E8",
                              }}>
                              <OutlinedInput
                                className={styles.quantityInput1}
                                value={
                                  row?.product?.description || row?.description
                                }
                                multiline
                                maxRows={2}
                                placeholder='Description'
                                onChange={(e) =>
                                  handleQtyOrDescriptionChange(
                                    e,
                                    index,
                                    "description"
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <div></div>
                        </div>
                      ))}
                  </TableBody>
                </Table>
                <div className={styles.addRowContainer}>
                  <button
                    className={styles.addRowButton}
                    onClick={() => {
                      dispatch(addNewRow("orderItems"));
                    }}>
                    <AddIcon className={styles.addIcon} />
                    Add Row
                  </button>
                </div>
              </TableContainer>
            </div>
            <hr className={styles.divider} />

            <div className={styles.outerContainer}>
              <div className={styles.innerContainer}>
                <div
                  className={styles.leftContainer}
                  styles={{ width: matchesMobile && "90%" }}>
                  <p
                    className={styles.searchItemBold}
                    style={{ paddingLeft: 0 }}>
                    {order_summary}
                  </p>
                  <br />
                  <div className={styles.orderSummaryFields}>
                    <p className={styles.taxRateField}>{tax_rate}</p>
                    <OutlinedInput
                      id='standard_text'
                      className={styles.textFieldStyling}
                      value={orderDetail?.tax_rate}
                      inputProps={{
                        step: "0.01",
                        style: {
                          padding: "5px 5px 10px 10px",
                        },
                      }}
                      onChange={(e) => handleTaxRateChange(e)}
                      endAdornment={
                        <InputAdornment position='end'> %</InputAdornment>
                      }
                    />
                  </div>
                  <br />
                  <div className={styles.orderSummaryFields}>
                    <p className={styles.adjustmentField}>{adjustment}</p>
                    <OutlinedInput
                      id='standard_text'
                      className={styles.textFieldStyling}
                      value={orderDetail?.adjustment}
                      onChange={(e) => handleAnyChange(e, "adjustment")}
                      endAdornment={
                        <InputAdornment position='end'> $</InputAdornment>
                      }
                      inputProps={{
                        style: {
                          padding: "5px 5px 10px 10px",
                        },
                      }}
                    />
                    <p className={styles.adjustmentField1}> Reason</p>
                    <OutlinedInput
                      id='standard_text'
                      className={styles.textFieldStyling}
                      inputProps={{
                        style: {
                          width: "33%",
                          padding: "5px 5px 10px 10px",
                        },
                      }}
                      value={orderDetail?.adjustment_reason}
                      onChange={(e) => handleAnyChange(e, "adjustment_reason")}
                    />
                  </div>
                </div>
                {!matchesMobile && (
                  <div
                    className={styles.rightContainer1}
                    style={{ marginTop: "30px" }}>
                    {showSummary()}
                  </div>
                )}
              </div>
            </div>
            {matchesMobile && (
              <div
                className={styles.rightContainer1}
                style={{ marginTop: "30px", width: matchesMobile && "90%" }}>
                {showSummary()}
              </div>
            )}
            <br />
            <div className={styles.notesContainer}>
              <div className={styles.notesLeftContainer}>
                <p>{customer_notes_optional}</p>
                <OutlinedInput
                  id='customerNotes'
                  placeholder='Notes to customer'
                  multiline
                  rows={2}
                  maxRows={2}
                  style={{ width: "100%" }}
                  onChange={(e) => handleAnyChange(e, "customer_notes")}
                  className={styles.multiLine}
                  // className={styles.fixPadding}
                />
                <Button
                  classes={styles.notesButton}
                  component='label'
                  style={{
                    border: "none",
                    background: "#c90000",
                    color: colors.text_white,
                    fontSize: "15px",
                    padding: "10px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    margin: "20px 0",
                    textTransform: "unset",
                    fontWeight: "300",
                    display: "flex",
                  }}>
                  Upload Customer Images
                  <input
                    hidden
                    multiple
                    accept='image/png, image/gif, image/jpeg'
                    type='file'
                    onChange={multiImageUpload}
                    ref={fileInputRef}
                  />
                </Button>
                {fileName?.length > 0 &&
                  fileName?.map((fileItem, index) => (
                    <div className={styles.fileNameContainer} key={fileItem}>
                      {fileItem.fileName}
                      <CloseIcon
                        onClick={() => imageUploadClose(index)}
                        className={styles.iconClose}
                      />
                    </div>
                  ))}
              </div>
              <div className={styles.notesRightContainer}>
                <p className={styles.internalNotes}>
                  {internal_notes_optional}
                </p>
                <OutlinedInput
                  id='internalNotes'
                  placeholder='Internal notes'
                  multiline
                  rows={2}
                  maxRows={2}
                  style={{ width: "100%" }}
                  className={styles.multiLine}
                  onChange={(e) => handleAnyChange(e, "internal_notes")}
                />
                <Button
                  classes={styles.notesButton}
                  component='label'
                  style={{
                    border: "none",
                    background: "#c90000",
                    color: colors.text_white,
                    fontSize: "15px",
                    padding: "10px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    margin: "20px 0",
                    textTransform: "unset",
                    fontWeight: "300",
                    display: "flex",
                  }}>
                  Upload Internal Images
                  <input
                    hidden
                    multiple
                    accept='image/png, image/gif, image/jpeg'
                    type='file'
                    onChange={multiImageUploadInternal}
                    ref={fileInputRefInternal}
                  />
                </Button>
                {fileNameInternal?.length > 0 &&
                  fileNameInternal?.map((fileItem, index) => (
                    <div className={styles.fileNameContainer} key={fileItem}>
                      {fileItem.fileName}
                      <CloseIcon
                        onClick={() => imageUploadCloseInternal(index)}
                        className={styles.iconClose}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendNotification}
                  onChange={handleNotificationCheckBoxChange}
                  style={{ color: "#C90000" }}
                />
              }
              label={checkbox_lable}
            />
            <br />
            {priceError && <CommonError type='textError' error={priceError} />}
            {quantityError && (
              <CommonError type='textError' error={quantityError} />
            )}
            {error && <CommonError type='textError' error={error} />}
            {editOrderLoader && <Loader />}
            <div
              style={
                editOrderLoader
                  ? { display: "flex", marginBottom: "0px" }
                  : { display: "flex", marginBottom: "20px" }
              }>
              <button
                className={styles.payButton}
                onClick={() =>
                  ChangeOrderStatus(orderDetail, fileName, fileNameInternal)
                }>
                Update Order
              </button>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <Checkout
          showModal={showModal}
          handleClose={handleClose}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};

const localStyles = makeStyles((theme) => {
  return {
    space: {
      height: "50px",
      ["@media  (max-width: 725px)"]: {
        height: "70px !important",
      },
    },
    fixPadding: {
      padding: 0,
    },
    applyButton: {
      border: "none",
      background: colors.icon_color,
      color: colors.text_white,
      fontSize: "15px",
      padding: "10px",
      width: "150px",
      borderRadius: "5px",
      cursor: "pointer",
      margin: "5px 0",
      marginLeft: "10px",
      ["@media  (max-width: 725px)"]: {
        width: "150px",
        padding: "5px",
      },
    },

    payButton: {
      border: "none",
      background: colors.icon_color,
      color: colors.text_white,
      fontSize: "20px",
      padding: "15px",
      width: "265px",
      borderRadius: "5px",
      cursor: "pointer",
      margin: "20px 0",
      ["@media  (max-width: 725px)"]: {
        width: "150px",
        padding: "5px",
      },
    },
    titleOrderId: {
      fontWeight: 600,
    },
    internalNotes: {
      textAlign: "start",
    },
    orderSummaryFields: {
      display: "flex",
    },
    taxRateField: {
      marginTop: "5px",
      marginRight: "37px",
    },
    adjustmentField: {
      marginTop: "5px",
      marginRight: "10px",
    },
    adjustmentField1: {
      marginTop: "5px",
      marginRight: "10px",
      marginLeft: "3%",
    },

    textFieldStyling: {
      width: "20%",
      marginBottom: "5px",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#c90000",
      },
    },
    multiLine: {
      padding: "5px 5px 10px 10px",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#c90000",
      },
    },
    searchList: {
      borderBottom: "1px solid gainsboro",
      borderTop: "1px solid gainsboro",
      display: "flex",
      padding: "10px",
      cursor: "pointer",
    },
    searchResultContainer: {
      height: "170px",
      overflowY: "scroll",
      overflowX: "hidden",
      position: "absolute",
      zIndex: 100,
      backgroundColor: "white",
      width: "18.2%",
    },
    fileNameContainer: {
      width: "75%",
      marginTop: 10,
      textAlign: "start",
    },
    notAllowedContainer: { width: "75%", marginTop: 10, color: "#C90000" },
    addRowContainer: {
      display: "flex",
      justifyContent: "end",
      width: "95%",
    },

    deleteIcon: {
      color: "#C90000",
      cursor: "pointer",
    },
    quantityInput: {
      padding: "5px 5px 10px 10px",
      width: "100%",
      height: "30px",
      textAlign: "center",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#c90000",
      },
    },
    quantityInput1: {
      width: "96%",
      margin: "auto",
      padding: "5px 5px 10px 10px",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#c90000",
      },
    },
    quantityInput2: {
      width: "70%",
      height: "30px",
      padding: "5px 5px 10px 10px",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#c90000",
      },
    },
    quantityPrice: {
      fontWeight: "bold",
    },
    quantityInputNumber: {
      padding: "5px 5px 10px 10px",
      height: "30px",
      textAlign: "center",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#c90000",
      },
    },
    addRowButton: {
      border: "none",
      background: colors.icon_color,
      color: colors.text_white,
      borderRadius: "5px",
      cursor: "pointer",
      margin: "20px 0",
      fontSize: "15px",
      padding: "10px",
      width: "150px",
    },
    addIcon: {
      marginTop: "-3px",
      marginRight: "3px",
    },
    iconClose: {
      marginLeft: 10,
      cursor: "pointer",
    },
    notesButton: {
      border: "none",
      background: colors.icon_color,
      color: colors.text_white,
      fontSize: "20px",
      padding: "15px",
      width: "475px",
      borderRadius: "5px",
      cursor: "pointer",
      margin: "20px 0",
      textTransform: "unset",
      fontWeight: "400",
    },
    outerContainer: {
      width: "90%",
      lineHeight: "20px",
    },
    innerContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    notesContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "90%",
    },
    leftContainer: {
      width: "100%",
      textAlign: "left",
    },
    rightContainer: {
      width: "30%",
      textAlign: "end",
    },
    rightContainer1: {
      width: "50%",
      textAlign: "end",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
      },
    },
    notesLeftContainer: {
      width: "48%",
      textAlign: "left",
      marginRight: "2%",
    },
    notesRightContainer: {
      width: "48%",
      textAlign: "end",
      marginLeft: "2%",
    },
    searchItemBold: {
      paddingLeft: 10,
      marginBottom: 5,
      fontWeight: "bold",
      textAlign: "left",
    },
    searchItemBoldRight: {
      paddingLeft: 10,
      marginBottom: 0,
      fontWeight: "bold",
      textAlign: "right",
    },
    searchItem: {
      paddingLeft: 10,
      marginBottom: 5,
      color: "grey",
    },
    closeIcon: {
      color: "white",
      cursor: "pointer",
    },
    popoverContainer: {
      width: "100%",
      background: "#C90000",
      gridColumnStart: 1,
      gridColumnEnd: 3,
      marginRight: "40px",
      boxShadow: "0px 3px 6px #00000029",
      padding: "20px",
      borderRadius: "5px",
      marginBottom: "20px",
      position: "sticky",
      top: 0,
      zIndex: 2,
      [theme.breakpoints.down("md")]: {
        order: 4,
        marginRight: 0,
        padding: 0,
      },
      [theme.breakpoints.between("md", "lg")]: {
        marginRight: "20px",
        padding: "5px",
      },
    },
    head: {
      color: "white",
      marginLeft: "45%",
      fontSize: "24px",
      fontWeight: "bold",
      fontFamily: "Montserrat, sans-serif",
      width: "65%",
      minWidth: "65%",
      maxWidth: "65%",
    },
    divider: {
      width: "90%",
      border: "none",
      height: "3px !important",
      margin: "20px",
      alignSelf: "center",
      backgroundColor: "#ada0a0",
    },
    orderImageBlock: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    orderCreate: {
      fontSize: "22px",
      lineHeight: "33px",
      color: colors.text_light_gray,
      marginBottom: "25px",
    },
    status_tab_image_view: {
      width: 106,
      height: 106,
      borderRadius: "50%",
      backgroundColor: colors.theme_bg_red,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inner_circle: {
      width: 98,
      height: 98,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      backgroundColor: colors.black_text_color,
    },
    card_image: {
      width: 90,
      height: 90,
      borderRadius: 45,
      alignSelf: "center",
      top: 5,
    },
    orderStatus: {
      marginTop: "25px",
      fontSize: "22px",
      lineHeight: "33px",
      fontWeight: semiBold,
    },
  };
});

export default EditOrder;
