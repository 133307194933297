/** @format */

import * as Actions from "../actions/ActionTypes";
const ProfileReducer = (
  state = {
    isLoading: false,
    error: undefined,
    setEditOrder: false,
    partnerData: undefined,
    data: {
      customer_name: undefined,
      phone_number: undefined,
      email: undefined,
    },
    profile_picture: undefined,
    message: undefined,
    status: undefined,
    profileInput: {
      customer_name: undefined,
      phone_number: undefined,
      email: undefined,
      company_name: undefined,
      customer_id: undefined,
    },
    profileError: {
      customer_name: "",
      phone_number: "",
      email: "",
      company_name: "",
      profile_error: "",
      api_error: "",
    },
    showEditModal: false,
  },
  action
) => {
  switch (action.type) {
    case Actions.EDIT_PROFILE_PENDING:
    case Actions.UPDATE_PROFILE_PENDING:
    case Actions.PARTNER_DATA_PENDING:
    case Actions.PARTNER_INSERT_ORDER_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case Actions.EDIT_PROFILE_ERROR:
    case Actions.UPDATE_PROFILE_ERROR:
    case Actions.PARTNER_DATA_ERROR:
    case Actions.PARTNER_INSERT_ORDER_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case Actions.EDIT_PROFILE_SUCCESS:
    case Actions.UPDATE_PROFILE_SUCCESS:
    case Actions.PARTNER_INSERT_ORDER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
      });

    case Actions.UPDATE_PROFILE_PICTURE:
      return Object.assign({}, state, {
        isImageLoading: true,
        profile_picture: action.profileImage,
      });
    case Actions.UPDATE_PROFILE_PICTURE_SUCCESS:
      return Object.assign({}, state, {
        isImageLoading: false,
      });
    case Actions.UPDATE_PROFILE_PICTURE_ERROR:
      return Object.assign({}, state, {
        isImageLoading: false,
        profile_picture: undefined,
      });
    case Actions.SET_EDIT_ORDER:
      return Object.assign({}, state, {
        setEditOrder: action.data,
      });
    case Actions.RESET_EDIT_ORDER:
      return Object.assign({}, state, {
        setEditOrder: false,
      });
    case Actions.ON_CHNAGE_PROFILE_INPUT:
      return Object.assign({}, state, {
        profileInput: action.profileInput,
      });
    case Actions.PROFILE_ERROR_MESSAGE:
      return Object.assign({}, state, {
        profileError: action.errorMessage,
      });

    case Actions.TOGGLE_OPEN_EDIT_PROFILE:
      return Object.assign({}, state, {
        showEditModal: !state.showEditModal,
      });
    case Actions.RESET_PROFILE_DATA:
      return Object.assign({}, state, {
        profile_picture: undefined,
      });
    case Actions.PARTNER_DATA_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        partnerData: action.data.users,
      });
    default:
      return state;
  }
};

export default ProfileReducer;
