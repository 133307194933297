/** @format */

import * as Actions from "../actions/ActionTypes";
const OrderAssignmentReducer = (
  state = {
    isLoading: false,
    error: undefined,
  },
  action
) => {
  switch (action.type) {
    case Actions.ORDER_ASSIGNMENT_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        error: undefined,
      });

    case Actions.ORDER_ASSIGNMENT_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case Actions.ORDER_ASSIGNMENT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: undefined,
      });

    default:
      return state;
  }
};

export default OrderAssignmentReducer;
