/** @format */

import { fetchPost, fetchGet } from "../../config/request";
import {
  CONTACT_US,
  CREATE_CUSTOMER,
  CREATE_ORDER_URL,
  GET_CUSTOMERS_URL,
  GET_CUSTOMER_ORDER,
  GET_ORDER_ITEMS_URL,
  GET_ORDER_STATUS,
  GET_ORDER_URL,
  GET_CARD_URL,
  EDIT_CARD_URL,
  SEARCH_CUSTOMER,
  SEARCH_PRODUCT,
  SHARE_ORDER,
  UNSEEN_NOTIFICATION_COUNT,
  UPDATE_CUSTOMER,
  UPDATE_NOTIFICATION_COUNT,
  DELETE_CARD_URL,
  ADD_CARD_WITH_TOKEN,
  ADD_CARD_URL,
} from "../../config/Constants";
import {
  serviceActionPending,
  serviceActionError,
  serviceActionSuccess,
} from "../../actions/HomeActions";
import {
  getOrderId,
  getOrderItems,
  getOrderItemsError,
  getOrderItemsSuccess,
  getOrderStatus,
  getOrderStatusError,
  getOrderStatusSuccess,
  orderTabPage,
  shareOrderError,
  shareOrderSuccess,
} from "../../actions/MyOrdersActions";
import { reset } from "../../actions/CartActions";
import {
  submitOrder,
  submitOrderError,
  submitOrderSuccess,
} from "../../actions/CheckoutAction";
import { addToCart } from "../../actions/ProductActions";
import {
  getUnseenNotificationCountError,
  getUnseenNotificationCountSuccess,
  updateNotificationCountError,
  updateNotificationCountSuccess,
} from "../../actions/CommonAction";
import {
  addUserError,
  addUserPending,
  addUserSuccess,
} from "../../actions/NewOrderActions";
import {
  contactUsError,
  contactUsPending,
  searchCustomerError,
  searchCustomerSuccess,
  searchProductError,
  searchProductSuccess,
} from "../../actions/SearchActions";
import {
  getCustomerError,
  getCustomerOrderError,
  getCustomerOrderSuccess,
  getCustomerPending,
  getCustomerSuccess,
} from "../../actions/CustomerActions";
import {
  editCardError,
  editCardPending,
  editCardSuccess,
  getCardListError,
  getCardListPending,
  getCardListSuccess,
  addCardPending,
  addCardWithTokenError,
  addCardError,
  addCardSuccess,
} from "../../actions/CardListActions";
import {
  listServiceActionError,
  // listServiceActionPending,
  // listServiceActionSuccess,
} from "../../actions/AddressListActions";
import {
  updateServiceActionError,
  updateServiceActionPending,
  updateServiceActionSuccess,
} from "../../actions/ProfileActions";

export async function getCustomerList(dispatch, offset, limit) {
  dispatch(getCustomerPending());
  try {
    const payload = {
      offset,
      limit,
      partner_id: JSON.parse(localStorage.getItem("user_data"))?.partner?.id,
    };
    const getCustomerList = await fetchPost(GET_CUSTOMERS_URL, payload);
    if (getCustomerList.error)
      return dispatch(getCustomerError(getCustomerList.error));
    dispatch(getCustomerSuccess(getCustomerList));
    return getCustomerList.customerList;
  } catch (e) {
    return dispatch(getCustomerError(e));
  }
}

export const createNewUser = async (dispatch, state) => {
  dispatch(addUserPending());
  const newUser = await fetchPost(`${CREATE_CUSTOMER}`, {
    customer_name: state.customer_name,
    company_name: state.company_name,
    customer_address: state.address.address,
    line_1: state.address.line_1,
    phone_number: state.phone_number,
    email: state.email,
    partner_id: JSON.parse(localStorage.getItem("user_data")).id,
    latitude: state.address.latitude,
    longitude: state.address.longitude,
  });
  if (newUser?.error) {
    dispatch(addUserError(newUser?.error));
    return false;
  } else {
    dispatch(addUserSuccess(newUser));
    return true;
  }
};

export const searchCustomer = async (dispatch, value) => {
  const search = await fetchPost(`${SEARCH_CUSTOMER}`, {
    query: value,
    partner_id: JSON.parse(localStorage.getItem("user_data")).id,
  });
  if (search?.error) {
    dispatch(searchCustomerError(search?.error));
    return false;
  } else {
    dispatch(searchCustomerSuccess(search.customers));
    return true;
  }
};

export const searchProduct = async (dispatch, body, index) => {
  const search = await fetchPost(`${SEARCH_PRODUCT}`, body);
  if (search?.error) {
    dispatch(searchProductError(search?.error));
    return false;
  } else {
    dispatch(searchProductSuccess({ data: search, index }));
    return true;
  }
};

export const onGetOrderStatus = async (dispatch, orderId) => {
  dispatch(getOrderStatus());
  const status = await fetchGet(`${GET_ORDER_STATUS}?order_id=${orderId}`);
  if (status.error) {
    return dispatch(getOrderStatusError(status.error));
  }
  return dispatch(getOrderStatusSuccess(status));
};

export async function updateProfile(profileInput) {
  delete profileInput.errorMessage;
  try {
    let tel = profileInput.phone_number;
    tel = tel.replace(/\D+/g, "");
    const body = { ...profileInput, phone_number: tel };
    const update = await fetchPost(UPDATE_CUSTOMER, body);
    return !update?.error;
  } catch (e) {
    return false;
  }
}

export const getOrderDetails = async (dispatch, orderId) => {
  dispatch(getOrderItems());
  const orderData = await fetchGet(
    `${GET_ORDER_ITEMS_URL}?order_id=${orderId}`
  );
  if (orderData?.error) return dispatch(getOrderItemsError(orderData?.error));
  dispatch(getOrderItemsSuccess(orderData));
};

export const shareOrder = async (dispatch, data, orderId) => {
  const orderData = await fetchPost(`${SHARE_ORDER}`, {
    orderId: orderId,
    receiver_email: data.email,
    subject: data.subject,
    from: data.from,
    to: data.to,
  });
  if (orderData?.error) return dispatch(shareOrderError(orderData?.error));
  else {
    dispatch(shareOrderSuccess(orderData));
  }
};

export const updateNotificationCount = async (dispatch, user_id) => {
  const updateNotification = await fetchGet(
    `${UPDATE_NOTIFICATION_COUNT}?receiver_id=${user_id}&receiver_type=partner`
  );
  if (updateNotification?.error)
    return dispatch(updateNotificationCountError(updateNotification?.error));
  else {
    dispatch(updateNotificationCountSuccess(updateNotification));
  }
};

export const getUnseenNotificationCount = async (dispatch, user_id) => {
  const unseenNotification = await fetchGet(
    `${UNSEEN_NOTIFICATION_COUNT}?receiver_id=${user_id}&receiver_type=partner`
  );
  if (unseenNotification?.error)
    return dispatch(getUnseenNotificationCountError(unseenNotification?.error));
  else {
    dispatch(getUnseenNotificationCountSuccess(unseenNotification));
  }
};

export async function getCustomerOrders(dispatch, orderLoader, offset, limit) {
  try {
    dispatch(orderTabPage(0));
    if (orderLoader) dispatch(serviceActionPending(offset));
    const getOrder = await fetchPost(GET_ORDER_URL, {
      partner_id: JSON.parse(localStorage.getItem("user_data")).partner.id,
      isStatusWithComplete: true,
      offset,
      limit,
    });
    if (getOrder.error) return dispatch(serviceActionError(getOrder.error));
    dispatch(serviceActionSuccess(getOrder));
    return true;
  } catch (e) {
    return dispatch(serviceActionError(e));
  }
}

export async function getOrderByPartner(dispatch, customer, offset, limit) {
  try {
    const getOrderByPartner = await fetchPost(GET_CUSTOMER_ORDER, {
      partner_id: JSON.parse(localStorage.getItem("user_data")).partner.id,
      customer_id: customer?.id || customer?.customer_id,
      offset,
      limit,
    });
    if (getOrderByPartner.error) {
      dispatch(getCustomerOrderError(getOrderByPartner.error));
      return false;
    }
    dispatch(getCustomerOrderSuccess(getOrderByPartner.orderList));
    return getOrderByPartner.orderList;
  } catch (e) {
    return dispatch(serviceActionError(e));
  }
}

export async function addOrder(
  dispatch,
  paymentModalDetails,
  cardList,
  setOrderDetails,
  setCartAddress,
  cardDetails,
  totalAmount,
  dateValue,
  discountAmount,
  amount,
  promoCodeDetails,
  cardFormValue,
  walletAmount,
  user
) {
  dispatch(submitOrder());

  const products = [];
  cardList.map((cardListValue) => {
    products.push({
      product_id: cardListValue.product_id,
      service_id: cardListValue.service_id,
      qty: 1,
      price: cardListValue.amount,
      product_addon_name: cardListValue.product_fee_name
        ? cardListValue.product_fee_name
        : "",
      product_addon_amount: cardListValue.product_fee_amount,
    });
  });
  const body = {
    customer_id: user?.id.toString(),
    address_id: cardList[0].address_id,
    pickup_date: dateValue,
    pickup_time: null,
    delivery_date: null,
    delivery_time: null,
    total: totalAmount,
    discount: discountAmount,
    sub_total: amount,
    delivery_cost: null,
    deals_id: promoCodeDetails?.deals?.id || null,
    delivered_by: null,
    // TODO remove after fix requested data
    payment_mode: "after",

    service_id: cardList[0].service_id,
    service_addon_name: cardList[0].service_fee_name
      ? cardList[0].service_fee_name
      : "",
    service_addon_amount: cardList[0].service_fee,
    products,
    order_name: cardFormValue?.order_name || "",
    customer_comments: cardFormValue?.order_notes || "",
    // TODO remove after fix requested data
    billing_address_id: Number(1),
  };
  const insertOrder = await fetchPost(CREATE_ORDER_URL, body);
  if (insertOrder.error) return dispatch(submitOrderError());
  dispatch(getOrderId(insertOrder.oder_id));
  setOrderDetails(null);
  setCartAddress(null);
  dispatch(addToCart(0, []));
  dispatch(submitOrderSuccess());
  dispatch(reset());
  try {
    await localStorage.removeItem("cardOptionalValue");
    return true;
  } catch (exception) {
    return false;
  }
}

export const pendingPaymentFilter = (orders) => {
  return orders.filter(
    (order) =>
      order?.status?.length > 0 &&
      order.status[order.status.length - 1]?.partner_status
        ?.label_for_partner === "Pending Payment"
  );
};

export const onContactUs = async (dispatch, state) => {
  dispatch(contactUsPending());
  const body = {
    name: JSON.parse(localStorage.getItem("user_data"))?.name,
    email: state?.email,
    message: state?.query,
    phone: state?.phone?.replace(/[- )(]/g, ""),
  };
  const contact = await fetchPost(`${CONTACT_US}`, body);
  if (contact?.error) {
    dispatch(contactUsError(contact?.error));
    return false;
  } else {
    return true;
  }
};
export async function getCustomerCards(dispatch, customer_id) {
  try {
    dispatch(getCardListPending());
    const cardList = await fetchGet(
      `${GET_CARD_URL}?customer_id=${customer_id}`
    );

    if (cardList.error) dispatch(getCardListError(cardList.error));
    else {
      dispatch(getCardListSuccess(cardList));
    }
  } catch (e) {
    dispatch(listServiceActionError(e));
    return { error: e };
  }
}

export async function editCard(dispatch, state, selectedCard, customer_id) {
  try {
    dispatch(editCardPending());
    const body = {
      stripeCardId: selectedCard?.id,
      customer_id: customer_id,
      card_exp_month: state?.expiryDate?.split("/")[0],
      card_exp_year: state?.expiryDate?.split("/")[1],
      is_default_billing_address: false,
      address: {
        city_long_name:
          state?.address?.city_long_name || selectedCard?.address_city,
        country_long_name:
          state?.address?.country_long_name || selectedCard?.address_country,
        line_1: state?.address?.line_1
          ? state?.address?.line_1
          : selectedCard?.address_line1,
        line_2: state?.address?.line_1
          ? state?.address?.line_1
          : selectedCard?.address_line2,
        state_long_name:
          state?.address?.state_long_name || selectedCard?.address_state,
        pin_code: state?.address?.pin_code || selectedCard?.address_zip,
      },
    };
    const cardList = await fetchPost(EDIT_CARD_URL, body);
    if (cardList.error) {
      dispatch(editCardError(cardList.error));
      return false;
    } else {
      dispatch(editCardSuccess(cardList));
      getCustomerCards(dispatch, customer_id);
      return true;
    }
  } catch (e) {
    dispatch(listServiceActionError(e));
    return { error: e };
  }
}
export async function onDeleteCard(dispatch, stripeId, customer_id) {
  try {
    dispatch(updateServiceActionPending());
    const deleteCard = await fetchPost(DELETE_CARD_URL, {
      stripeCardId: stripeId,
      customer_id: customer_id,
    });

    if (deleteCard.error)
      return dispatch(updateServiceActionError(deleteCard.error));
    await getCustomerCards(dispatch, customer_id);
    dispatch(updateServiceActionSuccess(deleteCard.message));
  } catch (e) {
    dispatch(updateServiceActionError(e));
  }
}

export async function addCardWithToken(
  dispatch,
  state,
  customer_id,
  stateName
) {
  try {
    if (state?.address?.id) delete state.address.id;
    dispatch(addCardPending());
    const body = {
      customer_id: customer_id,
      stripeTokenId: state?.cardDetails?.id,
      is_default_billing_address: state?.is_default_billing_address,
      name_on_card: stateName?.name_on_card,
      nick_name: stateName?.nick_name,
      address: {
        ...state?.address,
        customer_id: customer_id.toString(),
        address_name: state?.address?.address,
        is_default_billing: false,
        line_2: state?.address?.line_1 ?? "",
      },
    };
    const cardList = await fetchPost(ADD_CARD_WITH_TOKEN, body);
    if (cardList.error) {
      dispatch(addCardWithTokenError(cardList.error));
      return false;
    } else {
      getCustomerCards(dispatch, customer_id);
      return true;
    }
  } catch (e) {
    dispatch(listServiceActionError(e));
    return { error: e };
  }
}

export async function addCard(dispatch, state, customer_id) {
  try {
    dispatch(addCardPending());
    const body = {
      customer_id: customer_id,
      card_number: state?.cardNumber?.replace(/\s/g, ""),
      card_exp_month: state?.expiryDate?.split("/")[0],
      card_exp_year: state?.expiryDate?.split("/")[1],
      card_cvc: state?.cvc,
      name_on_card: "commodo culpa esse",
      nick_name: "reprehenderit dolore do culpa",
      is_default_billing_address: state?.billingAddress,
      address: {
        ...state?.address,
        customer_id: customer_id,
        address_name: state?.address?.address,
        is_default_billing: false,
        line_2: state?.address?.line_1 ?? "",
      },
    };
    const cardList = await fetchPost(ADD_CARD_URL, body);
    if (cardList.error) {
      dispatch(addCardError(cardList.error));
      return false;
    } else {
      dispatch(addCardSuccess(cardList));
      getCustomerCards(dispatch, customer_id);
      return true;
    }
  } catch (e) {
    dispatch(listServiceActionError(e));
    return { error: e };
  }
}
