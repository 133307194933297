/** @format */

import * as ActionTypes from "./ActionTypes";

export const serviceActionPending = (offset) => ({
  type: ActionTypes.MYORDERS_LIST_PENDING,
  offset,
});

export const serviceActionError = (error) => ({
  type: ActionTypes.MYORDERS_LIST_ERROR,
  error: error,
});

export const serviceActionSuccess = (data) => ({
  type: ActionTypes.MYORDERS_LIST_SUCCESS,
  data: data,
});

export const filterType = (data) => ({
  type: ActionTypes.FILTER_TYPE,
  data: data,
});

export const getOrderId = (orderId) => ({
  type: ActionTypes.GET_ORDER_ID,
  orderId,
});

export const getOrderItems = () => ({
  type: ActionTypes.GET_ORDER_ITEMS,
});

export const getOrderItemsSuccess = (data) => ({
  type: ActionTypes.GET_ORDER_ITEMS_SUCCESS,
  data,
});

export const getOrderItemsError = (error) => ({
  type: ActionTypes.GET_ORDER_ITEMS_ERROR,
  error,
});

export const getOrderStatus = () => ({
  type: ActionTypes.GET_ORDER_STATUS,
});

export const getOrderStatusSuccess = (orderStatusList) => ({
  type: ActionTypes.GET_ORDER_STATUS_SUCCESS,
  orderStatusList,
});

export const getOrderStatusError = (error) => ({
  type: ActionTypes.GET_ORDER_STATUS_ERROR,
  error,
});

export const orderTabPage = (page) => ({
  type: ActionTypes.ORDER_TAB_PAGE,
  page,
});

export const getNotificationOrderId = (notificationOrderId) => ({
  type: ActionTypes.NOTIFICATION_ORDER_ID,
  notificationOrderId,
});

export const activeOrdersPending = (offset) => ({
  type: ActionTypes.ACTIVE_ORDERS_PENDING,
  offset,
});

export const activeOrdersError = (error) => ({
  type: ActionTypes.ACTIVE_ORDERS_ERROR,
  error: error,
});

export const activeOrdersSuccess = (data) => ({
  type: ActionTypes.ACTIVE_ORDERS_SUCCESS,
  data,
});

export const shareOrderPending = () => ({
  type: ActionTypes.SHARE_ORDER_PENDING,
});

export const shareOrderError = (error) => ({
  type: ActionTypes.SHARE_ORDER_ERROR,
  error: error,
});

export const shareOrderSuccess = (data) => ({
  type: ActionTypes.SHARE_ORDER_SUCCESS,
  data,
});

export const getOrderByIdPending = () => ({
  type: ActionTypes.GET_ORDER_BY_ID_PENDING,
});

export const getOrderByIdSuccess = (data) => ({
  type: ActionTypes.GET_ORDER_BY_ID_SUCCESS,
  data,
});

export const getOrderByIdError = (error) => ({
  type: ActionTypes.GET_ORDER_BY_ID_ERROR,
  error,
});

export const handleEditOrder = (data) => ({
  type: ActionTypes.HANDLE_EDIT_ORDER,
  data,
});
export const addNewRow = (key) => ({
  type: ActionTypes.ADD_NEW_ROW,
  key,
});

export const setQtyError = () => ({
  type: ActionTypes.SET_QTY_ERROR,
});

export const editOrderPending = () => ({
  type: ActionTypes.EDIT_ORDER_PENDING,
});

export const editOrderError = (error) => ({
  type: ActionTypes.EDIT_ORDER_ERROR,
  error: error,
});

export const editOrderSuccess = (data) => ({
  type: ActionTypes.EDIT_ORDER_SUCCESS,
  data,
});

export const setOrderSummary = (data) => ({
  type: ActionTypes.SET_ORDER_SUMMARY,
  data,
});

export const changeOrderStatusPending = (error) => ({
  type: ActionTypes.CHANGE_ORDER_STATUS_PENDING,
  error,
});

export const setChangeOrderStatusError = (error) => ({
  type: ActionTypes.CHANGE_ORDER_STATUS_ERROR,
  error,
});

export const changeOrderStatusSuccess = () => ({
  type: ActionTypes.CHANGE_ORDER_STATUS_SUCCESS,
});

export const getOrderInvoiceSuccess = (data) => ({
  type: ActionTypes.GET_ORDER_INVOICE_SUCCESS,
  data,
});

export const sendNotificationSuccess = (data) => ({
  type: ActionTypes.SEND_NOTIFICATION_SUCCESS,
  data: data,
});

export const sendNotificationPending = () => ({
  type: ActionTypes.SEND_NOTIFICATION_PENDING,
});

export const sendNotificationError = (error) => ({
  type: ActionTypes.SEND_NOTIFICATION_ERROR,
  error: error,
});

export const getOrderInvoiceError = (error) => ({
  type: ActionTypes.GET_ORDER_INVOICE_ERROR,
  error,
});

export const updateStatusHistoryPending = () => ({
  type: ActionTypes.UPDATE_STATUS_HISTORY_PENDING,
});

export const updateStatusHistorySuccess = (data) => ({
  type: ActionTypes.UPDATE_STATUS_HISTORY_SUCCESS,
  data,
});

export const updateStatusHistoryError = (error) => ({
  type: ActionTypes.UPDATE_STATUS_HISTORY_ERROR,
  error,
});

export const deleteStatusHistoryPending = () => ({
  type: ActionTypes.DELETE_STATUS_HISTORY_PENDING,
});

export const deleteStatusHistorySuccess = (data) => ({
  type: ActionTypes.DELETE_STATUS_HISTORY_SUCCESS,
  data,
});

export const deleteStatusHistoryError = (error) => ({
  type: ActionTypes.DELETE_STATUS_HISTORY_ERROR,
  error,
});

export const changeOrderStatusError = (error) => ({
  type: ActionTypes.CHANGE_ORDER_STATUS_ERROR,
  error,
});

