import * as Actions from "../actions/ActionTypes";
const RegisterReducer = (
    state = {
      isLoading: false,
      error: undefined,
      data: [],
      message: undefined,
      status: undefined,
    },
    action,
) => {
  switch (action.type) {
    case Actions.RESET_SIGNUP_VALUE:
      return Object.assign({}, state, {
        error: undefined,
      });
    case Actions.REGISTER_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        error: undefined,
      });
    case Actions.REGISTER_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case Actions.REGISTER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        status: "success",
        message: "",
      });

    default:
      return state;
  }
};

export default RegisterReducer;
