/** @format */

import * as ActionTypes from "./ActionTypes";

export const subTotal = (data) => ({
  type: ActionTypes.SUB_TOTAL,
  data: data,
});
export const setSelectedCard = (data) => ({
  type: ActionTypes.SET_SELECTED_CARD,
  data,
});

export const discardSelectedCard = () => ({
  type: ActionTypes.DISCARD_SELECTED_CARD,
});

export const discardSelectedAddress = () => ({
  type: ActionTypes.DISCARD_SELECTED_ADDRESS,
});



export const total = (data) => ({
  type: ActionTypes.TOTAL,
  data: data,
});

export const promo = (data) => ({
  type: ActionTypes.PROMO,
  data: data,
});

export const calculatePricing = () => ({
  type: ActionTypes.CALCULATE_PRICING,
});

export const selectAddress = (data) => ({
  type: ActionTypes.SELECT_ADDRESS,
  data: data,
});

export const selectDate = (data) => ({
  type: ActionTypes.SELECT_DATE,
  data: data,
});

export const reset = () => ({
  type: ActionTypes.RESET,
});

export const selectPickupDate = (data) => ({
  type: ActionTypes.SELECT_PICKUP_DATE,
  data: data,
});

export const selectPickupTime = (data) => ({
  type: ActionTypes.SELECT_PICKUP_TIME,
  data: data,
});

export const selectDeliveryDate = (data) => ({
  type: ActionTypes.SELECT_DELIVERY_DATE,
  data: data,
});

export const selectDeliveryTime = (data) => ({
  type: ActionTypes.SELECT_DELIVERY_TIME,
  data: data,
});

export const deliveryCost = (data) => ({
  type: ActionTypes.DELIVERY_COST,
  data: data,
});

export const totalItem = (data) => ({
  type: ActionTypes.TOTAL_ITEM,
  data: data,
});

export const getCartFormValue = (formValue) => ({
  type: ActionTypes.GET_CART_FORM_VALUE,
  formValue,
});

export const getRemoveOrderNameInput = () => ({
  type: ActionTypes.GET_CART_ORDER_INPUT,
});

export const toggleDate = () => ({
  type: ActionTypes.TOGGLE_DATE,
});

export const getDate = (date) => ({
  type: ActionTypes.GET_DATE,
  date,
});

export const getCardProductList = () => ({
  type: ActionTypes.GET_CARD_PRODUCT_LIST,
});
export const getCardProductListSuccess = (cardList) => ({
  type: ActionTypes.GET_CARD_PRODUCT_LIST_SUCCESS,
  cardList,
});
export const getCardProductListError = (error) => ({
  type: ActionTypes.GET_CARD_PRODUCT_LIST_ERROR,
  error,
});

export const getAppConfig = () => ({
  type: ActionTypes.GET_APP_CONFIG,
});

export const getAppConfigSuccess = (configData) => ({
  type: ActionTypes.GET_APP_CONFIG_SUCCESS,
  configData,
});

export const getAppConfigError = (error) => ({
  type: ActionTypes.GET_APP_CONFIG_ERROR,
  error,
});

export const getExtraFee = (feeName, feeAmount) => ({
  type: ActionTypes.GET_EXTRA_FEE,
  feeName,
  feeAmount,
});
export const getAddOnService = () => ({
  type: ActionTypes.GET_ADD_ON_SERVICE,
});
export const getAddOnServiceSuccess = () => ({
  type: ActionTypes.GET_ADD_ON_SERVICE_SUCCESS,
});
export const getAddOnServiceError = (error) => ({
  type: ActionTypes.GET_ADD_ON_SERVICE_ERROR,
  error,
});
export const getTotalAmount = (totalAmount) => ({
  type: ActionTypes.GET_TOTAL_AMOUNT,
  totalAmount,
});

export const checkValidPromoCode = () => ({
  type: ActionTypes.CHECK_VALID_PROMO_CODE,
});

export const checkValidPromoCodeSuccess = (discount) => ({
  type: ActionTypes.CHECK_VALID_PROMO_CODE_SUCCESS,
  discount,
});

export const checkValidPromoCodeError = (error) => ({
  type: ActionTypes.CHECK_VALID_PROMO_CODE_ERROR,
  error,
});

export const deleteCart = () => ({
  type: ActionTypes.DELETE_CART,
});

export const deleteCartSuccess = () => ({
  type: ActionTypes.DELETE_CART_SUCCESS,
});

export const deleteCartError = () => ({
  type: ActionTypes.DELETE_CART_ERROR,
});

export const toggleCartModal = () => ({
  type: ActionTypes.TOGGLE_CART_MODAL,
});
export const onPromoCodeNull = () => ({
  type: ActionTypes.GET_CART_PROMO_CODE_NULL,
});

export const setSelectedRow = (data) => ({
  type: ActionTypes.SET_SELECTED_ROW,
  data,
});

export const discardSelectedRow = () => ({
  type: ActionTypes.DISCARD_SELECTED_ROW,
});

export const setSelectedCustomer = (data) => ({
  type: ActionTypes.SET_SELECTED_CUSTOMER,
  data,
});

export const discardSelectedCustomer = () => ({
  type: ActionTypes.DISCARD_SELECTED_CUSTOMER,
});

export const setSelectedTab = (data) => ({
  type: ActionTypes.SET_SELECTED_TAB,
  data,
});

export const resetSelectedTab = () => ({
  type: ActionTypes.RESET_SELECTED_TAB,
});

export const setSelectedCustomerTab = (data) => ({
  type: ActionTypes.SET_SELECTED_CUSTOMER_TAB,
  data,
});

export const resetSelectedCustomerTab = () => ({
  type: ActionTypes.RESET_SELECTED_CUSTOMER_TAB,
});

export const setDropDate = (data) => ({
  type: ActionTypes.SET_SELECTED_CUSTOMER_DROP_OFF_DATE,
  data,
});
export const discardSelectedPaymentCard = (data) => ({
  type: ActionTypes.DISCARD_SELECTED_PAYMENT_CARD,
  data,
});
export const setSelectedPaymentCard = (data) => ({
  type: ActionTypes.SET_SELECTED_PAYMENT_CARD,
  data,
});


