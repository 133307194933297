/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { submitVerifyEmail } from "../components/account/service";
import { toast } from "react-toastify";
import {
  smallLogo99Partner,
  verify_email_fail,
  verify_email_success,
} from "../config/Constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loader } from "../components/GeneralComponents";

import * as colors from "../assets/css/Colors";
import { checkIsAuthError, checkIsAuthSuccess } from "../actions/AuthAction";
import useStyles from "../GlobalStyle";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const EmailVerification = () => {
  const GlobalStyle = useStyles();
  const dispatch = useDispatch();
  const styles = localStyles();
  const navigate = useNavigate();
  const [res, setRes] = useState(false);
  const [message, setMessage] = useState("");
  const [messageData, setMessageData] = useState("");
  const [load, setLoad] = useState(true);

  const { isAuth } = useSelector((state) => state.auth);

  let [searchParams] = useSearchParams();
  const verificationCode = searchParams.get("verificationCode");
  const mode = searchParams.get("mode");

  useEffect(() => {
    verifyEmail();
  }, []);

  const verifyEmail = async () => {
    setLoad(true);
    try {
      const response = await submitVerifyEmail(
        dispatch,
        verificationCode,
        mode
      );
      setRes(response[0]);
      if (response[0]) {
        setLoad(false);
        toast.success(verify_email_success);
        setMessage("Congratulations");
        setMessageData("You have successfully verified your email.");
      } else {
        setLoad(false);
        if (response[1].includes("already in use")) {
          setMessage("Sorry");
          setMessageData(`${response[1]}`);
        } else if (response[1].includes("Invalid or expired code!")) {
          setMessage("Sorry");
          setMessageData(
            "The verification link is either invalid or expired. Please try again."
          );
        } else {
          setMessage("Sorry");
          setMessageData(
            "There is an issue while verifying your email. Please try again."
          );
        }
        toast.error(verify_email_fail);
      }
    } catch (err) {
      toast.error(verify_email_fail);
    }
  };

  const LoginNow = () => {
    if (isAuth) navigate("/dashboard");
    else navigate("/login");
  };

  const matches = useMediaQuery("(max-width:1023px)");

  return (
    <div className={GlobalStyle.container}>
      <div className={styles.header_section} />
      <div className={styles.bottom_section}>
        <div className={styles.card}>
          <div className={GlobalStyle.logoCard}>
            <img width={matches ? 240 : 292} src={smallLogo99Partner} alt='' />
          </div>
          <div className={styles.inputs}>
            {load ? (
              <Loader />
            ) : (
              <>
                <div
                  className={GlobalStyle.inputHeader}
                  style={{ textAlign: "center" }}>
                  {message}
                </div>
                <div className={styles.verification}>{messageData}</div>
                {res && (
                  <button
                    className={GlobalStyle.mainBtnGlobal}
                    onClick={LoginNow}>
                    {isAuth ? "Go To Dashboard" : "Login Now"}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state.login.isLoading,
    errorValue: state.login.error,
    data: state.login.data,
    message: state.login.message,
    status: state.login.status,
  };
}

const mapDispatchToProps = (dispatch) => ({
  checkIsAuthSuccess: () => dispatch(checkIsAuthSuccess()),
  checkIsAuthError: () => dispatch(checkIsAuthError()),
});
export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);

const localStyles = makeStyles((theme) => {
  return {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#ededed",
    },
    header_section: {
      width: "100%",
      height: 397,
      backgroundColor: colors.top_red_section,
      alignItems: "center",
    },
    logo_content: {
      top: 60,
    },
    logo: {
      width: 30,
      height: 25,
    },
    register_name: {
      color: colors.top_red_section,
      fontSize: 20,
      fontWeight: "bold",
      alignSelf: "center",
      marginTop: 10,
    },
    body_section: {
      width: "100%",
      backgroundColor: colors.theme_bg_three,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginTop: "25px",
    },
    inputs: {
      width: "100%",
      padding: "15px 40px 20px",
      boxSizing: "border-box",
      [theme.breakpoints.down("md")]: {
        padding: "15px 20px 20px",
      },
    },
    input: {
      caretColor: colors.icon_color,
      "&.MuiTextField-root": {
        marginBottom: 30,
      },
      "& .MuiFormHelperText-root": {
        position: "absolute",
        bottom: "-20px",
      },
    },
    input_text: {
      borderColor: colors.theme_bg,
      padding: 10,
      borderRadius: 5,
    },
    footer_section: {
      width: "100%",
      alignItems: "center",
    },
    login_content: {
      width: "100%",
      margin: 5,
      alignItems: "center",
    },
    login_string: {
      color: colors.theme_fg,
    },
    btn_style: {
      backgroundColor: colors.theme_bg,
    },
    bottom_section: {
      [theme.breakpoints.down("md")]: {
        margin: "0 20px",
      },
    },
    card: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "500px",
      margin: "-100px auto 0",
      background: colors.theme_fg,
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "5px",
    },
    email: {
      borderColor: colors.theme_bg,

      padding: 10,
      borderRadius: 5,
      height: 40,
    },
    forgot_password_container: {
      width: "100%",
      textAlign: "end",
      fontSize: 14,
      marginTop: 25,
      marginBottom: 25,
      color: colors.description_text_color,
      cursor: "pointer",
    },
    signup_container: {
      display: "flex",
      justifyContent: "center",
      marginTop: 30,
      paddingBottom: 30,
      color: colors.top_red_section,
      cursor: "pointer",
    },
    error_test: {
      alignSelf: "center",
    },
    verification: {
      marginTop: "50px",
      textAlign: "center",
      fontWeight: "bold",
      marginBottom: "10px",
    },
  };
});
