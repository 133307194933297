/** @format */

import * as ActionTypes from "./ActionTypes";

export const searchCustomerError = (error) => ({
  type: ActionTypes.SEARCH_CUSTOMER_ERROR,
  error: error,
});

export const searchCustomerSuccess = (data) => ({
  type: ActionTypes.SEARCH_CUSTOMER_SUCCESS,
  data,
});

export const searchProductError = (error) => ({
  type: ActionTypes.SEARCH_PRODUCT_ERROR,
  error: error,
});

export const contactUsError = (error) => ({
  type: ActionTypes.CONTACT_US_ERROR,
  error,
});

export const contactUsPending = (error) => ({
  type: ActionTypes.CONTACT_US_PENDING,
  error,
});

export const searchProductSuccess = (data) => ({
  type: ActionTypes.SEARCH_PRODUCT_SUCCESS,
  data,
});

export const searchProductPending = () => ({
  type: ActionTypes.SEARCH_PRODUCT_PENDING,
});

export const resetSearchedCustomer = () => ({
  type: ActionTypes.RESET_SEARCHED_CUSTOMER,
});
