/** @format */

//Settings Service list api
export const SETTING_SERVICE_PENDING = "SETTING_SERVICE_PENDING";
export const SETTING_SERVICE_ERROR = "SETTING_SERVICE_ERROR";
export const SETTING_SERVICE_SUCCESS = "SETTING_SERVICE_SUCCESS";

//Home Service list api
export const HOME_SERVICE_PENDING = "HOME_SERVICE_PENDING";
export const HOME_SERVICE_ERROR = "HOME_SERVICE_ERROR";
export const HOME_SERVICE_SUCCESS = "HOME_SERVICE_SUCCESS";

export const HOME_ACTIVE_ORDERS_PENDING = "HOME_ACTIVE_ORDERS_PENDING";
export const HOME_ACTIVE_ORDERS_ERROR = "HOME_ACTIVE_ORDERS_ERROR";
export const HOME_ACTIVE_ORDERS_SUCCESS = "HOME_ACTIVE_ORDERS_SUCCESS";
export const PARTNER_COMPLETE_ORDERS = "PARTNER_COMPLETE_ORDERS";
export const PARTNER_NEW_ORDERS = "PARTNER_NEW_ORDERS";
export const PARTNER_PENDING_ORDERS = "PARTNER_PENDING_ORDERS";

export const CLEAR_ALL_ORDERS = "CLEAR_ALL_ORDERS";

export const ORDER_COUNT_ERROR = "ORDER_COUNT_ERROR";
export const ORDER_COUNT_SUCCESS = "ORDER_COUNT_SUCCESS";

export const ACTIVE_ORDERS_PENDING = "ACTIVE_ORDERS_PENDING";
export const ACTIVE_ORDERS_ERROR = "ACTIVE_ORDERS_ERROR";
export const ACTIVE_ORDERS_SUCCESS = "ACTIVE_ORDERS_SUCCESS";

export const MAIN_PARENT_ID = "MAIN_PARENT_ID";
export const GET_ORDER_STATUS_LIST_SUCCESS = "GET_ORDER_STATUS_LIST_SUCCESS";
export const GET_ORDER_STATUS_LIST_ERROR = "GET_ORDER_STATUS_LIST_ERROR";

//Home Payment list api
export const PAYMENT_LIST_PENDING = "PAYMENT_LIST_PENDING";
export const PAYMENT_LIST_ERROR = "PAYMENT_LIST_ERROR";
export const PAYMENT_LIST_SUCCESS = "PAYMENT_LIST_SUCCESS";

export const STRIPE_PENDING = "STRIPE_PENDING";
export const STRIPE_ERROR = "STRIPE_ERROR";
export const STRIPE_SUCCESS = "STRIPE_SUCCESS";

//Faq list api
export const FAQ_LIST_PENDING = "FAQ_LIST_PENDING";
export const FAQ_LIST_ERROR = "FAQ_LIST_ERROR";
export const FAQ_LIST_SUCCESS = "FAQ_LIST_SUCCESS";

//Product list api
export const PRODUCT_LIST_PENDING = "PRODUCT_LIST_PENDING";
export const PRODUCT_LIST_ERROR = "PRODUCT_LIST_ERROR";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const ADD_TO_CART = "ADD_TO_CART";
export const PRODUCT_RESET = "PRODUCT_RESET";
export const PRODUCT_LIST_RESET = "PRODUCT_LIST_RESET";
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS";
export const GET_CATEGORY_LIST_ERROR = "GET_CATEGORY_LIST_ERROR";
export const SET_ON_CHANGE_TAB = "SET_ON_CHANGE_TAB";
export const SELECTED_PRODUCT_LIST = "SELECTED_PRODUCT_LIST";
export const SELECTED_ADD_ON_PRODUCT_LIST = "SELECTED_ADD_ON_PRODUCT_LIST";
export const IS_PRODUCT_IMAGE_LOADING = "IS_PRODUCT_IMAGE_LOADING";
export const GET_CATEGORY_ID = "GET_CATEGORY_ID";
export const EMPTY_PRODUCT_LIST = "EMPTY_PRODUCT_LIST";

//Register api
export const REGISTER_PENDING = "REGISTER_PENDING";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const RESET_SIGNUP_VALUE = "RESET_SIGNUP_VALUE";

//Register api
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const RESET_LOGIN_VALUE = "RESET_LOGIN_VALUE";

export const TOGGLE_WORKING_DAY = "TOGGLE_WORKING_DAY";
export const SET_OPENING_HOURS = "SET_OPENING_HOURS";
export const SET_CLOSING_HOURS = "SET_CLOSING_HOURS";
export const CLEAR_WORKING_HOURS = "CLEAR_WORKING_HOURS";
export const EDIT_WORKING_DAY = "EDIT_WORKING_DAY";
export const EDIT_OPENING_HOURS = "EDIT_OPENING_HOURS";
export const EDIT_CLOSING_HOURS = "EDIT_CLOSING_HOURS";

//Address api
export const CREATE_ADDRESS_PENDING = "CREATE_ADDRESS_PENDING";
export const CREATE_ADDRESS_ERROR = "CREATE_ADDRESS_ERROR";
export const CREATE_ADDRESS_SUCCESS = "CREATE_ADDRESS_SUCCESS";
export const GET_ADDRESS_VALUES = "GET_ADDRESS_VALUES";
export const GET_ADDRESS_ID = "GET_ADDRESS_ID";
export const GET_ADDRESS_ID_SUCCESS = "GET_ADDRESS_ID_SUCCESS";
export const GET_ADDRESS_ID_ERROR = "GET_ADDRESS_ID_ERROR";
export const SHOW_ADDITIONALDETAILS = "SHOW_ADDITIONALDETAILS";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const GET_BILLING_ADDRESS = "GET_BILLING_ADDRESS";
export const GET_BILLING_ADDRESS_SUCCESS = "GET_BILLING_ADDRESS_SUCCESS";
export const GET_BILLING_ADDRESS_ERROR = "GET_BILLING_ADDRESS_ERROR";
export const CLEAR_ADDRESS_VALUE = "CLEAR_ADDRESS_VALUE";

export const EDIT_ADDRESS_PENDING = "EDIT_ADDRESS_PENDING";
export const EDIT_ADDRESS_ERROR = "EDIT_ADDRESS_ERROR";
export const EDIT_ADDRESS_SUCCESS = "EDIT_ADDRESS_SUCCESS";

export const UPDATE_ADDRESS_PENDING = "UPDATE_ADDRESS_PENDING";
export const UPDATE_ADDRESS_ERROR = "UPDATE_ADDRESS_ERROR";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";

//Address list api
export const ADDRESS_LIST_PENDING = "ADDRESS_LIST_PENDING";
export const ADDRESS_LIST_ERROR = "ADDRESS_LIST_ERROR";
export const ADDRESS_LIST_SUCCESS = "ADDRESS_LIST_SUCCESS";

export const ADDRESS_DELETE_PENDING = "ADDRESS_LIST_PENDING";
export const ADDRESS_DELETE_ERROR = "ADDRESS_LIST_ERROR";
export const ADDRESS_DELETE_SUCCESS = "ADDRESS_LIST_SUCCESS";
export const GET_CUSTOMER_SERVICE_ADDRESS_ID =
  "GET_CUSTOMER_SERVICE_ADDRESS_ID";
export const LOCATION_PERMISSION_DENIED = "LOCATION_PERMISSION_DENIED";
export const GET_NEAR_BY_SERVICE_ADDRESS = "GET_NEAR_BY_SERVICE_ADDRESS";
export const GET_NEAR_BY_SERVICE_ADDRESS_SUCCESS =
  "GET_NEAR_BY_SERVICE_ADDRESS_SUCCESS";
export const GET_NEAR_BY_SERVICE_ADDRESS_ERROR =
  "GET_NEAR_BY_SERVICE_ADDRESS_ERROR";

//Cart api
export const SUB_TOTAL = "SUB_TOTAL";
export const DELIVERY_COST = "DELIVERY_COST";
export const TOTAL = "TOTAL";
export const PROMO = "PROMO";
export const SET_SELECTED_CARD = "SET_SELECTED_CARD";
export const CALCULATE_PRICING = "CALCULATE_PRICING";
export const SELECT_ADDRESS = "SELECT_ADDRESS";
export const SELECT_DATE = "SELECT_DATE";
export const RESET = "RESET";
export const SELECT_PICKUP_DATE = "SELECT_PICKUP_DATE";
export const SELECT_PICKUP_TIME = "SELECT_PICKUP_TIME";
export const SELECT_DELIVERY_DATE = "SELECT_DELIVERY_DATE";
export const SELECT_DELIVERY_TIME = "SELECT_DELIVERY_TIME";
export const TOTAL_ITEM = "TOTAL_ITEM";
export const GET_CART_FORM_VALUE = "GET_CART_FORM_VALUE";
export const GET_CART_ORDER_INPUT = "GET_CART_ORDER_INPUT";
export const GET_CART_PROMO_CODE_NULL = "GET_CART_PROMO_CODE_NULL";
export const TOGGLE_DATE = "TOGGLE_DATE";
export const GET_DATE = "GET_DATE";
export const GET_CARD_PRODUCT_LIST = "GET_CARD_PRODUCT_LIST";
export const GET_CARD_PRODUCT_LIST_SUCCESS = "GET_CARD_PRODUCT_LIST_SUCCESS";
export const GET_CARD_PRODUCT_LIST_ERROR = "GET_CARD_PRODUCT_LIST_ERROR";
export const GET_APP_CONFIG = "GET_APP_CONFIG";
export const GET_APP_CONFIG_SUCCESS = "GET_APP_CONFIG_SUCCESS";
export const GET_APP_CONFIG_ERROR = "GET_APP_CONFIG_ERROR";
export const GET_EXTRA_FEE = "GET_EXTRA_FEE";
export const GET_ADD_ON_SERVICE = "GET_ADD_ON_SERVICE";
export const GET_ADD_ON_SERVICE_SUCCESS = "GET_ADD_ON_SERVICE_SUCCESS";
export const GET_ADD_ON_SERVICE_ERROR = "GET_ADD_ON_SERVICE_ERROR";
export const GET_TOTAL_AMOUNT = "GET_TOTAL_AMOUNT";
export const CHECK_VALID_PROMO_CODE = "CHECK_VALID_PROMO_CODE";
export const CHECK_VALID_PROMO_CODE_SUCCESS = "CHECK_VALID_PROMO_CODE_SUCCESS";
export const CHECK_VALID_PROMO_CODE_ERROR = "CHECK_VALID_PROMO_CODE_ERROR";
export const DELETE_CART = "DELETE_CART";
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS";
export const DELETE_CART_ERROR = "DELETE_CART_ERROR";
export const TOGGLE_CART_MODAL = "TOGGLE_CART_MODAL";
export const RESET_CART_AMOUNT = "RESET_CART_AMOUNT";

//MyOrders api
export const MYORDERS_LIST_PENDING = "MYORDERS_LIST_PENDING";
export const MYORDERS_LIST_ERROR = "MYORDERS_LIST_ERROR";
export const MYORDERS_LIST_SUCCESS = "MYORDERS_LIST_SUCCESS";
export const FILTER_TYPE = "FILTER_TYPE";
export const GET_ORDER_ID = "GET_ORDER_ID";
export const GET_ORDER_ITEMS = "GET_ORDER_ITEMS";
export const GET_ORDER_ITEMS_SUCCESS = "GET_ORDER_ITEMS_SUCCESS";
export const GET_ORDER_ITEMS_ERROR = "GET_ORDER_ITEMS_ERROR";
export const GET_ORDER_STATUS = "GET_ORDER_STATUS";
export const GET_ORDER_STATUS_SUCCESS = "GET_ORDER_STATUS_SUCCESS";
export const GET_ORDER_STATUS_ERROR = "GET_ORDER_STATUS_ERROR";
export const ORDER_TAB_PAGE = "ORDER_TAB_PAGE";
export const NOTIFICATION_ORDER_ID = "NOTIFICATION_ORDER_ID";

//Promo Code api
export const PROMO_LIST_PENDING = "PROMO_LIST_PENDING";
export const PROMO_LIST_ERROR = "PROMO_LIST_ERROR";
export const PROMO_LIST_SUCCESS = "PROMO_LIST_SUCCESS";

//Profile api
export const EDIT_PROFILE_PENDING = "EDIT_PROFILE_PENDING";
export const EDIT_PROFILE_ERROR = "EDIT_PROFILE_ERROR";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";

export const UPDATE_PROFILE_PENDING = "UPDATE_PROFILE_PENDING";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";

export const UPDATE_PROFILE_PICTURE = "UPDATE_PROFILE_PICTURE";
export const UPDATE_PROFILE_PICTURE_SUCCESS = "UPDATE_PROFILE_PICTURE_SUCCESS";
export const UPDATE_PROFILE_PICTURE_ERROR = "UPDATE_PROFILE_PICTURE_ERROR";
export const ON_CHNAGE_PROFILE_INPUT = "ON_CHNAGE_PROFILE_INPUT";
export const PROFILE_ERROR_MESSAGE = "PROFILE_ERROR_MESSAGE";
export const TOGGLE_OPEN_EDIT_PROFILE = "TOGGLE_OPEN_EDIT_PROFILE";
export const RESET_PROFILE_DATA = "RESET_PROFILE_DATA";
//Forgot Service list api
export const FORGOT_SERVICE_PENDING = "FORGOT_SERVICE_PENDING";
export const FORGOT_SERVICE_ERROR = "FORGOT_SERVICE_ERROR";
export const FORGOT_SERVICE_SUCCESS = "FORGOT_SERVICE_SUCCESS";

//Otp Service list api
export const OTP_SERVICE_PENDING = "OTP_SERVICE_PENDING";
export const OTP_SERVICE_ERROR = "OTP_SERVICE_ERROR";
export const OTP_SERVICE_SUCCESS = "OTP_SERVICE_SUCCESS";

//Reset Service list api
export const RESET_SERVICE_PENDING = "RESET_SERVICE_PENDING";
export const RESET_SERVICE_ERROR = "RESET_SERVICE_ERROR";
export const RESET_SERVICE_SUCCESS = "RESET_SERVICE_SUCCESS";

//Payment Actions
export const GET_WALLET_AMOUNT = "GET_WALLET_AMOUNT";
export const GET_WALLET_AMOUNT_SUCCESS = "GET_WALLET_AMOUNT_SUCCESS";
export const GET_WALLET_AMOUNT_ERROR = "GET_WALLET_AMOUNT_ERROR";
export const GET_CARD_VALUES = "GET_CARD_VALUES";
export const CARD_VALUE_ERROR = "CARD_VALUE_ERROR";
export const RELAOD_WALLET = "RELAOD_WALLET";
export const RELAOD_WALLET_SUCCESS = "RELAOD_WALLET_SUCCESS";
export const RELAOD_WALLET_ERROR = "RELAOD_WALLET_ERROR";
export const GET_CARD_LIST = "GET_CARD_LIST";
export const GET_CARD_LIST_SUCCESS = "GET_CARD_LIST_SUCCESS";
export const GET_CARD_LIST_ERROR = "GET_CARD_LIST_ERROR";
export const SAVE_CARD = "SAVE_CARD";
export const SAVE_CARD_SUCCESS = "SAVE_CARD_SUCCESS";
export const SAVE_CARD_ERROR = "SAVE_CARD_ERROR";
export const TOGGLE_CARD_DELETE_MODAL = "TOGGLE_CARD_DELETE_MODAL";

//Privacy Policy Actions
export const PRIVACY_SERVICE_PENDING = "PRIVACY_SERVICE_PENDING";
export const PRIVACY_SERVICE_ERROR = "PRIVACY_SERVICE_ERROR";
export const PRIVACY_SERVICE_SUCCESS = "PRIVACY_SERVICE_SUCCESS";

// Get user data
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

// Common Action Constants
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const NOTES_TOGGLE_MODAL = "NOTES_TOGGLE_MODAL";
export const IS_INPUT_TRUE = "IS_INPUT_TRUE";
export const IS_INPUT_FALSE = "IS_INPUT_FALSE";
export const CUSTOMER_NOTES_DATA = "CUSTOMER_NOTES_DATA";

export const IS_AUTH = "IS_AUTH";
export const IS_AUTH_SUCCESS = "IS_AUTH_SUCCESS";
export const IS_AUTH_ERROR = "IS_AUTH_ERROR";
export const INIT_SCREEN_NAME = "INIT_SCREEN_NAME";

export const GET_SERVICE_BY_PARENT_ID = "GET_SERVICE_BY_PARENT_ID";
export const GET_SERVICE_BY_PARENT_ID_SUCCESS =
  "GET_SERVICE_BY_PARENT_ID_SUCCESS";
export const GET_SERVICE_BY_PARENT_ID_ERROR = "GET_SERVICE_BY_PARENT_ID_ERROR";
export const GET_SERVICE_ID = "GET_SERVICE_ID";
export const GET_PARENT_SERVICE_DATA = "GET_PARENT_SERVICE_DATA";
export const TOGGLE_CART_DELETE_MODAL = "TOGGLE_CART_DELETE_MODAL";
export const RESER_SERVICES = "RESER_SERVICES";
export const PRODUCT_SERVICE_ID = "PRODUCT_SERVICE_ID";

// Checkout
export const GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD";
export const GET_PAYMENT_METHOD_SUCCESS = "GET_PAYMENT_METHOD_SUCCESS";
export const GET_PAYMENT_METHOD_ERROR = "GET_PAYMENT_METHOD_ERROR";
export const SET_PAYMENT_MODAL_DETAILS = "SET_PAYMENT_MODAL_DETAILS";
export const TOGGLE_PAYMENT_MODAL = "TOGGLE_PAYMENT_MODAL";
export const GET_CONFIRM_PAYMENT = "GET_CONFIRM_PAYMENT";
export const SUBMIT_ORDER = "SUBMIT_ORDER";
export const SUBMIT_ORDER_SUCCESS = "SUBMIT_ORDER_SUCCESS";
export const SUBMIT_ORDER_ERROR = "SUBMIT_ORDER_ERROR";
export const GET_CARD_DETAILS = "GET_CARD_DETAILS";
export const GET_STRIPE_TOKEN = "GET_STRIPE_TOKEN";
export const SET_CARD_ERROR_MESSAGE = "SET_CARD_ERROR_MESSAGE";
export const GET_CUSTOMER_WALLET_AMOUNT = "GET_CUSTOMER_WALLET_AMOUNT";
export const GET_CUSTOMER_WALLET_AMOUNT_SUCCESS =
  "GET_CUSTOMER_WALLET_AMOUNT_SUCCESS";
export const GET_CUSTOMER_WALLET_AMOUNT_ERROR =
  "GET_CUSTOMER_WALLET_AMOUNT_ERROR";
export const GET_LAST_SCREEN = "GET_LAST_SCREEN";
export const NO_DEFAULT_BILLING_ADDRESS = "NO_DEFAULT_BILLING_ADDRESS";
// commom
export const GET_BANNER_IMAGES = "GET_BANNER_IMAGES";
export const GET_BANNER_IMAGES_SUCCESS = "GET_BANNER_IMAGES_SUCCESS";
export const GET_BANNER_IMAGES_ERROR = "GET_BANNER_IMAGES_ERROR";
export const SET_DROPDOWN_OPEN = "SET_DROPDOWN_OPEN";
export const SET_DROPDOWN_VALUE = "SET_DROPDOWN_VALUE";
export const GET_FORM_COPY = "GET_FORM_COPY";
export const GET_FORM_COPY_SUCCESS = "GET_FORM_COPY_SUCCESS";
export const GET_FORM_COPY_ERROR = "GET_FORM_COPY_ERROR";
export const GET_FORM_COPY_BY_NAME = "GET_FORM_COPY_BY_NAME";
export const GET_FORM_COPY_BY_NAME_SUCCESS = "GET_FORM_COPY_BY_NAME_SUCCESS";
export const GET_FORM_COPY_BY_NAME_ERROR = "GET_FORM_COPY_BY_NAME_ERROR";

export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY";
export const GET_PRIVACY_POLICY_SUCCESS = "GET_PRIVACY_POLICY_SUCCESS";
export const GET_PRIVACY_POLICY_ERROR = "GET_PRIVACY_POLICY_ERROR";

export const GET_TERMS_CONDITION = "GET_TERMS_CONDITION";
export const GET_TERMS_CONDITION_SUCCESS = "GET_TERMS_CONDITION_SUCCESS";
export const GET_TERMS_CONDITION_ERROR = "GET_TERMS_CONDITION_ERROR";

export const IS_FROM_NOTIFICATION = "IS_FROM_NOTIFICATION";
export const GO_TO_ORDER_TAB = "GO_TO_ORDER_TAB";

// deals
export const GET_DEALS = "GET_DEALS";
export const GET_DEALS_SUCCESS = "GET_DEALS_SUCCESS";
export const GET_DEALS_ERROR = "GET_DEALS_ERROR";

// request service area
export const REQUEST_SERVICE_AREA = "REQUEST_SERVICE_AREA";
export const REQUEST_SERVICE_AREA_SUCCESS = "REQUEST_SERVICE_AREA_SUCCESS";
export const REQUEST_SERVICE_AREA_ERROR = "REQUEST_SERVICE_AREA_ERROR";
export const ON_PRESS_REQUEST = "ON_PRESS_REQUEST";
export const GET_REQUEST_FORM_DATA = "GET_REQUEST_FORM_DATA";
export const GET_REQUEST_FORM_ERROR = "GET_REQUEST_FORM_ERROR";
export const RESET_SERVICE_REQUEST = "RESET_SERVICE_REQUEST";
export const GET_SERVICE_AREA = "GET_SERVICE_AREA";
export const GET_SERVICE_AREA_SUCCESS = "GET_SERVICE_AREA_SUCCESS";
export const GET_SERVICE_AREA_ERROR = "GET_SERVICE_AREA_ERROR";

// become a partner
export const BECOME_PARTNER_FORM_VALUE = "BECOME_PARTNER_FORM_VALUE";
export const BECOME_PARTNER_FORM_ERROR = "BECOME_PARTNER_FORM_ERROR";
export const ON_SUBMIT_BECOME_PARTNER_REQUEST =
  "ON_SUBMIT_BECOME_PARTNER_REQUEST";
export const ON_SUBMIT_BECOME_PARTNER_REQUEST_SUCCESS =
  "ON_SUBMIT_BECOME_PARTNER_REQUEST_SUCCESS";
export const ON_SUBMIT_BECOME_PARTNER_REQUEST_ERROR =
  "ON_SUBMIT_BECOME_PARTNER_REQUEST_ERROR";
export const CLEAR_PARTNER_FORM = "CLEAR_PARTNER_FORM";
// reset password
export const RESET_PASSWORD_VALUE = "RESET_PASSWORD_VALUE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS";
export const RESET_PASSWORD_REQUEST_ERROR = "RESET_PASSWORD_REQUEST_ERROR";
// delete account
export const DELETE_ACCOUNT_VALUE = "DELETE_ACCOUNT_VALUE";
export const DELETE_ACCOUNT_ERROR = "DELETE_ACCOUNT_ERROR";
export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_REQUEST_SUCCESS = "DELETE_ACCOUNT_REQUEST_SUCCESS";
export const DELETE_ACCOUNT_REQUEST_ERROR = "DELETE_ACCOUNT_REQUEST_ERROR";

// feature request
export const ON_SUBMIT_FEATURE_REQUEST = "ON_SUBMIT_FEATURE_REQUEST";
export const ON_SUBMIT_FEATURE_REQUEST_SUCCESS =
  "ON_SUBMIT_FEATURE_REQUEST_SUCCESS";
export const ON_SUBMIT_FEATURE_REQUEST_ERROR =
  "ON_SUBMIT_FEATURE_REQUEST_ERROR";
export const FEATURE_REQUEST_FORM_VALUE = "FEATURE_REQUEST_FORM_VALUE";
export const CLEAR_FEATURE_REQUEST_FORM_VALUE =
  "CLEAR_FEATURE_REQUEST_FORM_VALUE";
export const FEATURE_REQUEST_FORM_ERROR = "FEATURE_REQUEST_FORM_ERROR";
export const CLEAR_FEATURE_REQUEST_VALUE = "CLEAR_FEATURE_REQUEST_VALUE";

//share order request
export const SHARE_ORDER_PENDING = "SHARE_ORDER_PENDING";
export const SHARE_ORDER_ERROR = "SHARE_ORDER_ERROR";
export const SHARE_ORDER_SUCCESS = "SHARE_ORDER_SUCCESS";

//edit order request
export const EDIT_ORDER_PENDING = "EDIT_ORDER_PENDING";
export const EDIT_ORDER_ERROR = "EDIT_ORDER_ERROR";
export const EDIT_ORDER_SUCCESS = "EDIT_ORDER_SUCCESS";

// notifications
export const GET_NOTIFICATIONS_PENDING = "GET_NOTIFICATIONS_PENDING";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_ERROR";

// clear notification
export const CLEAR_NOTIFICATIONS_PENDING = "CLEAR_NOTIFICATIONS_PENDING";
export const CLEAR_NOTIFICATIONS_SUCCESS = "CLEAR_NOTIFICATIONS_SUCCESS";
export const CLEAR_NOTIFICATIONS_ERROR = "CLEAR_NOTIFICATIONS_ERROR";

// Load more
export const SET_LOAD_MORE = "SET_LOAD_MORE";
export const SET_LOAD_MORE_MESSAGES = "SET_LOAD_MORE_MESSAGES";

// update notification count
export const UPDATE_NOTIFICATION_COUNT_SUCCESS =
  "UPDATE_NOTIFICATION_COUNT_SUCCESS";
export const UPDATE_NOTIFICATION_COUNT_ERROR =
  "UPDATE_NOTIFICATION_COUNT_ERROR";

export const UPDATE_UNSEEN_MSG_COUNT_ACTIVE = "UPDATE_UNSEEN_MSG_COUNT_ACTIVE";
export const UPDATE_UNSEEN_MSG_COUNT_COMPLETED =
  "UPDATE_UNSEEN_MSG_COUNT_COMPLETED";
export const UPDATE_MSG_COUNT_ACTIVE = "UPDATE_MSG_COUNT_ACTIVE";
export const UPDATE_MSG_COUNT_COMPLETED = "UPDATE_MSG_COUNT_COMPLETED";

// send message
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";

// receive message
export const RECEIVE_MESSAGE_SUCCESS = "RECEIVE_MESSAGE_SUCCESS";
export const RECEIVE_MESSAGE_ERROR = "RECEIVE_MESSAGE_ERROR";

// get messages
export const GET_MESSAGE_BY_ORDER_ID_PENDING =
  "GET_MESSAGE_BY_ORDER_ID_PENDING";

export const GET_MESSAGE_BY_ORDER_ID_SUCCESS =
  "GET_MESSAGE_BY_ORDER_ID_SUCCESS";
export const GET_MESSAGE_BY_ORDER_ID_ERROR = "GET_MESSAGE_BY_ORDER_ID_ERROR";

// get customers
export const GET_CUSTOMERS_PENDING = "GET_CUSTOMERS_PENDING";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_ERROR = "GET_CUSTOMERS_ERROR";

// get notification count
export const GET_NOTIFICATION_COUNT_SUCCESS = "GET_NOTIFICATION_COUNT_SUCCESS";
export const GET_NOTIFICATION_COUNT_ERROR = "GET_NOTIFICATION_COUNT_ERROR";

// add new user
export const ADD_USER_PENDING = "ADD_USER_PENDING";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

export const PARTNER_DATA_PENDING = "PARTNER_DATA_PENDING";
export const PARTNER_DATA_SUCCESS = "PARTNER_DATA_SUCCESS";
export const PARTNER_DATA_ERROR = "PARTNER_DATA_ERROR";

export const PARTNER_INSERT_ORDER_PENDING = "PARTNER_INSERT_ORDER_PENDING";
export const PARTNER_INSERT_ORDER_SUCCESS = "PARTNER_INSERT_ORDER_SUCCESS";
export const PARTNER_INSERT_ORDER_ERROR = "PARTNER_INSERT_ORDER_ERROR";

export const CONTACT_US_ERROR = "CONTACT_US_ERROR";
export const CONTACT_US_PENDING = "CONTACT_US_PENDING";

export const ENABLE_DISABLE_EMAIL_ERROR = "ENABLE_DISABLE_EMAIL_ERROR";

export const SET_EDIT_ORDER = "SET_EDIT_ORDER";
export const RESET_EDIT_ORDER = "RESET_EDIT_ORDER";

// link customer
export const LINK_CUSTOMER_ERROR = "LINK_CUSTOMER_ERROR";

// search customer
export const SEARCH_CUSTOMER_SUCCESS = "SEARCH_CUSTOMER_SUCCESS";
export const SEARCH_CUSTOMER_ERROR = "SEARCH_CUSTOMER_ERROR";

// search product
export const SEARCH_PRODUCT_PENDING = "SEARCH_PRODUCT_PENDING";
export const SEARCH_PRODUCT_SUCCESS = "SEARCH_PRODUCT_SUCCESS";
export const SEARCH_PRODUCT_ERROR = "SEARCH_PRODUCT_ERROR";

export const GET_NOTIFICATIONS_ON_CLICK_PENDING =
  "GET_NOTIFICATIONS_ON_CLICK_PENDING";
export const GET_NOTIFICATIONS_ON_CLICK_SUCCESS =
  "GET_NOTIFICATIONS_ON_CLICK_SUCCESS";
export const GET_NOTIFICATIONS_ON_CLICK_ERROR =
  "GET_NOTIFICATIONS_ON_CLICK_ERROR";

export const SET_FROM_CUSTOMER = "SET_FROM_CUSTOMER";

// selected user
export const SET_SELECTED_USER = "SET_SELECTED_USER";

export const SET_IS_SEARCHED_FALSE = "SET_IS_SEARCHED_FALSE";

export const SET_SELECTED_ROW = "SET_SELECTED_ROW";
export const DISCARD_SELECTED_ROW = "DISCARD_SELECTED_ROW";

export const SET_SELECTED_CUSTOMER = "SET_SELECTED_CUSTOMER";
export const DISCARD_SELECTED_CUSTOMER = "DISCARD_SELECTED_CUSTOMER";

export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const RESET_SELECTED_TAB = "RESET_SELECTED_TAB";

export const SET_SELECTED_CUSTOMER_TAB = "SET_SELECTED_CUSTOMER_TAB";
export const RESET_SELECTED_CUSTOMER_TAB = "RESET_SELECTED_CUSTOMER_TAB";

// send Email
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_ERROR = "SEND_EMAIL_ERROR";
export const SEND_EMAIL_PENDING = "SEND_EMAIL_PENDING";

// send notification
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_ERROR = "SEND_NOTIFICATION_ERROR";
export const SEND_NOTIFICATION_PENDING = "SEND_NOTIFICATION_PENDING";

// selected product
export const SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT";

// delete product
export const DELETE_SELECTED_PRODUCT = "DELETE_SELECTED_PRODUCT";

//logout request
export const LOGOUT_API_CALL = "LOGOUT_API_CALL";
export const LOGOUT_API_SUCCESS = "LOGOUT_API_SUCCESS";
export const LOGOUT_API_ERROR = "LOGOUT_API_ERROR";

export const RESET_PASSWORD_ERRORS = "RESET_PASSWORD_ERRORS";

// forgot password
export const FORGOT_PASSWORD_PENDING = "FORGOT_PASSWORD_PENDING";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

// reset password
export const RESET_PASSWORD_PENDING = "RESET_PASSWORD_PENDING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

// update password
export const UPDATE_PASSWORD_PENDING = "UPDATE_PASSWORD_PENDING";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";

export const UPDATE_PRODUCT_SELECTION = "UPDATE_PRODUCT_SELECTION";

export const RESET_USER = "RESET_USER";

export const RESET_SEARCHED_CUSTOMER = "RESET_SEARCHED_CUSTOMER";

// validate customer
export const VALIDATE_CUSTOMER_PENDING = "VALIDATE_CUSTOMER_PENDING";
export const VALIDATE_CUSTOMER_SUCCESS = "VALIDATE_CUSTOMER_SUCCESS";
export const VALIDATE_CUSTOMER_ERROR = "VALIDATE_CUSTOMER_ERROR";

export const CLOSE_POPOVER = "CLOSE_POPOVER";

export const ADD_CUSTOMER_MODAL = "ADD_CUSTOMER_MODAL";

export const PARTNER_PROFILE_PENDING = "PARTNER_PROFILE_PENDING";
export const PARTNER_PROFILE_SUCCESS = "PARTNER_PROFILE_SUCCESS";
export const PARTNER_PROFILE_ERROR = "PARTNER_PROFILE_ERROR";

export const SET_TAB_VALUE = "SET_TAB_VALUE";

export const SET_SELECTED_CUSTOMER_DROP_OFF_DATE =
  "SET_SELECTED_CUSTOMER_DROP_OFF_DATE";

export const RESET_TAB_VALUE = "RESET_TAB_VALUE";

export const SET_SELECTED_ADDRESS = "SET_SELECTED_ADDRESS";

export const GET_ORDER_BY_ID_PENDING = "GET_ORDER_BY_ID_PENDING";
export const GET_ORDER_BY_ID_SUCCESS = "GET_ORDER_BY_ID_SUCCESS";
export const GET_ORDER_BY_ID_ERROR = "GET_ORDER_BY_ID_ERROR";

export const GET_CUSTOMER_ORDER_ERROR = "GET_CUSTOMER_ORDER_ERROR";
export const GET_CUSTOMER_ORDER_SUCCESS = "GET_CUSTOMER_ORDER_SUCCESS";

export const GET_ORDER_INVOICE_SUCCESS = "GET_ORDER_INVOICE_SUCCESS";
export const GET_ORDER_INVOICE_ERROR = "GET_ORDER_INVOICE_ERROR";

export const HANDLE_EDIT_ORDER = "HANDLE_EDIT_ORDER";

export const ADD_NEW_ROW = "ADD_NEW_ROW";

export const SET_QTY_ERROR = "SET_QTY_ERROR";

export const OPENED_ORDER = "OPENED_ORDER";

export const SET_ORDER_SUMMARY = "SET_ORDER_SUMMARY";

export const CHANGE_ORDER_STATUS_ERROR = "CHANGE_ORDER_STATUS_ERROR";
export const CHANGE_ORDER_STATUS_PENDING = "CHANGE_ORDER_STATUS_PENDING";
export const CHANGE_ORDER_STATUS_SUCCESS = "CHANGE_ORDER_STATUS_SUCCESS";

export const ORDER_STATUS_CHANGED = "ORDER_STATUS_CHANGED";
export const NEW_ORDER_CREATED = "NEW_ORDER_CREATED";

export const JOIN_ROOM = "JOIN_ROOM";

export const LEAVE_ROOM = "LEAVE_ROOM";
export const RESET_VALIDATION = "RESET_VALIDATION";

export const GET_PARTNER_USERS_PENDING = "GET_PARTNER_USERS_PENDING";
export const GET_PARTNER_USERS_SUCCESS = "GET_PARTNER_USERS_SUCCESS";
export const GET_PARTNER_USERS_ERROR = "GET_PARTNER_USERS_ERROR";

export const DELETE_PARTNER_USER_PENDING = "DELETE_PARTNER_USER_PENDING";
export const DELETE_PARTNER_USER_SUCCESS = "DELETE_PARTNER_USER_SUCCESS";
export const DELETE_PARTNER_USER_ERROR = "DELETE_PARTNER_USER_ERROR";

export const GET_PARTNER_ROLES_PENDING = "GET_PARTNER_ROLES_PENDING";
export const GET_PARTNER_ROLES_SUCCESS = "GET_PARTNER_ROLES_SUCCESS";
export const GET_PARTNER_ROLES_ERROR = "GET_PARTNER_ROLES_ERROR";

export const GET_PARTNER_PERMISSIONS_PENDING =
  "GET_PARTNER_PERMISSIONS_PENDING";
export const GET_PARTNER_PERMISSIONS_SUCCESS =
  "GET_PARTNER_PERMISSIONS_SUCCESS";
export const GET_PARTNER_PERMISSIONS_ERROR = "GET_PARTNER_PERMISSIONS_ERROR";

export const UPDATE_STATUS_HISTORY_PENDING = "UPDATE_STATUS_HISTORY_PENDING";
export const UPDATE_STATUS_HISTORY_SUCCESS = "UPDATE_STATUS_HISTORY_SUCCESS";
export const UPDATE_STATUS_HISTORY_ERROR = "UPDATE_STATUS_HISTORY_ERROR";

export const DELETE_STATUS_HISTORY_PENDING = "DELETE_STATUS_HISTORY_PENDING";
export const DELETE_STATUS_HISTORY_SUCCESS = "DELETE_STATUS_HISTORY_SUCCESS";
export const DELETE_STATUS_HISTORY_ERROR = "DELETE_STATUS_HISTORY_ERROR";

export const ADD_PARTNER_USER_PENDING = "ADD_PARTNER_USER_PENDING";
export const ADD_PARTNER_USER_SUCCESS = "ADD_PARTNER_USER_SUCCESS";
export const ADD_PARTNER_USER_ERROR = "ADD_PARTNER_USER_ERROR";
export const CLEAR_ADD_USER_ERROR = "CLEAR_ADD_USER_ERROR";

// Order Assignment

export const ORDER_ASSIGNMENT_PENDING = "ORDER_ASSIGNMENT_PENDING";
export const ORDER_ASSIGNMENT_SUCCESS = "ORDER_ASSIGNMENT_SUCCESS";
export const ORDER_ASSIGNMENT_ERROR = "ORDER_ASSIGNMENT_ERROR";

export const SET_LOCATION_COORDINATES = "SET_LOCATION_COORDINATES";
export const RESET_ADDRESS_ERROR = "RESET_ADDRESS_ERROR";

//card api

export const DISCARD_SELECTED_CARD = "DISCARD_SELECTED_CARD";
export const DISCARD_SELECTED_ADDRESS = "DISCARD_SELECTED_ADDRESS";
export const CARD_LIST_PENDING = "CARD_LIST_PENDING";
export const CARD_LIST_ERROR = "CARD_LIST_ERROR";
export const CARD_LIST_SUCCESS = "CARD_LIST_SUCCESS";
export const ADD_CARD_PENDING = "ADD_CARD_PENDING";
export const ADD_CARD_ERROR = "ADD_CARD_ERROR";
export const ADD_CARD_SUCCESS = "ADD_CARD_SUCCESS";

export const ADD_CARD_WITH_TOKEN_PENDING = "ADD_CARD_WITH_TOKEN_PENDING";
export const ADD_CARD_WITH_TOKEN_ERROR = "ADD_CARD_WITH_TOKEN_ERROR";
export const ADD_CARD_WITH_TOKEN_SUCCESS = "ADD_CARD_WITH_TOKEN_SUCCESS";

export const EDIT_CARD_PENDING = "EDIT_CARD_PENDING";
export const EDIT_CARD_ERROR = "EDIT_CARD_ERROR";
export const EDIT_CARD_SUCCESS = "EDIT_CARD_SUCCESS";
export const SET_SELECTED_PAYMENT_CARD = "SET_SELECTED_PAYMENT_CARD";
export const DISCARD_SELECTED_PAYMENT_CARD = "DISCARD_SELECTED_PAYMENT_CARD";
export const CLEAR_CARD_FORM = "CLEAR_CARD_FORM";

export const GET_WALLET_ERROR = "GET_WALLET_ERROR";
export const GET_WALLET_SUCCESS = "GET_WALLET_SUCCESS";
export const GET_WALLET_PENDING = "GET_WALLET_PENDING";

export const CARD_PAYMENT_ERROR = "CARD_PAYMENT_ERROR";
export const CARD_PAYMENT_SUCCESS = "CARD_PAYMENT_SUCCESS";
export const CARD_PAYMENT_PENDING = "CARD_PAYMENT_PENDING";
