/** @format */

import {
  enableDisableEmailError,
  isInputFalse,
} from "../../actions/CommonAction";
import {
  profileErrorMessage,
  updateProfilePictureError,
  updateProfilePictureSuccess,
  updateServiceActionError,
  updateServiceActionPending,
  updateServiceActionSuccess,
} from "../../actions/ProfileActions";
import {
  ADD_PARTNER_ADDRESS,
  ADD_PARTNER_USER,
  CREATE_ADDRESS_URL,
  DELETE_ADDRESS,
  DELETE_PARTNER_USER,
  GET_BILLING_ADDRESS_URL,
  GET_CUSTOMER_ADDRESS,
  GET_PARTNER_PERMISSIONS_URL,
  GET_PARTNER_PROFILE,
  GET_PARTNER_ROLES_URL,
  GET_PARTNER_USERS_URL,
  password_reset_fail,
  RESET_PASSWORD_URL,
  RESET_USER_PASSWORD_URL,
  temp_hours,
  UPDATE_ADDRESS_URL,
  UPDATE_CUSTOMER_SETTINGS,
  UPDATE_PARTNER,
  UPDATE_PARTNER_USER,
  UPLOAD_PROFILE_IMAGE,
  VERIFY_EMAIL,
} from "../../config/Constants";
import { getUser } from "../../config/Helper";
import {
  fetchDelete,
  fetchGet,
  fetchPatch,
  fetchPost,
} from "../../config/request";
import {
  onSubmitResetRequestError,
  onSubmitResetRequestSuccess,
  resetPasswordValueError,
} from "../../actions/ResetPasswordActions";
import {
  getAddressValue,
  getBillingAddress,
  getBillingAddressError,
  getBillingAddressSuccess,
  serviceActionPending,
  serviceActionError,
  serviceActionSuccess,
  editServiceActionPending,
  editServiceActionError,
  editServiceActionSuccess,
  partnerProfileSuccess,
  partnerProfileError,
  clearAddressValue,
} from "../../actions/AddressActions";
import {
  listServiceActionError,
  listServiceActionPending,
  listServiceActionSuccess,
} from "../../actions/AddressListActions";
import { getCustomerList } from "../customersHistory/services";
import {
  deletePartnerUsersError,
  deletePartnerUsersPending,
  deletePartnerUsersSuccess,
  getPartnerRolesError,
  getPartnerRolesPending,
  getPartnerRolesSuccess,
  getPartnerUsersError,
  getPartnerUsersPending,
  getPartnerUsersSuccess,
} from "../../actions/RolesAction";
import {
  getPartnerPermissionsError,
  getPartnerPermissionsPending,
  getPartnerPermissionsSuccess,
} from "../../actions/PermissionAction";
import {
  addPartnerUserError,
  addPartnerUserPending,
  addPartnerUserSuccess,
} from "../../actions/HomeActions";
import { toast } from "react-toastify";

export async function getSignedLink(dispatch, response, profileError) {
  try {
    const imageUri = await fetchPost(UPLOAD_PROFILE_IMAGE, {
      delivery_boy_id: JSON.parse(localStorage.getItem("user_data"))?.partner
        .id,
      profile_picture: response.url,
      filename: response.file.name,
      type: response.file.type,
    });
    if (imageUri.error) {
      dispatch(
        profileErrorMessage({
          ...profileError,
          api_error: imageUri.error,
        })
      );
      return dispatch(updateProfilePictureError());
    }
    await getUser(JSON.parse(localStorage.getItem("user_data"))?.id);
    dispatch(updateProfilePictureSuccess());
  } catch (e) {
    dispatch(
      profileErrorMessage({
        ...profileError,
        api_error: "some error",
      })
    );
    return e;
  }
}

export async function enableDisableEmail(dispatch, customer, is_email_active) {
  const emailNotification = await fetchPost(UPDATE_CUSTOMER_SETTINGS, {
    customer_id: customer.id,
    is_email_active,
  });
  if (emailNotification.error) {
    dispatch(enableDisableEmailError(emailNotification.error));
    return false;
  } else {
    getCustomerList(dispatch, 0, 100);
    return true;
  }
}

export const addNewPartnerUser = async (
  dispatch,
  permissions_list,
  role_id,
  state
) => {
  dispatch(addPartnerUserPending());
  const body = {
    name: state?.customer_name,
    email: state?.email,
    number: state?.phone_number?.toString().replace(/[- )(]/g, ""),
    partner_id: JSON.parse(localStorage.getItem("user_data"))?.partner?.id,
    role_id,
    permissions_list,
  };
  const partnerUser = await fetchPost(ADD_PARTNER_USER, body);
  if (partnerUser.error) {
    dispatch(addPartnerUserError(partnerUser.error));
    return false;
  }
  dispatch(addPartnerUserSuccess(partnerUser));
  return true;
};

export const updatePartnerUser = async (
  dispatch,
  permissions_list,
  role_id,
  state,
  selectedUser
) => {
  dispatch(addPartnerUserPending());
  const body = {
    name: state?.customer_name,
    email: state?.email,
    number: state?.phone_number?.toString().replace(/[- )(]/g, ""),
    partner_user_id: selectedUser?.id,
    role_id,
    permissions_list,
  };
  const partnerUser = await fetchPost(UPDATE_PARTNER_USER, body);
  if (partnerUser.error) {
    dispatch(addPartnerUserError(partnerUser.error));
    return false;
  }
  dispatch(addPartnerUserSuccess(partnerUser));
  return true;
};

export const getAllPartnerUsers = async (dispatch) => {
  dispatch(getPartnerUsersPending());
  const usersList = await fetchGet(
    `${GET_PARTNER_USERS_URL}?partner_id=${
      JSON.parse(localStorage.getItem("user_data"))?.partner?.id
    }`
  );

  const filteredUsers = usersList.data.filter((item) => {
    return item.id !== JSON.parse(localStorage.getItem("user_data"))?.id;
  });
  if (usersList.error) dispatch(getPartnerUsersError(usersList.error));
  dispatch(getPartnerUsersSuccess(filteredUsers));
};

export const getAllPartnerRoles = async (dispatch, offset, limit) => {
  dispatch(getPartnerRolesPending());
  const rolesList = await fetchGet(
    `${GET_PARTNER_ROLES_URL}?offset=${offset}&limit=${limit}`
  );
  if (rolesList.error) dispatch(getPartnerRolesError(rolesList.error));
  dispatch(getPartnerRolesSuccess(rolesList));
};

export const getAllPartnerPermissions = async (dispatch, offset, limit) => {
  dispatch(getPartnerPermissionsPending());
  const permissionList = await fetchGet(
    `${GET_PARTNER_PERMISSIONS_URL}?offset=${offset}&limit=${limit}`
  );
  if (permissionList.error)
    dispatch(getPartnerPermissionsError(permissionList.error));
  dispatch(getPartnerPermissionsSuccess(permissionList));
};

export async function updateProfile(
  dispatch,
  profileInput,
  profileError,
  profile_picture,
  type
) {
  let update;
  try {
    if (type === "org") {
      const body = {
        email: profileInput.org_email,
        phone_number: profileInput?.org_phone.replace(/\D+/g, ""),
        delivery_boy_name: profileInput.org_name,
      };
      dispatch(updateServiceActionPending());
      update = await fetchPatch(
        `${UPDATE_PARTNER}/${
          JSON.parse(localStorage.getItem("user_data"))?.partner?.id
        }`,
        body
      );
      const tempData = JSON.parse(localStorage.getItem("user_data"));
      tempData.partner = update.result;
      localStorage.setItem("user_data", JSON.stringify(tempData));
    } else {
      let partnerProfilePicture;
      if (profile_picture) {
        partnerProfilePicture = {
          fileName: profile_picture.file.name,
          type: profile_picture.file.type,
          base64: profile_picture.url,
        };
      }
      const body = {
        email: profileInput.email,
        number: profileInput?.phone_number.replace(/\D+/g, ""),
        name: profileInput?.customer_name,
        partner_user_id: JSON.parse(localStorage.getItem("user_data"))?.id,
        profile_picture: partnerProfilePicture,
      };
      dispatch(updateServiceActionPending());
      update = await fetchPost(UPDATE_PARTNER_USER, body);
      if (
        JSON.parse(localStorage.getItem("user_data"))?.email !=
        profileInput.email
      ) {
        toast.success(
          `A verification email has been sent to ${profileInput.email}. Please verify it first!`
        );
      }
    }

    if (update?.error) {
      dispatch(
        profileErrorMessage({
          ...profileError,
          api_error: update?.error,
        })
      );
      dispatch(updateServiceActionError(update?.error));
      return false;
    }
    const userData = update;
    userData.auth_token = JSON.parse(
      localStorage.getItem("user_data")
    ).auth_token;

    await getPartnerProfile(dispatch);
    dispatch(isInputFalse());
    dispatch(updateServiceActionSuccess());
    return true;
  } catch (e) {
    dispatch(
      profileErrorMessage({
        ...profileError,
        api_error: "some error",
      })
    );
    dispatch(updateServiceActionError(e));
    return false;
  }
}

export async function submitResetPassword(
  dispatch,
  formData,
  resetPasswordError
) {
  try {
    const body = {
      id: JSON.parse(localStorage.getItem("user_data")).id,
      currentPassword: formData.current_password,
      password: formData.confirm_password,
    };

    const reqResetPassword = await fetchPost(RESET_PASSWORD_URL, body);
    if (reqResetPassword.error) {
      dispatch(onSubmitResetRequestError(reqResetPassword.error));
      dispatch(
        resetPasswordValueError({
          ...resetPasswordError,
          api_error:
            reqResetPassword.error === "Invalid partner"
              ? password_reset_fail
              : reqResetPassword.error,
        })
      );
      return false;
    }
    dispatch(onSubmitResetRequestSuccess(reqResetPassword.message));
    return true;
  } catch (e) {
    dispatch(onSubmitResetRequestError(e));
    dispatch(
      resetPasswordValueError({
        ...resetPasswordError,
        api_error: "some error",
      })
    );
    return e;
  }
}

export async function resetUserPassword(dispatch, formData, selectedUser) {
  try {
    const body = {
      partner_user_id: selectedUser.id,
      new_password: formData.confirm_password,
    };

    const reqResetPassword = await fetchPost(RESET_USER_PASSWORD_URL, body);
    if (reqResetPassword.error) {
      dispatch(onSubmitResetRequestError(reqResetPassword.error));
      dispatch(
        resetPasswordValueError({
          api_error: reqResetPassword.error,
        })
      );
      return false;
    }
    dispatch(onSubmitResetRequestSuccess(reqResetPassword.message));
    return true;
  } catch (e) {
    dispatch(onSubmitResetRequestError(e));
    dispatch(
      resetPasswordValueError({
        api_error: "some error",
      })
    );
    return false;
  }
}

export async function getCustomerBillingAddress(dispatch, user_id) {
  try {
    dispatch(getBillingAddress());
    const response = await fetchGet(
      `${GET_BILLING_ADDRESS_URL}?customer_id=${user_id}`
    );
    if (response.error) dispatch(getBillingAddressError(response.error));
    else dispatch(getBillingAddressSuccess(response));
  } catch (e) {
    dispatch(getBillingAddressError(e));
    return { error: e };
  }
}

export async function getAddressList(
  dispatch,
  orderDetail,
  isFromCheckout = false
) {
  try {
    dispatch(listServiceActionPending());
    const addressList = await fetchGet(
      `${GET_CUSTOMER_ADDRESS}?customer_id=${orderDetail.customer.id}`
    );

    if (addressList.error) dispatch(listServiceActionError(addressList.error));
    else {
      if (isFromCheckout) {
        addressList[addressList.length - 1].isSelected = true;
        addressList[addressList.length - 1].isUpdated = true;
        dispatch(getAddressValue(addressList[addressList.length - 1]));
      }
      dispatch(listServiceActionSuccess(addressList));
    }
  } catch (e) {
    dispatch(listServiceActionError(e));
    return { error: e };
  }
}

export async function getPartnerProfile(dispatch) {
  try {
    const partnerProfile = await fetchGet(
      `${GET_PARTNER_PROFILE}?partner_id=${
        JSON.parse(localStorage.getItem("user_data"))?.partner?.id
      }`
    );
    if (partnerProfile.error) {
      dispatch(partnerProfileError(partnerProfile.error));
    } else {
      partnerProfile.address.map((address) => {
        if (address.serviceHours.length === 0) {
          address.serviceHours = temp_hours;
        }
      });
      dispatch(partnerProfileSuccess(partnerProfile));
    }
  } catch (e) {
    return dispatch(serviceActionError(e));
  }
}

export async function onSaveAddress(
  dispatch,
  addressValue,
  isBilling,
  customer,
  addCustomerAddress,
  customerDetails,
  addressState,
  state
) {
  try {
    dispatch(serviceActionPending());

    delete addressValue.is_default_billing;

    let body;
    let saveAddress;

    if (customer || addCustomerAddress) {
      body = {
        ...addressValue,
        address_name: addressValue.address_name || "",
        address_note: addressValue.address_note || "",
        customer_id: customer
          ? customer.id.toString()
          : customerDetails.id.toString(),
        is_default_billing: isBilling,
        line_2: addressValue.line_2 || "",
        created_by_partner_id: JSON.parse(localStorage.getItem("user_data"))
          .partner.id,
      };
      saveAddress = await fetchPost(CREATE_ADDRESS_URL, body);
    } else {
      let serviceHours = [];
      addressState.working_hours.forEach((item) => {
        const tempItem = {
          ...item,
          opening_hours:
            item.opening_hours != ""
              ? item.opening_hours.toISOString().split("T")[1].slice(0, 5)
              : "",
          closing_hours:
            item.closing_hours != ""
              ? item.closing_hours.toISOString().split("T")[1].slice(0, 5)
              : "",
        };
        serviceHours.push(tempItem);
      });

      body = {
        ...addressValue,
        address_name: addressValue.address_name || "",
        address_note: addressValue.address_note || "",
        partner_id: JSON.parse(localStorage.getItem("user_data")).partner.id,
        line_2: addressValue.line_2 || "",
        service_hours: serviceHours,
        pick_up_radius: state?.pick_up_radius,
        mobile_service_radius: state?.service_radius,
        mobile_cleaning_radius: state?.cleaning_radius,
        phone_number: state?.phone_number.toString().replace(/[- )(]/g, ""),
        terms_and_conditions: state?.terms_and_conditions,
      };

      saveAddress = await fetchPost(ADD_PARTNER_ADDRESS, body);
    }

    if (saveAddress.error) {
      dispatch(serviceActionError(saveAddress.error));
      return false;
    } else {
      dispatch(serviceActionSuccess(saveAddress));
      if (addCustomerAddress) {
        const customerAddressList = await fetchGet(
          `${GET_CUSTOMER_ADDRESS}?customer_id=${
            customerDetails
              ? customerDetails.id.toString()
              : customer.id.toString()
          }`
        );
        dispatch(listServiceActionSuccess(customerAddressList));
      } else {
        await getPartnerProfile(dispatch);
      }
      return true;
    }
  } catch (e) {
    dispatch(serviceActionError(e));
    return false;
  }
}

export async function submitVerifyEmail(dispatch, verificationCode) {
  const body = {
    verificationCode: verificationCode,
  };
  try {
    const reqResetPassword = await fetchPost(VERIFY_EMAIL, body);
    if (reqResetPassword.error) {
      dispatch(onSubmitResetRequestError(reqResetPassword.error));
      dispatch(
        resetPasswordValueError({
          api_error: reqResetPassword.error,
        })
      );
      return [false, reqResetPassword.error];
    }
    dispatch(onSubmitResetRequestSuccess(reqResetPassword.message));
    return [true, ""];
  } catch (e) {
    dispatch(onSubmitResetRequestError(e));
    dispatch(
      resetPasswordValueError({
        api_error: "some error",
      })
    );
    console.log(e, "error");
    return [false, e];
  }
}

export async function onUpdateAddress(dispatch, addressValue, fromEdit, state) {
  dispatch(editServiceActionPending());
  let update;
  if (fromEdit) {
    delete addressValue.address;
    update = await fetchPost(UPDATE_ADDRESS_URL, addressValue);
  } else {
    let service_hours = [];
    addressValue.serviceHours.forEach((item) => {
      const tempItem = {
        ...item,
        opening_hours:
          typeof item.opening_hours === "object"
            ? item.opening_hours.toISOString().split("T")[1].slice(0, 5)
            : item.opening_hours,
        closing_hours:
          typeof item.closing_hours === "object"
            ? item.closing_hours.toISOString().split("T")[1].slice(0, 5)
            : item.closing_hours,
      };

      delete tempItem.service_location_id;
      delete tempItem.created_at;
      delete tempItem.updated_at;

      service_hours.push(tempItem);
    });

    update = await fetchPatch(`${"serviceLocation"}`, {
      location_id: addressValue.address_id,
      address_name: addressValue.address_name || "",
      line_1: addressValue.line_1,
      line_2: addressValue.line_2 || "",
      state_long_name: addressValue.state_long_name || "",
      state_short_name: addressValue.state_short_name || "",
      city_long_name: addressValue.city_long_name || "",
      pin_code: addressValue.pin_code || "",
      address_note: addressValue.address_note || "",
      address: addressValue.address || "",
      latitude: addressValue.latitude || "",
      longitude: addressValue.longitude || "",
      city_short_name: addressValue.city_short_name || "",
      country_long_name: addressValue.country_long_name || "",
      country_short_name: addressValue.country_short_name || "",
      service_hours: service_hours,
      pick_up_radius: state?.pick_up_radius,
      mobile_service_radius: state?.service_radius,
      mobile_cleaning_radius: state?.cleaning_radius,
      phone_number: state?.phone_number.toString().replace(/[- )(]/g, ""),
      terms_and_conditions: state?.terms_and_conditions,
    });

    if (update.error) {
      dispatch(editServiceActionError(update.error));
      return false;
    }
  }
  dispatch(clearAddressValue());
  dispatch(editServiceActionSuccess(update.message));
  return true;
}

export async function onDeleteAddress(
  dispatch,
  selectedAddress,
  customerAddress
) {
  try {
    dispatch(updateServiceActionPending());
    let deleteAddress;
    if (customerAddress) {
      deleteAddress = await fetchPost(DELETE_ADDRESS, {
        address_id: selectedAddress.id.toString(),
      });
    } else {
      deleteAddress = await fetchDelete(
        `${"serviceLocation/"}${selectedAddress.id.toString()}`
      );
    }

    if (deleteAddress.error) {
      dispatch(updateServiceActionError(deleteAddress.error));
      return false;
    } else {
      if (customerAddress) {
        await getCustomerList(dispatch, 0, 100);
        toast.success("Customer Address Deleted Successfully");
      } else {
        await getPartnerProfile(dispatch);
        toast.success("Address Deleted Successfully");
      }
      dispatch(updateServiceActionSuccess(deleteAddress.message));
      return true;
    }
  } catch (e) {
    dispatch(updateServiceActionError(e));
  }
}

export async function onDeletePartnerUser(dispatch, selectedUser) {
  try {
    dispatch(deletePartnerUsersPending());
    const deletePartnerUser = await fetchDelete(
      `${DELETE_PARTNER_USER}/${selectedUser.id}`
    );
    if (deletePartnerUser.error) {
      dispatch(deletePartnerUsersError(deletePartnerUser.error));
      return false;
    } else {
      dispatch(deletePartnerUsersSuccess());
      return true;
    }
  } catch (e) {
    dispatch(updateServiceActionError(e));
  }
}
