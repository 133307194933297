/** @format */

import * as Actions from "../actions/ActionTypes";
const NewOrderReducer = (
  state = {
    isLoading: false,
    error: undefined,
    user: null,
    linked: false,
    isSearched: false,
    validationError: undefined,
    validated: false,
    fromCustomer: false,
    updateStatusHistoryLoading: false,
    updateStatusHistoryError: undefined,
    deleteStatusHistoryLoading: false,
    deleteStatusHistoryError: undefined,
  },
  action
) => {
  switch (action.type) {
    case Actions.ADD_USER_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        user: null,
      });
    case Actions.ADD_USER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        user: action.data,
        isSearched: false,
        error: undefined,
      });
    case Actions.SET_IS_SEARCHED_FALSE:
      return Object.assign({}, state, {
        isSearched: false,
      });
    case Actions.ADD_USER_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        user: null,
      });
    case Actions.LINK_CUSTOMER_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        linkError: action.error,
        user: null,
      });
    case Actions.SET_SELECTED_USER:
      return Object.assign({}, state, {
        user: action.data,
        linked: true,
        isSearched: true,
      });
    case Actions.SET_FROM_CUSTOMER:
      return Object.assign({}, state, {
        fromCustomer: action.data,
      });
    case Actions.RESET_USER:
      return Object.assign({}, state, {
        user: null,
        linked: false,
        error: undefined,
      });
    case Actions.VALIDATE_CUSTOMER_SUCCESS:
      return Object.assign({}, state, {
        user: action.data.customer,
        linked: action.data.linked,
        validated: true,
      });
    case Actions.VALIDATE_CUSTOMER_ERROR:
      return Object.assign({}, state, {
        validationError: action.error,
        validated: false,
      });
    case Actions.VALIDATE_CUSTOMER_PENDING:
      return Object.assign({}, state, {
        validationError: undefined,
        user: undefined,
        validated: false,
      });
    case Actions.UPDATE_STATUS_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        updateStatusHistoryLoading: false,
      });
    case Actions.UPDATE_STATUS_HISTORY_ERROR:
      return Object.assign({}, state, {
        updateStatusHistoryLoading: false,
        updateStatusHistoryError: action.error,
      });
    case Actions.UPDATE_STATUS_HISTORY_PENDING:
      return Object.assign({}, state, {
        updateStatusHistoryLoading: true,
        updateStatusHistoryError: undefined,
      });
    case Actions.DELETE_STATUS_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        deleteStatusHistoryLoading: false,
      });
    case Actions.DELETE_STATUS_HISTORY_ERROR:
      return Object.assign({}, state, {
        deleteStatusHistoryLoading: false,
        deleteStatusHistoryError: action.error,
      });
    case Actions.DELETE_STATUS_HISTORY_PENDING:
      return Object.assign({}, state, {
        deleteStatusHistoryLoading: true,
        deleteStatusHistoryError: undefined,
      });
    case Actions.RESET_VALIDATION:
      return Object.assign({}, state, {
        validated: false,
      });
    default:
      return state;
  }
};

export default NewOrderReducer;
