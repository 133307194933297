/** @format */

import * as Actions from "../actions/ActionTypes";
const LoginReducer = (
  state = {
    isLoading: false,
    error: undefined,
    status: undefined,
  },
  action
) => {
  switch (action.type) {
    case Actions.LOGIN_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        error: undefined,
      });
    case Actions.LOGIN_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case Actions.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        status: "success",
      });
    default:
      return state;
  }
};

export default LoginReducer;
