/** @format */

import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import * as colors from "../../assets/css/Colors";
import useStyles from "../../GlobalStyle";

import { checkout, semiBold, total } from "../../config/Constants";
import { DPFContext } from "../../context";
import { formatter } from "../../config/Helper";
import { resetTabValue, setTabValue } from "../../actions/HomeActions";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import strings from "../../languages/strings";
import { walletPayment } from "./services";
import { CommonError, Loader } from "../GeneralComponents";
import {
  discardSelectedRow,
  resetSelectedTab,
  setSelectedRow,
  setSelectedTab,
} from "../../actions/CartActions";
import { setEditOrder } from "../../actions/ProfileActions";
import { useNavigate } from "react-router-dom";

const WalletPayment = ({ paymentAmount, handleClose }) => {
  const styles = localStyles();
  const GlobalStyle = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const order = useSelector((state) => state.myOrders.orderItems);
  const { orderDetail, walletError } = useSelector((state) => state.myOrders);

  const { walletAmount, isLoading } = useSelector((state) => state.checkout);

  const context = useContext(DPFContext);

  const handleBackClick = () => {
    context.setOrderDetails(null);
    context.setPaymentType(null);
    dispatch(discardSelectedRow());
    dispatch(resetSelectedTab());
    dispatch(resetTabValue());
  };

  const walletPaymentMethod = async () => {
    const response = await walletPayment(
      dispatch,
      orderDetail?.order_id,
      Number(paymentAmount)
    );
    if (response) {
      handleClose();
      dispatch(setSelectedRow(orderDetail.order_id));
      dispatch(setSelectedTab("detail"));
      dispatch(setTabValue(1));
      dispatch(setEditOrder(true));
      context.setOrderDetails(orderDetail.order_id);
      navigate(`/edit-order/${orderDetail?.order_id}`);
      window.location.reload();
    }
  };

  return (
    <>
      <div className={styles.checkoutTab}>{checkout}</div>
      <div className={styles.detailsHead1}>
        <ArrowBackIcon
          className={styles.icon}
          sx={{ color: colors.icon_color }}
          fontSize={"large"}
          onClick={handleBackClick}
        />
        <span className={styles.title1}>
          {strings.order_no}: {order?.customer_order_id}
        </span>
      </div>
      <div className={styles.container}>
        <span className={styles.text}>
          {context?.paymentType?.details?.payment_mode}
        </span>
        <div className={styles.balanceContainer}>
          <p className={styles.head}>
            {context?.paymentType?.details?.description}
            {formatter.format(walletAmount)}
          </p>
        </div>
        <hr className={GlobalStyle.divider1} />
        <div className={styles.infoBlock}>
          <span className={styles.notesText1}>{total}</span>
          <span
            className={styles.notesText1}
            style={{ color: colors.icon_color }}>
            ${Number(paymentAmount).toFixed(2)}
          </span>
        </div>
        {walletError && <CommonError type='textError' error={walletError} />}
        {isLoading && <Loader />}
        <div className={styles.orderImageBlock}>
          <button
            className={styles.payButton}
            disabled={walletAmount < Number(paymentAmount)}
            style={
              walletAmount < Number(paymentAmount)
                ? { backgroundColor: "grey", cursor: "default" }
                : {}
            }
            onClick={() => walletPaymentMethod()}>
            Submit
          </button>
        </div>
        <div className={styles.orderImageBlock}>
          <button
            className={styles.payButton}
            style={{ backgroundColor: "grey" }}
            onClick={() => context.setPaymentType(null)}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

const localStyles = makeStyles((theme) => {
  return {
    container: {
      background: colors.theme_fg,
      gridColumnStart: 3,
      gridRowStart: 1,
      gridRowEnd: 3,
      borderRadius: "5px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflow: "auto",
      width: "90%",
      "&::-webkit-scrollbar": {
        width: "0px",
      },
    },
    balanceContainer: {
      backgroundColor: "black",
      padding: 20,
      borderRadius: 10,
      marginBottom: 10,
    },
    icon: {
      cursor: "pointer",
    },
    notesText1: {
      fontSize: "24px",
      lineHeight: "27px",
      margin: 0,
      fontWeight: "bold",
    },
    infoBlock: {
      display: "flex",
      justifyContent: "space-between",
      width: "55%",
    },
    title1: {
      fontWeight: semiBold,
      fontSize: "24px",
      lineHeight: "35px",
      display: "table",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
        lineHeight: "30px",
        marginLeft: "30px",
        display: "table",
        margin: "0 auto",
      },
      [theme.breakpoints.between("md", "lg")]: {
        fontSize: "18px",
        marginLeft: "25px",
        display: "table",
        margin: "0 auto",
      },
    },
    checkoutTab: {
      background: "#c90000",
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "5px 5px 0 0",
      color: "#FFFFFF",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "35px",
      textTransform: "none",
      height: "59px",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    detailsHead1: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 20px",
      position: "sticky",
      background: colors.theme_fg,
      alignItems: "center",
      width: "95%",
      [theme.breakpoints.down("lg")]: {
        padding: "10px",
      },
    },
    checkboxBlock: {
      display: "flex",
      alignItems: "center",
      fontSize: "18px",
    },
    head: {
      fontWeight: semiBold,
      fontSize: "24px",
      lineHeight: "35px",
      margin: 0,
      color: "white",
    },
    text: {
      margin: "10px 0 30px",
      padding: 0,
      fontSize: "22px",
      lineHeight: "26px",
      textAlign: "center",
    },
    image: {
      marginRight: "105px",
    },

    notesText: {
      fontSize: "18px",
      lineHeight: "27px",
      margin: 0,
    },

    orderImageBlock: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    payButton: {
      border: "none",
      background: colors.icon_color,
      color: colors.text_white,
      fontSize: "20px",
      padding: "15px",
      width: "265px",
      borderRadius: "5px",
      cursor: "pointer",
      margin: "20px 0",
    },
  };
});

export default WalletPayment;
