/** @format */

import React, { useState, useEffect } from "react";
import * as colors from "../../assets/css/Colors";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddressValue,
  serviceActionError,
  setShowAdditionalDetails,
} from "../../actions/AddressActions";
import AutocompleteAddress from "../AutocompleteAddress";
import {
  Popover,
  useMediaQuery,
  TextField,
  InputAdornment,
  Modal,
} from "@mui/material";
import {
  VALIDATE_ADDRESS_CARD,
  VALIDATE_CARD_NUMBER,
  VALIDATE_CVC,
  VALIDATE_EXPIRY_DATE,
} from "../../config/validation";
import {
  GOOGLE_KEY,
  add_address,
  app_title,
  card_details,
  change,
  no_address,
  no_record,
  select_billing_address,
  smallLogo99,
} from "../../config/Constants";
import localStyles from "./localStyles";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CancelIcon from "@mui/icons-material/Cancel";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LockIcon from "@mui/icons-material/Lock";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { addCard, addCardWithToken, editCard } from "./services";
import { getExpiryDateFormat, getStripeTokeValue } from "../../config/Helper";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CommonError } from "../GeneralComponents";
import { injectStripe, CardElement } from "react-stripe-elements";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import AddAddress from "../account/AddAddress";

function AddPayment({
  formId,
  formList,
  formAnchor,
  closeFormList,
  profileInput,
  formattedCardNumber,
  setFormattedCardNumber,
  formattedExpiry,
  setFormattedExpiry,
  fromCheckout,
  addressList,
  customer,
  ...props
}) {
  const dispatch = useDispatch();
  const styles = localStyles();
  const matches = useMediaQuery("(max-width:1023px)");
  const { selectedCard, error } = useSelector((state) => state.card_list);
  const [billingAddress, setBillingAddress] = useState(false);
  const [showAddressList, setShowAddressList] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [futureUse, setFutureUse] = useState(false);
  const [tempCardDetails, setTempCardDetails] = useState(undefined);
  const [defaultBillingAddress, setDefaultBillingAddress] = useState(null);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(undefined);
  const [enterDetailsError, setEnterDetailsError] = useState(undefined);
  const [selectedAddress, setSelectedAddress] = useState(undefined);

  const [state, setState] = useState({
    cardNumber: "",
    expiryDate: "",
    cvc: "",
    address: {},
    apartment: undefined,
    validation: true,
    billingAddress: true,
    errorMessage: {
      cardNumber: undefined,
      expiryDate: undefined,
      cvc: undefined,
      apartment: undefined,
      address: undefined,
    },
  });

  useEffect(() => {
    const defaultBilling = addressList?.filter(
      (data) => data.is_default_billing
    );
    if (defaultBilling && defaultBilling.length > 0) {
      setDefaultBillingAddress(defaultBilling);
      setBillingAddress(true);
    }
  }, [addressList]);

  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");

    // Specify the source URL of the script you want to load
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_KEY}&libraries=places`;

    // Specify the async attribute to load the script asynchronously
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleCheckBoxChange = () => {
    setBillingAddress(!billingAddress);
    setState({
      ...state,
      billingAddress: !billingAddress,
      errorMessage: { ...state.errorMessage },
    });
  };

  const handleFutureCheckBoxChange = () => {
    setFutureUse(!futureUse);
  };

  const handleChangeClick = () => {
    setShowAddressList(!showAddressList);
  };

  const handleAddressClick = async (addressItem, index) => {
    setSelectedAddressIndex(index);
    setShowAddressList(false);
    setEnterDetailsError(undefined);
    setSelectedAddress(addressItem);
  };

  const onChangeAddress = async (details) => {
    let body = {
      line_1: "",
      pin_code: "",
      address: "",
      latitude: "",
      longitude: "",
      city_long_name: "",
      city_short_name: "",
      state_long_name: "",
      state_short_name: "",
      country_long_name: "",
      country_short_name: "",
    };

    await getGeocode({ address: details.description }).then((results) => {
      dispatch(getAddressValue({ address: results[0].formatted_address }));
      const { lat, lng } = getLatLng(results[0]);
      body = {
        ...body,
        address: results[0].formatted_address,
        latitude: lat.toString(),
        longitude: lng.toString(),
      };
      results[0].address_components.map((value) => {
        if (value.types.indexOf("route") > -1) {
          body = { ...body, line_1: value.long_name };
        } else if (
          value.types.indexOf("sublocality_level_1") > -1 ||
          value.types.indexOf("locality") > -1
        ) {
          body = {
            ...body,
            city_long_name: value.long_name,
            city_short_name: value.short_name,
          };
        } else if (value.types.indexOf("administrative_area_level_1") > -1) {
          body = {
            ...body,
            state_short_name: value.short_name,
            state_long_name: value.long_name,
          };
        } else if (value.types.indexOf("postal_code") > -1) {
          body = { ...body, pin_code: value.long_name };
        } else if (value.types.indexOf("country") > -1) {
          body = {
            ...body,
            country_long_name: value.long_name,
            country_short_name: value.short_name,
          };
        }
      });
      setState({
        ...state,
        address: body,
        errorMessage: { ...state.errorMessage, address: "" },
      });
    });

    const checkValues = Object.keys(body).map((key) => {
      if (key !== "door_no") if (body[key] === "") return false;
      return true;
    });
    if (checkValues.indexOf(false) > -1)
      return dispatch(serviceActionError("Please enter full address"));
    dispatch(getAddressValue({ ...body }));
    dispatch(setShowAdditionalDetails(true));
  };

  const onChange = (key, value) => {
    if (key === "cardNumber") {
      const v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
      const matches = v.match(/\d{4,16}/g);
      const match = (matches && matches[0]) || "";
      const parts = [];

      for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
      }

      if (parts.length) {
        setFormattedCardNumber(parts.join(" "));
      } else {
        setFormattedCardNumber(value);
      }
      setState({
        ...state,
        [key]: parts.join(" "),
        errorMessage: { ...state.errorMessage, [key]: "" },
      });
    } else if (key === "expiryDate") {
      const format = getExpiryDateFormat(value);
      setFormattedExpiry(format);
      setState({
        ...state,
        [key]: format,
        errorMessage: { ...state.errorMessage, [key]: "" },
      });
    } else if (
      ["pin_code", "state_long_name", "city_short_name"].includes(key)
    ) {
      setState({
        ...state,
        address: { ...state.address, [key]: value },
        errorMessage: { ...state.errorMessage, [key]: "" },
      });
    } else {
      setState({
        ...state,
        [key]: value.replace(/\s/g, ""),
        errorMessage: { ...state.errorMessage, [key]: "" },
      });
    }
  };

  const checkValidate = () => {
    let errorMessage = { ...state.errorMessage };
    if (!selectedCard) {
      errorMessage.cardNumber = VALIDATE_CARD_NUMBER(state.cardNumber);
    }
    errorMessage.expiryDate = VALIDATE_EXPIRY_DATE(state.expiryDate);
    if (!selectedCard) errorMessage.cvc = VALIDATE_CVC(state.cvc);
    errorMessage.address = billingAddress
      ? ""
      : VALIDATE_ADDRESS_CARD(state?.address?.address);
    setState({ ...state, errorMessage });
    if (
      errorMessage.cardNumber ||
      errorMessage.expiryDate ||
      errorMessage.cvc
    ) {
      state.validation = false;
      return false;
    }
    state.validation = true;
    return true;
  };

  const submitForm = async () => {
    checkValidate();
    const customer_id = customer.id;
    if (tempCardDetails) {
      const tempAddress = {
        line_1: selectedAddress?.line_1,
        pin_code: selectedAddress?.pin_code,
        address: selectedAddress?.address,
        latitude: selectedAddress?.latitude,
        longitude: selectedAddress?.longitude,
        city_long_name: selectedAddress?.city_long_name,
        city_short_name: selectedAddress?.city_short_name,
        state_long_name: selectedAddress?.state_long_name,
        state_short_name: selectedAddress?.state_short_name,
        country_long_name: selectedAddress?.country_long_name,
        country_short_name: selectedAddress?.country_short_name,
      };
      const body = {
        cardDetails: tempCardDetails?.token,
        is_default_billing_address: billingAddress,
        address: billingAddress ? defaultBillingAddress[0] : tempAddress,
      };

      if (tempAddress?.address === undefined && !defaultBillingAddress[0])
        setEnterDetailsError(select_billing_address);
      else {
        const response = await addCardWithToken(dispatch, body, customer_id);
        if (response) {
          setFutureUse(false);
          closeFormList();
        }
      }
    } else {
      if (!selectedCard && fromCheckout) setEnterDetailsError(card_details);
    }
    if (state.validation) {
      if (selectedCard) {
        const response = await editCard(
          dispatch,
          state,
          selectedCard,
          customer_id
        );
        if (response) {
          closeFormList();
        }
      } else {
        const response = await addCard(dispatch, state, customer_id);
        if (response) {
          closeFormList();
        }
      }
    }
  };

  const onChangeText = async (key, value) => {
    let details = { [key]: value };
    if (details?.card?.complete) {
      const response = await getStripeTokeValue(props, details);
      setTempCardDetails(response);
      setEnterDetailsError(undefined);
    }
  };

  return (
    <Popover
      id={formId}
      open={formList}
      anchorEl={formAnchor}
      onClose={() => {
        setState({
          cardNumber: "",
          expiryDate: "",
          cvc: "",
          address: {},
          apartment: undefined,
          validation: true,
          billingAddress: true,
          errorMessage: {
            cardNumber: undefined,
            expiryDate: undefined,
            cvc: undefined,
            apartment: undefined,
            address: undefined,
          },
        });
        closeFormList();
      }}
      anchorReference='none'
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      style={
        !matches
          ? {
              top: "200px",
              left: fromCheckout ? "50%" : "71%",
            }
          : {
              top: "200px",
              left: "15%",
            }
      }
      PaperProps={{
        style: { width: fromCheckout ? "50%" : "80%" },
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}>
      <div className={styles.containerAddPayment}>
        <CancelIcon className={styles.closeIcon} onClick={closeFormList} />
        <div className={styles.dpfIcon}>
          <img src={smallLogo99} alt='' width={matches ? 60 : 100} />
        </div>
        {!fromCheckout && (
          <>
            <span className={styles.headAddPayment}>{app_title}</span>
            <span>{profileInput?.email}</span>
          </>
        )}
        <br />
        <div className={styles.cardElement}>
          {fromCheckout ? (
            <div className={styles.innerContainerCard}>
              <CardElement
                onChange={(data) => onChangeText("card", data)}
                hidePostalCode={true}
                className={styles.cardField}
              />
            </div>
          ) : (
            <>
              <TextField
                placeholder='Credit Card Number'
                name='ccnumber'
                variant='outlined'
                required
                fullWidth
                style={{
                  borderTop: "1px solid rgba(0, 0, 0, 0.42)",
                  borderTopRightRadius: "5px",
                  borderTopLeftRadius: "5px",
                }}
                value={
                  formattedCardNumber ||
                  (selectedCard && `**** **** **** ${selectedCard.last4}`)
                }
                onChange={(e) => onChange("cardNumber", e.target.value)}
                inputProps={{
                  maxLength: 19,
                }}
                disabled={selectedCard?.last4 ? true : false}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <CreditCardIcon sx={{ color: colors.icon_color }} />
                    </InputAdornment>
                  ),
                }}
                error={!!state.errorMessage.cardNumber}
                helperText={state.errorMessage.cardNumber}
              />
              <div className={styles.outerContainer}>
                <div className={styles.widthContainer}>
                  <TextField
                    placeholder='MM/YY'
                    name='ccexp'
                    variant='outlined'
                    required
                    fullWidth
                    value={
                      formattedExpiry ||
                      (selectedCard &&
                        `${
                          selectedCard.exp_month < 10
                            ? `0${selectedCard.exp_month}`
                            : selectedCard.exp_month
                        }/${Number(String(selectedCard.exp_year).slice(-2))}`)
                    }
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => onChange("expiryDate", e.target.value)}
                    inputProps={{
                      maxLength: 7,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <CalendarTodayIcon
                            sx={{ color: colors.icon_color }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    error={!!state.errorMessage.expiryDate}
                    helperText={state.errorMessage.expiryDate}
                  />
                </div>
                {!selectedCard && (
                  <div className={styles.widthContainer}>
                    <TextField
                      placeholder='CVC'
                      name='cvc'
                      variant='outlined'
                      required
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={state.cvc}
                      onChange={(e) => onChange("cvc", e.target.value)}
                      inputProps={{
                        maxLength: 3,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <LockIcon sx={{ color: colors.icon_color }} />
                          </InputAdornment>
                        ),
                      }}
                      error={!!state.errorMessage.cvc}
                      helperText={state.errorMessage.cvc}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {fromCheckout && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={futureUse}
                  onChange={handleFutureCheckBoxChange}
                  style={{ color: "#C90000" }}
                />
              }
              label='Save this card for future use'
            />
          )}
          {((!selectedCard && futureUse) || !fromCheckout) && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={billingAddress}
                    onChange={handleCheckBoxChange}
                    style={{ color: "#C90000" }}
                  />
                }
                label='Use as default billing address'
              />
              {billingAddress && fromCheckout && (
                <>
                  <br />
                  {defaultBillingAddress && defaultBillingAddress.length > 0 ? (
                    <>
                      <span>{defaultBillingAddress[0]?.line_1}</span> <br />
                      {defaultBillingAddress[0]?.line_2 && (
                        <>
                          <span>{defaultBillingAddress[0]?.line_2}</span> <br />
                        </>
                      )}
                      <span>
                        {defaultBillingAddress[0]?.city_short_name},{" "}
                        {defaultBillingAddress[0]?.state_short_name}{" "}
                        {defaultBillingAddress[0]?.pin_code}
                      </span>
                    </>
                  ) : (
                    <>{no_address}</>
                  )}
                </>
              )}
            </>
          )}
          {(!billingAddress || selectedCard) && !futureUse && !fromCheckout ? (
            <>
              <br />
              <span>Billing Address</span>
              <br />
              <br />
              <AutocompleteAddress
                onChangeAddress={onChangeAddress}
                error={!!state.errorMessage.address}
                errorText={state.errorMessage.address}
                addressValue={state?.address}
                selectedCard={selectedCard}
              />
              <TextField
                placeholder='Apartment, unit, suite, or floor #'
                variant='standard'
                fullWidth
                color='grey'
                className={styles.inputEmailAddress}
                onChange={(e) => onChange("apartment", e.target.value)}
                value={state?.apartment}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LocationOnIcon sx={{ color: colors.icon_color }} />
                    </InputAdornment>
                  ),
                }}
                error={!!state.errorMessage.apartment}
                helperText={state.errorMessage.apartment}
              />
              <TextField
                placeholder='City'
                variant='standard'
                fullWidth
                color='grey'
                className={styles.inputEmailAddress}
                onChange={(e) => onChange("city_short_name", e.target.value)}
                value={
                  state?.address?.city_short_name || selectedCard?.address_city
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LocationOnIcon sx={{ color: colors.icon_color }} />
                    </InputAdornment>
                  ),
                }}
                error={!!state.errorMessage.city}
                helperText={state.errorMessage.city}
              />
              <TextField
                placeholder='State'
                variant='standard'
                fullWidth
                color='grey'
                className={styles.inputEmailAddress}
                onChange={(e) => onChange("state_long_name", e.target.value)}
                value={
                  state?.address.state_long_name || selectedCard?.address_state
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LocationOnIcon sx={{ color: colors.icon_color }} />
                    </InputAdornment>
                  ),
                }}
                error={!!state.errorMessage.state}
                helperText={state.errorMessage.state}
              />
              <TextField
                placeholder='Zip Code'
                variant='standard'
                fullWidth
                color='grey'
                className={styles.inputEmailAddress}
                onChange={(e) => onChange("pin_code", e.target.value)}
                value={
                  (state && state.address.pin_code) || selectedCard?.address_zip
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LocationOnIcon sx={{ color: colors.icon_color }} />
                    </InputAdornment>
                  ),
                }}
                error={!!state.errorMessage.zip}
                helperText={state.errorMessage.zip}
              />
            </>
          ) : (
            futureUse &&
            !billingAddress &&
            (showAddressList ? (
              <>
                <div className={styles.addCard}>
                  <div
                    className={styles.addAddressContainer}
                    onClick={() => setShowModal(true)}>
                    <AddCircleIcon className={styles.cardIcon} />{" "}
                    <span className={styles.newCardText}> {add_address}</span>
                  </div>
                  <div>
                    <span
                      onClick={handleChangeClick}
                      className={styles.newCardText}
                      style={{ color: "#C90000", fontWeight: "bold" }}>
                      {change}
                    </span>
                  </div>
                </div>
                <div className={styles.addressListContainer}>
                  {addressList?.length > 0 &&
                    addressList?.map((addressItem, index) => (
                      <div
                        className={
                          selectedAddressIndex === index
                            ? styles.selectedCard
                            : styles.addressCard1
                        }
                        key={addressItem.id}
                        onClick={() => handleAddressClick(addressItem, index)}>
                        <LocationOnIcon className={styles.cardIcon} />
                        <div className={styles.addressCardContainer}>
                          <span>{addressItem?.line_1}</span> <br />
                          {addressItem?.line_2 && (
                            <>
                              <span>{addressItem?.line_2}</span> <br />
                            </>
                          )}
                          <span>
                            {addressItem?.city_short_name},{" "}
                            {addressItem?.state_short_name}{" "}
                            {addressItem?.pin_code}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <>
                <div className={styles.addCard}>
                  <div
                    className={styles.addAddressContainer}
                    onClick={() => setShowModal(true)}>
                    <AddCircleIcon className={styles.cardIcon} />{" "}
                    <span className={styles.newCardText}> {add_address}</span>
                  </div>
                  <div>
                    <span
                      onClick={handleChangeClick}
                      className={styles.newCardText}
                      style={{ color: "#C90000", fontWeight: "bold" }}>
                      {change}
                    </span>
                  </div>
                </div>
                <div>
                  {selectedAddress && (
                    <>
                      <br />
                      <span>{selectedAddress?.line_1}</span> <br />
                      {selectedAddress?.line_2 && (
                        <>
                          <span>{selectedAddress?.line_2}</span> <br />
                        </>
                      )}
                      <span>
                        {selectedAddress?.city_short_name},{" "}
                        {selectedAddress?.state_short_name}{" "}
                        {selectedAddress?.pin_code}
                      </span>
                    </>
                  )}
                </div>
              </>
            ))
          )}
          <br />
          {error != no_record && (
            <>
              <CommonError type='textError' error={error} />
              <br />
            </>
          )}
          {enterDetailsError && (
            <CommonError type='textError' error={enterDetailsError} />
          )}
          <button
            onClick={submitForm}
            className={styles.submitButton}
            style={{ marginTop: "10px" }}>
            {selectedCard
              ? "Update"
              : billingAddress && fromCheckout && !futureUse
              ? "Pay"
              : "Save"}
          </button>
        </div>
      </div>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className={styles.modalContact}>
          <AddAddress
            setAddAddress={setShowModal}
            getService={false}
            customer={customer}
            addCustomerAddress={true}
          />
        </div>
      </Modal>
    </Popover>
  );
}

export default injectStripe(AddPayment);
