/** @format */

import React, { createContext, useState } from "react";

export const DPFContext = createContext(null);

const DPFContextProvider = (props) => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [customerDetails, setCustomerDetails] = useState(null);

  const [ChangeOrderStatus, setChangeOrderStatus] = useState(null);
  const [cartAddress, setCartAddress] = useState(null);
  const [isDealsTab, setDealsTab] = useState(false);
  const [isOrderTab, setOrderTab] = useState(false);
  const [ssoError, setSsoError] = useState(null);
  const [fcmToken, setFcmToken] = useState(null);
  const [thanksModal, setThanksModal] = useState(false);
  const [cartDetails, setCartDetails] = useState(null);
  const [orderPage, setOrderPage] = useState(null);
  const [ordersSort, setOrdersSort] = useState(null);
  const [account, setAccount] = useState(null);
  const [contact, setContact] = useState(null);
  const [isCartShown, setIsCartShown] = useState(null);
  const [customerOrders, setCustomerOrders] = useState(null);
  const [paymentType, setPaymentType] = useState(null);

  return (
    <DPFContext.Provider
      value={{
        orderDetails,
        setOrderDetails,
        ChangeOrderStatus,
        setChangeOrderStatus,
        cartAddress,
        setCartAddress,
        isDealsTab,
        setDealsTab,
        isOrderTab,
        setOrderTab,
        ssoError,
        setSsoError,
        fcmToken,
        setFcmToken,
        cartDetails,
        setCartDetails,
        orderPage,
        setOrderPage,
        ordersSort,
        setOrdersSort,
        account,
        setAccount,
        isCartShown,
        setIsCartShown,
        customerDetails,
        setCustomerDetails,
        customerOrders,
        setCustomerOrders,
        paymentType,
        setPaymentType,
        contact,
        setContact,
        thanksModal,
        setThanksModal,
      }}>
      {props.children}
    </DPFContext.Provider>
  );
};

export default DPFContextProvider;
